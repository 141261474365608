import React, { ReactElement, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Head from '../../components/Head';

import './styles.less';

const NotFoundScene: React.FC = (props): ReactElement => {
  const history = useHistory();

  const locationRef = useRef(null);

  const [category, setCategory] = useState('horse.sale');

  let backLink = `${process.env.REACT_APP_CLASSIFIEDS_URL}`;
  if (typeof window !== 'undefined') {
    backLink = encodeURIComponent(window?.location?.href);
  }
  return (
    <div>
      <Head title="Equestrian Classifieds Marketplace - Horse & Hound" />
      <section>
        <div className="container content notFoundContent">
          <h1 className="heading">
            Sorry! The page you are trying to visit is unavailable.
          </h1>
          <p>
            This may be because you followed an out-of-date link or the page is no longer
            available.
          </p>
          <img src="/img/404.png" width="100%" className="notFoundImage" />
          <span>
            Try starting your <a href="/">search again</a> or{' '}
            <a
              href={`https://www.whickr.com/create-advert?p=horseandhound&backLink=${backLink}`}
            >
              place your advert here
            </a>
            .
          </span>
        </div>
      </section>
    </div>
  );
};

// NotFoundScene.fetchData = async (ssrStore, match, req) => {
//   const SSR = withSSRContext({ req });
//   const searchParamsInUrl = qs.parse('?sort=publishedAt.desc&page=1', {
//     ignoreQueryPrefix: true,
//   });

//   const { apiQuery } = getSearchOptions(searchParamsInUrl);
//   const advertItems = await getAdverts({ ...apiQuery, limit: 6, amp: SSR });
//   console.log('advertItems', advertItems);
//   ssrStore.dispatch(updateLanding({ adverts: advertItems }));
// };

export default NotFoundScene;
