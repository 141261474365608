import React, {
  ReactElement,
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
} from 'react';
import './styles.less';
import { Row, Col, Input, AutoComplete, Form, Select, Spin } from 'antd';
// import PlacesAutocomplete, {
//   geocodeByAddress,
//   geocodeByPlaceId,
//   getLatLng,
// } from 'react-places-autocomplete';
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-google-places-autocomplete';

import jsonp from 'fetch-jsonp';
import querystring from 'querystring';

const { Option } = Select;
// const { Option, OptGroup } = Select;

/**
 * Description
 */

type OnChangeParams = {
  result: any[];
  location: string;
  coords: { lat: number; lon: number };
};

type Props = {
  defaultInputValue?: string;
  loading?: boolean;
  onPickedItem?: (location: string) => void;
  onChange: (params: OnChangeParams) => void;
};

const defaultProps = { loading: false, defaultInputValue: '', onPickedItem: () => null };

let timeout;
let currentValue;

const LocationAutoCompleteInput = ({
  loading,
  defaultInputValue,
  onPickedItem,
  onChange,
  label,
  locationRef,
  size = 'middle',
}: Props): React.ReactElement => {
  const selectRef = useRef(null);
  const [data, setData] = useState([]);
  const [value, setValue] = useState(defaultInputValue);
  const [searchString, setSearchString] = useState(defaultInputValue);

  const [fetching, setFetching] = useState(false);

  const autocompleteService = useRef();

  useImperativeHandle(locationRef, () => ({
    clear: () => {
      setData([]);
      setValue(null);
      setSearchString('');
    },
  }));

  useEffect(() => {
    if (typeof document !== 'undefined') {
      autocompleteService.current = new google.maps.places.AutocompleteService();
    }
  }, []);

  // const geocoder = useRef(new google.maps.Geocoder());

  function fetch(newValue, callback) {
    setFetching(true);
    const isShorterThanMinLength = newValue.length < 2;

    if (isShorterThanMinLength || newValue.length === 0) {
      return;
    }

    const options = {
      input: newValue,
      componentRestrictions: {
        country: ['uk', 'ie', 'gg', 'je', 'im'],
      },
    };

    // const mapOptions = ['location', 'radius', 'bounds', 'types'];
    // mapOptions.forEach((option) => {
    //   if (this.props[option]) {
    //     // $FlowFixMe
    //     options[option] = this.props[option];
    //   }
    // });

    // Get suggested locations from google map.
    autocompleteService.current.getPlacePredictions(options, (suggestsGoogle) => {
      const suggestions = suggestsGoogle || [];
      const locData = suggestions.map((datum) => {
        return {
          address: datum.description,
          placeId: datum.place_id,
        };
      });
      setData(locData);
      setFetching(false);
    });
  }

  const handleSearch = (searchValue) => {
    setSearchString(searchValue);
    if (searchValue) {
      fetch(searchValue, (newData) => setData(newData));
    } else {
      setData([]);
      setValue(null);
    }
  };

  const handleChange = async (newValue, { children }) => {
    setValue(newValue);

    selectRef.current.blur();

    if (children) {
      const location = children;

      if (onPickedItem) {
        onPickedItem(location);
      }
      try {
        const results = await geocodeByAddress(location);
        const { lat, lng: lon } = await getLatLng(results[0]);

        onChange({
          result: results[0],
          location,
          coords: { lat, lon },
          isAdminLevel1: results[0].types.includes('administrative_area_level_1'),
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const options = data.map((d) => <Option key={d.placeId}>{d.address}</Option>);

  let extra = {};
  if (value) {
    extra = { value };
  }

  const autocompleteSelect = loading ? (
    <div>Loading...</div>
  ) : (
    <div className="locationSearchInput">
      <Select
        ref={selectRef}
        showSearch
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={false}
        {...extra}
        style={{ width: '100%' }}
        onSearch={handleSearch}
        onChange={handleChange}
        size={size}
        placeholder="Search"
        loading={fetching}
        notFoundContent={
          searchString.length < 2
            ? null
            : fetching
            ? 'Loading...'
            : searchString.length > 0
            ? 'Not found'
            : null
        }
      >
        {options}
      </Select>
    </div>
  );
  if (!label) {
    return autocompleteSelect;
  }
  return (
    <>
      <label htmlFor={label} className="advertInputLabel">
        {label}
        {autocompleteSelect}
      </label>
    </>
  );
};

LocationAutoCompleteInput.defaultProps = defaultProps;

export default LocationAutoCompleteInput;

// class LocationAutoCompleteInput extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = { address: '', result: [] };
//   }

//   handleChange = (address) => {
//     this.setState({ address });
//   };

//   handleSelect = (address) => {
//     geocodeByAddress(address)
//       .then((results) => getLatLng(results[0]))
//       .then((latLng) => console.log('Success', latLng))
//       .catch((error) => console.error('Error', error));
//   };

//   selectAfter = (
//     <Select defaultValue=".com" className="select-after">
//       <Option value=".com">+ 0 Miles</Option>
//       <Option value=".jp">+ 5 Miles</Option>
//       <Option value=".cn">+ 10 Miles</Option>
//       <Option value=".org">+ 15 Miles</Option>
//     </Select>
//   );

//   render() {
//     return (
//       <PlacesAutocomplete
//         value={this.state.address}
//         onChange={this.handleChange}
//         onSelect={this.handleSelect}
//       >
//         {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
//           <div>
//             {/* <Input
//               {...getInputProps({
//                 placeholder: 'Search Places ...',
//                 className: 'location-search-input',
//               })}
//               addonAfter={this.selectAfter}
//             /> */}

//             <AutoComplete
//               options={this.state.result}
//               // {...getInputProps({
//               //   // placeholder: 'Search Places ...',
//               //   // className: 'location-search-input',
//               // })}
//               style={{ width: 200 }}
//               onSelect={this.handleSelect}
//               onChange={() => {
//                 let res;
//                 // if (!value || value.indexOf('@') >= 0) {
//                 //   res = [];
//                 // } else {
//                 //   res = ['gmail.com', '163.com', 'qq.com'].map((domain) => `${value}@${domain}`);
//                 // }

//                 res = suggestions.map((suggestion) => {
//                   return suggestion.description;
//                 });

//                 this.setState({ result: res });
//               }}
//               placeholder="input here"
//             />

//             <div className="autocomplete-dropdown-container">
//               {loading && <div>Loading...</div>}
//               {suggestions.map((suggestion) => {
//                 const className = suggestion.active
//                   ? 'suggestion-item--active'
//                   : 'suggestion-item';
//                 // inline style for demonstration purpose

//                 return (
//                   <div
//                     {...getSuggestionItemProps(suggestion, {
//                       className,
//                     })}
//                   >
//                     <span>{suggestion.description}</span>
//                   </div>
//                 );
//               })}
//             </div>
//           </div>
//         )}
//       </PlacesAutocomplete>
//     );
//   }
// }

// LocationAutoCompleteInput.defaultProps = defaultProps;
// export default LocationAutoCompleteInput;
