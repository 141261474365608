import _ from 'lodash';
import * as types from '../actions/actionTypes';
import { Advert, Colour, Discipline, Gender, SaleType, Type } from '../models';

type InitialState = { loggedIn: boolean };

const initialState: InitialState = { loggedIn: false };

export default (state = initialState, action): InitialState => {
  switch (action.type) {
    case types.UPDATE_USER_DATA:
      return (() => {
        return {
          ...state,
          ...action.payload.user,
          loggedIn: !!action.payload.user.id,
        };
      })();
    case types.CLEAR_USER_DATA:
      return (() => {
        return {
          ...initialState,
        };
      })();

    default:
      return state;
  }
};
