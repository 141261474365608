import React, { ReactElement, useEffect, useRef, useState } from 'react';
import {
  Radio,
  Checkbox,
  Row,
  Col,
  Form,
  Select,
  Space,
  message,
  InputNumber,
} from 'antd';
import { FormInstance, Values } from 'antd/lib/form/Form';
import qs from 'qs';
import { useLocation } from 'react-router-dom';
import Card from '../Card';
import Button from '../Button';
import './styles.less';

import LocationAutoCompleteInput from '../LocationAutoCompleteInput';
import analytics from '../../utils/analytics';

import device from '../../utils/device';
import getFilterItems, { Category } from './filterItems';
import {
  booleanTypes,
  multiTermTypes,
  rangeTypes,
  termTypes,
} from '../../utils/filterTypes';

const { Option, OptGroup } = Select;
const HORSE_TYPE_ID = '5ac0973a-2239-4048-a953-f812bc74d927';

const categoryOptions = [
  {
    value: 'horse',
    label: 'Horse',
  },
  {
    value: 'trailer',
    label: 'Trailer',
  },
  {
    value: 'property',
    label: 'Property',
  },
  {
    value: 'job',
    label: 'Jobs',
  },
  {
    value: 'dog',
    label: 'Dog',
  },
  // {
  //   value: 'liveryYard',
  //   label: 'Livery Yards',
  // },
  // {
  //   value: 'misc',
  //   label: 'Misc',
  // },
  {
    value: 'cat',
    label: 'Cat',
  },
  // {
  //   value: 'stallionsAtStud',
  //   label: 'Stallions at Stud',
  // },
];

type DisciplineOption = {
  id: string;
  label: string;
};
type FilterParams = {
  'age.max': string;
  'age.min': string;
  disciplines: DisciplineOption[];
  'height.max': string;
  'height.min': string;
  excludePOA: boolean;
  hasMedia: boolean;
  location: string;
  'price.max': string;
  'price.min': string;
  saleTypeId: string;
};

type Props = {
  onSortChange: (sortKey: string) => void;
  onFilterChange: (params: FilterParams) => void;
  onClearFilters: () => void;
  sort: string;
  filters: any;
};

const defaultProps = {};

const key = 'updatable';
const openMessage = () => {
  // message.loading({ content: 'Loading...', key });
};

const FilterBox: React.FC<Props> = ({
  sort,
  filters,
  disciplines,
  categoryId,
  onSortChange,
  onFilterChange,
  onClearFilters,
}: Props): ReactElement => {
  const [form] = Form.useForm();
  const locationRef = useRef(null);

  const activeFilters = getFilterItems(categoryId, form.getFieldsValue());

  const [isAdminLevel1, setIsAdminLevel1] = useState(filters.isAdminLevel1);

  let initialType = [];

  if (filters.typeId) {
    if (Array.isArray(filters.typeId)) {
      initialType = filters.typeId;
    } else {
      initialType = [filters.typeId];
    }
  }
  const [horseTypeId, setHorseTypeId] = useState(initialType);

  const location = useLocation();

  const locationSearch = useRef(location.search);
  const locationPathName = useRef(location.pathname);

  useEffect(() => {
    if (
      location.search !== locationSearch.current ||
      location.pathname !== locationPathName.current
    ) {
      const searchParamsInUrl = qs.parse(location.search, { ignoreQueryPrefix: true });

      if (searchParamsInUrl.typeId) {
        form.setFieldsValue({
          typeId: searchParamsInUrl.typeId,
        });

        setHorseTypeId(
          Array.isArray(searchParamsInUrl.typeId)
            ? searchParamsInUrl.typeId
            : [searchParamsInUrl.typeId]
        );
      }
      if (searchParamsInUrl.disciplines) {
        form.setFieldsValue({
          disciplines: searchParamsInUrl.disciplines,
        });
      }

      locationSearch.current = location.search;
      locationPathName.current = location.pathname;
    }
  }, [location]);

  if (
    typeof document !== 'undefined' &&
    document.getElementById('disciplines') &&
    device.isMobile
  ) {
    document.getElementById('disciplines').disabled = true;
  }

  let disciplineOptions = [];
  const horseOptions = [];
  const ponyOptions = [];

  disciplines?.forEach((option) => {
    const optionItem = (
      <Option key={option.id} value={option.id}>
        {option.label}
      </Option>
    );

    if (option.type === HORSE_TYPE_ID) {
      horseOptions.push(optionItem);
    } else {
      ponyOptions.push(optionItem);
    }

    if (horseTypeId && horseTypeId.length === 1) {
      if (option.type === horseTypeId[0]) {
        disciplineOptions.push(optionItem);
      }
    }
  });

  if (!horseTypeId || horseTypeId.length === 0 || horseTypeId.length === 2) {
    disciplineOptions = (
      <>
        <OptGroup label="Horse">{horseOptions}</OptGroup>
        <OptGroup label="Pony">{ponyOptions}</OptGroup>
      </>
    );
  }

  return (
    <Form
      form={form}
      onFinish={(values) => {
        values.coords = form.getFieldValue('coords');
        // values.miles = form.getFieldValue('miles');
        values.searchLocation = form.getFieldValue('searchLocation');
        values.isAdminLevel1 = isAdminLevel1;

        onFilterChange(values);
        openMessage();
      }}
      initialValues={filters}
    >
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <Card padding={device.isMobile ? 0 : 28} noShadow={device.isMobile}>
          <h3
            style={{
              fontWeight: 600,
              fontSize: 17,
              padding: 0,
              margin: 0,
              marginBottom: 8,
            }}
          >
            Sort
          </h3>
          <Select
            defaultValue={sort}
            style={{ width: '100%' }}
            className="sortbox"
            onChange={(value) => {
              onSortChange(value);

              const sortValues = value.split('.');
              analytics.onEvent('sort_apply', {
                sort_by: sortValues[0],
                direction: sortValues[1],
              });
            }}
          >
            <OptGroup label="Date">
              <Option value="publishedAt.desc">Newest Listed</Option>
              <Option value="publishedAt.asc">Oldest Listed</Option>
            </OptGroup>
            <OptGroup label="Price">
              <Option value="price.desc">Highest Price</Option>
              <Option value="price.asc">Lowest Price</Option>
            </OptGroup>
          </Select>
        </Card>

        <Card padding={device.isMobile ? 0 : 28} noShadow={device.isMobile}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h3
              style={{
                fontWeight: 600,
                fontSize: 17,
                marginBottom: 8,
              }}
            >
              Filters
            </h3>
          </div>
          {/* <h4 className="filterTitle">Category</h4>
          <Form.Item name="categoryId">
            <Select
              style={{
                width: '100%',
              }}
              placeholder="Select"
              className="sortbox"
              onChange={(e) => {
                // setCategory(e);
              }}
            >
              {categoryOptions.map(({ value, label }) => (
                <Option value={value}>{label}</Option>
              ))}
            </Select>
          </Form.Item> */}

          {activeFilters.map((filterItem) => {
            let filterComp = 'Type of filter';

            if (filterItem.type === 'location') {
              filterComp = (
                <>
                  <LocationAutoCompleteInput
                    locationRef={locationRef}
                    defaultInputValue={filters.searchLocation || ''}
                    onChange={({ location, coords, isAdminLevel1: newIsAdminLevel1 }) => {
                      setIsAdminLevel1(newIsAdminLevel1);
                      form.setFieldsValue({
                        searchLocation: location,
                        coords,
                      });
                    }}
                  />
                  <Form.Item name="miles">
                    <Select
                      style={{ width: '100%', marginBottom: 16, marginTop: 16 }}
                      className="sortbox"
                      disabled={isAdminLevel1}
                    >
                      <Option value="50">+ 50 Miles</Option>
                      <Option value="100">+ 100 Miles</Option>
                      <Option value="200">+ 200 Miles</Option>
                      <Option value="300">+ 300 Miles</Option>
                      <Option value="500">+ 500 Miles</Option>
                    </Select>
                  </Form.Item>
                </>
              );
            }

            if (filterItem.type === 'range') {
              filterComp = (
                <Row gutter={8}>
                  <Col span={12}>
                    <Form.Item name={`${filterItem.key}.min`}>
                      <InputNumber
                        placeholder="Min"
                        inputMode="numeric"
                        style={{ width: '100%' }}
                        {...filterItem.minProps}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name={`${filterItem.key}.max`}>
                      <InputNumber
                        placeholder="Max"
                        inputMode="numeric"
                        style={{ width: '100%' }}
                        {...filterItem.maxProps}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              );
            }

            if (filterItem.type === 'select') {
              filterComp = (
                <div className="horseTypeSelect">
                  <Form.Item name={filterItem.key} shouldUpdate>
                    <Select
                      style={{
                        width: '100%',
                      }}
                      placeholder="Select"
                      className="sortbox"
                      {...filterItem.selectProps}
                      onChange={(e) => {
                        form.setFieldsValue({
                          [filterItem.key]: e,
                        });

                        if (filterItem.key === 'typeId') {
                          form.setFieldsValue({
                            disciplines: [],
                          });
                          setHorseTypeId(e);
                        }
                      }}
                    >
                      {filterItem.key === 'disciplines'
                        ? disciplineOptions
                        : filterItem?.options?.map((option) => (
                            <Option value={option.value}>{option.label}</Option>
                          ))}
                    </Select>
                  </Form.Item>
                </div>
              );
            }

            if (filterItem.type === 'checkbox') {
              filterComp = (
                <Form.Item
                  name={filterItem.key}
                  valuePropName="checked"
                  initialValue={filterItem.initialValue}
                >
                  <Checkbox>{filterItem.label}</Checkbox>
                </Form.Item>
              );
            }

            if (filterItem.type === 'radio') {
              filterComp = (
                <Form.Item name={filterItem.key} initialValue={filterItem.initialValue}>
                  <Radio.Group>
                    {filterItem?.options?.map((option) => (
                      <Radio.Button key={option.value} value={option.value}>
                        {option.label}
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                </Form.Item>
              );
            }

            return (
              <>
                {filterItem.type !== 'checkbox' && (
                  <h4 className="filterTitle">
                    {filterItem.label} {filterItem.labelExtra}
                  </h4>
                )}
                {filterComp}
              </>
            );
          })}

          <Space direction="vertical" style={{ width: '100%' }}>
            <Button type="primary" label="Apply Filters" htmlType="submit" block />
            <Button
              label="Clear Filters"
              block
              onClick={async (e) => {
                e.preventDefault();

                setIsAdminLevel1(false);

                const allFilters = {};

                [...termTypes, ...booleanTypes].forEach((key) => {
                  allFilters[key] = null;
                });
                multiTermTypes.forEach((key) => {
                  allFilters[key] = [];
                });
                rangeTypes.forEach((key) => {
                  allFilters[`${key}.min`] = null;
                  allFilters[`${key}.max`] = null;
                });

                // Object.keys(form.getFieldsValue()).forEach((key) => {
                //   allFilters[key] = '';
                // });

                allFilters.miles = '50';
                form?.setFieldsValue(allFilters);
                locationRef.current.clear();
                onClearFilters();

                analytics.onEvent('filters_clear');
              }}
            />
          </Space>
        </Card>
      </Space>
    </Form>
  );
};

FilterBox.defaultProps = defaultProps;
export default FilterBox;
