export const heightFormatter = (text) => {
  text = text.replace(/[^0-9.]/g, '');

  const decimalCount = text.replace(/[^.]/g, '').length;
  if (decimalCount > 0) {
    if (decimalCount > 1) {
      text = text.replace(/.([^.]*)$/g, '');
    }

    const splitText = text.split('.');
    if (splitText.length > 1) {
      const beforeDecimalCount = splitText[0].length;

      if (beforeDecimalCount > 1) {
        text = `${splitText[0][0]}${splitText[0][1]}.${splitText[1]}`;
      }

      const afterDecimalCount = splitText[1].length;

      if (afterDecimalCount > 1) {
        text = `${splitText[0]}.${splitText[1][0]}`;
      }
    } else if (text.length > 2) {
      text = `${text[0][0]}${text[0][1]}`;
    }
  } else if (text.length > 2) {
    text = `${text[0]}${text[1]}`;
  }
  return text;
};

export const priceFormatter = (value) => {
  let text = `${value}`.replace(/[^0-9.]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  // let text = value.replace(/[^0-9.]/g, '');

  const decimalCount = text.replace(/[^.]/g, '').length;
  if (decimalCount > 0) {
    if (decimalCount > 1) {
      text = text.replace(/.([^.]*)$/g, '');
    }

    const splitText = text.split('.');
    const afterDecimalCount = splitText[1].length;

    if (afterDecimalCount > 2) {
      text = `${splitText[0]}.${splitText[1][0]}${splitText[1][1]}`;
    }
  }
  return `${text}`;
};
