import { API, Auth, graphqlOperation } from 'aws-amplify';
import _ from 'lodash';
import { listDisciplines } from '../graphql/queries';
import { Discipline } from '../models';

export default async function getDisciplines({
  amp = { Auth, API },
}): Promise<Discipline[] | undefined> {
  try {
    const {
      data: {
        listDisciplines: { items },
      },
    } = await amp.API.graphql({
      query: listDisciplines,
      authMode: 'AWS_IAM',
    });

    return _.orderBy(items, [(item) => item.label.toLowerCase()], ['asc']);
  } catch (err) {
    console.log('error fetching disciplines', JSON.stringify(err, null, 2));
    // return err;
  }
}
