export type Tester = string;

export enum CategoryType {
  Horse = 'horse',
  Trailer = 'trailer',
  Property = 'property',
  Job = 'job',
  Dog = 'dog',
  LiveryYard = 'liveryYard',
  Misc = 'misc',
  Cat = 'cat',
  StallionsAtStud = 'stallionsAtStud',
}
