import * as types from './actionTypes';

export function updateShowWelcomeMessage(shouldShowWelcomeMessage) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_SHOW_WELCOME_MESSAGE,
      payload: {
        shouldShowWelcomeMessage,
      },
    });
  };
}

export function updateColors(colors) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_COLORS,
      payload: {
        colors,
      },
    });
  };
}

export function updateDisciplines(disciplines) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_DISCIPLINES,
      payload: {
        disciplines,
      },
    });
  };
}
