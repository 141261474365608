export const UPDATE_ADVERTS = 'UPDATE_ADVERTS';
export const UPDATE_CURRENT_ADVERT = 'UPDATE_CURRENT_ADVERT';
export const ADD_ADVERTS = 'ADD_ADVERTS';
export const UPDATE_SOLD_AT = 'UPDATE_SOLD_AT';

export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const CLEAR_USER_DATA = 'CLEAR_USER_DATA';

export const ADD_FAVOURITE = 'ADD_FAVOURITE';
export const REMOVE_FAVOURITE = 'REMOVE_FAVOURITE';
export const UPDATE_FAVOURITES = 'UPDATE_FAVOURITES';

export const UPDATE_MY_ADVERTS = 'UPDATE_MY_ADVERTS';
export const UPDATE_LANDING = 'UPDATE_LANDING';

export const UPDATE_SHOW_WELCOME_MESSAGE = 'UPDATE_SHOW_WELCOME_MESSAGE';
export const UPDATE_COLORS = 'UPDATE_COLORS';
export const UPDATE_DISCIPLINES = 'UPDATE_DISCIPLINES';

export const UPDATE_WANTED_ADVERTS = 'UPDATE_WANTED_ADVERTS';
