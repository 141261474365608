import { API, Auth } from "aws-amplify";

import _ from "lodash";
import moment from "moment";

import { formatCurrency } from "../utils/currency";

import getDisciplines from "./getDisciplines";
import { Advert, Discipline } from "../models";

import { classifiedsSearchAdvertsCustom } from "../graphql/advertQueries";

export async function getAdverts({
  sort,
  filters,
  from = 0,
  limit = 10,
  amp = { Auth, API },
}) {
  try {
    let sortField = "";
    let sortDirection = "desc";
    const filter = {};

    if (sort) {
      sortField = sort;

      if (sort.includes(".")) {
        const [key, direction] = sort.split(".");
        sortField = key;
        sortDirection = direction;
      }
    }

    const disciplineItems = await getDisciplines({ amp });
    const soldAtCutOffDate = moment().subtract(4, "days").startOf("day").toISOString();
    const userCoords = {
      lon: -3.17909,
      lat: 51.48158100000001,
    };

    if (filters.miles) {
      filters.miles = `${filters.miles}mi`;
    }
    delete filters.searchLocation;

    const {
      data: {
        classifiedsSearchAdverts: { items, total },
      },
    }: {
      data: {
        classifiedsSearchAdverts: { items: Advert[]; from: string; total: number };
      };
    } = await amp.API.graphql({
      query: classifiedsSearchAdvertsCustom,
      authMode: "AWS_IAM",
      variables: {
        filter: {
          ...filters,
          status: 1,
          soldAtCutOffDate,
          userCoords,
        },
        limit,
        sort: { field: sortField, direction: sortDirection },
        from,
      },
    });

    return {
      from: from + limit,
      total,
      items: items.map((item) => {
        const disciplines: Discipline[] = [];
        if (item.disciplines) {
          item.disciplines.forEach((id) => {
            const taregtDiscipline: Discipline | undefined = _.find(disciplineItems, {
              id,
            });
            if (taregtDiscipline) {
              disciplines.push(taregtDiscipline);
            }
          });
        }

        let media = [];

        if (item.media) {
          media = item.media.map((mediaItem) => {
            if (item.mediaProcessing?.items?.length > 0) {
              const proccessingMediaItem = item.mediaProcessing.items.find(
                (o) => o.id === mediaItem.id
              );
              if (proccessingMediaItem?.status === "COMPLETE") {
                return {
                  ...mediaItem,
                  isProcessed: true,
                  status:
                    proccessingMediaItem?.status === "COMPLETE"
                      ? "complete"
                      : "processing",
                  sizes: proccessingMediaItem?.sizes,
                };
              }
            }

            return {
              ...mediaItem,
              status: "complete",
            };
          });
        }

        return {
          ...item,
          disciplines,
          price: item.price ? formatCurrency(item.price, item.currency) : "0",
          media,
        };
      }),
    };
  } catch (err) {
    console.log("getAdverts error", { err });
  }
}
