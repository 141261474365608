export const jobSubCategories = [
  { label: 'Equestrian admin', value: 'equestrian-admin' },
  { label: 'Equestrian transport', value: 'equestrian-transport' },
  { label: 'Welfare advisor', value: 'welfare-advisor' },
  { label: 'Work rider', value: 'work-rider' },
  { label: 'Trainees/apprenticeships', value: 'trainees-apprenticeships' },
  { label: 'Stable staff', value: 'stable-staff' },
  { label: 'Stable yard manager', value: 'stable-yard-manager' },
  { label: 'Riding instructor/trainer', value: 'riding-instructor-trainer' },
  { label: 'Rider', value: 'rider' },
  { label: 'Nanny/au pair', value: 'nanny-au-pair' },
  { label: 'Hunt staff', value: 'hunt-staff' },
  { label: 'Groom', value: 'groom' },
  { label: 'Groundsman/gardening', value: 'groundsman-gardening' },
  { label: 'Equine nutritionist', value: ' equine-nutritionist' },
  { label: 'Equine lecturer', value: 'equine-lecturer' },
  { label: 'Equestrian sales', value: 'equestrian-sales' },
  { label: 'Equestrian marketing', value: 'equestrian-marketing' },
  { label: 'Equestrian journalism', value: 'equestrian-journalism' },
];

export const liveryYardSubCategories = [
  { label: 'Competition livery', value: 'competition-livery' },
  { label: 'DIY livery', value: 'diy-livery' },
  { label: 'Full livery', value: 'full-livery' },
  { label: 'Grass livery', value: 'grass-livery' },
  { label: 'Hunter livery', value: 'hunter-livery' },
  { label: 'Part livery', value: 'part-livery' },
  { label: 'Retirement livery', value: 'retirement-livery' },
  { label: 'Sales livery', value: 'sales-livery' },
];

export const trailerFacilities = [
  { label: 'Tack locker', value: 'tack-locker' },
  { label: 'Skirt lockers', value: 'skirt-lockers' },
  { label: 'Water tank', value: 'water-tank' },
  { label: 'Horse wash down', value: 'horse-wash-down' },
  { label: 'Toilet', value: 'toilet' },
  { label: 'Shower', value: 'shower' },
  { label: 'Heating', value: 'heating' },
  { label: 'Generator', value: 'generator' },
  { label: 'Electric hook up', value: 'electric-hook-up' },
  { label: 'Hob', value: 'hob' },
  { label: 'Cooker', value: 'cooker' },
  { label: 'TV', value: 'tv' },
  { label: 'DVD player', value: 'dvd-player' },
];

export const trailerWeights = [
  { label: '3.5t', value: '3-5-t' },
  { label: '5t', value: '5-t' },
  { label: '7.5t', value: '7-5-t' },
  { label: '+7.5t', value: 'plus-7-5-t' },
];

export const liveryFacilities = [
  { label: 'Outdoor school', value: 'outdoor-school' },
  { label: 'Indoor school', value: 'indoor-school' },
  { label: 'Cross-country course', value: 'cross-country-course' },
  { label: 'Good local hacking', value: 'good-local-hacking' },
  { label: 'Field shelters', value: 'field-shelters' },
  { label: 'Horsewalker', value: 'horsewalker' },
  { label: 'Livery rest/coffee room', value: 'livery-restcoffee-room' },
  { label: 'Rug room', value: 'rug-room' },
  { label: 'Rug washing on site', value: 'rug-washing-on-site' },
  { label: 'Shop on site', value: 'shop-on-site' },
  { label: 'Outbuildings', value: 'outbuildings' },
  { label: 'Pool', value: 'pool' },
  { label: 'Vineyard', value: 'vineyard' },
];

// they are the same at the moment
export const propertyFacilities = [...liveryFacilities];

export const horseTypeOptions = [
  { label: 'Horse', value: '5ac0973a-2239-4048-a953-f812bc74d927' },
  { label: 'Pony', value: '5ae802e7-5b1b-4818-a67e-ecdf8e70fe88' },
];

export const livingOptions = [
  { label: 'None', value: 'none' },
  { label: 'Day', value: 'day' },
  { label: 'Overnight', value: 'overnight' },
  { label: 'Night', value: 'night' },
];

export const conditionOptions = [
  { label: 'New', value: 'new' },
  { label: 'Used', value: 'used' },
];

export const propertyTypeOptions = [
  { label: 'House', value: 'house' },
  { label: 'Bungalow', value: 'bungalow' },
  { label: 'Land', value: 'land1' },
  { label: 'Equestrian centre', value: 'equestrian-centre' },
  { label: 'Farm', value: 'farm' },
  { label: 'Barn conversion', value: 'barn-conversion' },
];

export const ftptOptions = [
  // { label: 'Horse grooms', value: 'horse-grooms' },
  // { label: 'Riding instructors/trainer', value: 'riding-instructorstrainer' },
  // { label: 'Riders', value: 'riders' },
  { label: 'Full Time', value: 'full-time' },
  { label: 'Part Time', value: 'part-time' },
];

export const typeOfStablesOptions = [
  { label: 'Indoor', value: 'indoor' },
  { label: 'Outdoor', value: 'outdoor' },
  { label: 'Indoor and outdoor', value: 'indoor-and-outdoor' },
];

export const saleTypeOptions = [
  { label: 'Sale', value: '80b50ee5-0f84-48e7-92f1-65793a48d597' },
  { label: 'Loan', value: '89ac3e31-fcfb-4794-8af9-6342f2d2b56e' },
];

export const disciplinesOptions = [
  {
    value: 'b004692a-ca4b-4b1e-830b-c1b9ebc40290',
    label: 'Showing',
  },
  {
    value: '720e53c2-686e-44a4-a589-00670278768b',
    label: 'Eventing',
  },
  {
    value: 'a1718945-dfb8-4a98-87e3-cbefbfcdccef',
    label: 'Hunting',
  },
  {
    value: 'c1ca192f-696d-4f9c-b6ff-c9afdfac03d5',
    label: 'Mother-daughter share',
  },
  {
    value: '805fc027-c940-4969-8421-245f494c6631',
    label: 'Driving',
  },
  {
    value: '48d04c8a-9cd7-42d7-89b4-58394647dd54',
    label: 'All-Rounder',
  },
  {
    value: '08e58da6-3de9-46ef-82f4-39cfd7345d65',
    label: 'Companion',
  },
  {
    value: '690bfc49-14b5-4655-8e25-52a38fafe986',
    label: 'Dressage',
  },
  {
    value: '11586567-fffd-4e92-9e93-b5f02ec4dfb0',
    label: 'Show Jumping',
  },
  {
    value: '0eb2e937-a693-4197-b1e2-200ad62ff9f9',
    label: 'Broodmare',
  },
  {
    value: '91040eed-64d4-4a61-9e0b-1d84b1694236',
    label: 'Youngstock',
  },
  {
    value: '4af8f987-c219-4c70-900f-f500c621f715',
    label: 'Polo',
  },
  {
    value: '1a364baf-0b4f-4577-8f0f-f4fdb49a74a3',
    label: 'Endurance',
  },
  {
    value: 'c0dcb6cb-293e-45ee-8774-b911787e6331',
    label: 'Pony Club',
  },
  {
    value: 'a9e78d7c-2cf5-4983-b5be-addc67e1621a',
    label: 'Ex Racehorse',
  },
  {
    value: 'd9c3aaf8-5dcb-4c1c-9507-4e8b511e54aa',
    label: 'Happy Hacker',
  },
];

export const trailerSaleTypeOptions = [
  { label: 'Sale', value: 'sale' },
  { label: 'Hire', value: 'hire' },
];

export const propertySaleTypeOptions = [
  { label: 'Sale', value: 'sale' },
  { label: 'Let', value: 'let' },
];

export const trailerTypeOptions = [
  { label: 'Horsebox', value: 'horsebox' },
  { label: 'Trailer', value: 'trailer' },
];

export const gradedWithOptions = [
  { label: 'AES (Anglo European Studbook)', value: 'aes-anglo-european-studbook' },
  { label: 'Brandenburg', value: 'brandenburg1' },
  { label: 'Danish Warmblood Society', value: 'danish-warmblood-society' },
  { label: 'Hanoverian', value: 'hanoverian' },
  { label: 'Holstein', value: 'holstein' },
  { label: 'Irish Sporthorse Studbook (ISH)', value: 'irish-sporthorse-studbook-ish' },
  { label: 'KWPN (Dutch warmblood)', value: 'kwpn-dutch-warmblood' },
  {
    label: 'Nederlands Rijpaarden en Pony Stamboek (NRPS)',
    value: 'nederlands-rijpaarden-en-pony-stamboek-nrps',
  },
  { label: 'Oldenburg', value: 'oldenburg' },
  {
    label: 'RID (Irish Draught Horse Studbook)',
    value: 'rid-irish-draught-horse-studbook',
  },
  { label: 'Scottish Sports Horse (SSH)', value: 'scottish-sports-horse-ssh' },
  { label: 'Selle Français', value: 'selle-francais' },
  {
    label: 'SHB(GB) (Sport Horse Breeding of Great Britain)',
    value: 'shbgb-sport-horse-breeding-of-great-britain',
  },
  { label: 'Swedish warmblood', value: 'swedish-warmblood' },
  { label: 'Trakehener', value: 'trakehener' },
  {
    label: 'Warmblood Breeders Studbook - UK (WBS-UK)',
    value: 'warmblood-breeders-studbook-uk-wbs-uk',
  },
  { label: 'Westfalian', value: 'westfalian' },
  { label: 'Zangersheide', value: 'zangersheide' },
  {
    label: 'ZfDP (Zuchtverband für Deutsche Pferde)',
    value: 'zfdp-zuchtverband-fuer-deutsche-pferde',
  },
];

export const availabilityOptions = [
  { label: 'Fresh', value: 'fresh' },
  { label: 'Chilled', value: 'chilled' },
  { label: 'Frozen', value: 'frozen' },
  { label: 'Natural', value: 'natural' },
];

export const priceTypeOptions = [
  { label: 'Per month', value: 'per-month' },
  { label: 'Per week', value: 'per-week' },
  { label: 'Per day', value: 'per-day' },
];

// export const colourOptions = [
//   { label: 'Bay', value: 'bay' },
//   { label: 'Black', value: 'black' },
//   { label: 'Blue Roan', value: 'blue' },
//   { label: 'Brown', value: 'brown' },
// ];

export const genderOptions = [
  { label: 'Mare', value: '47486bb6-127c-486c-af3f-d02a626f5735' },
  { label: 'Gelding', value: '42b218c0-b10c-402d-b362-5a8efea8f603' },
  { label: 'Stallion', value: '6e9289f4-417c-4059-88cf-7ddd21920638' },
];

export const colourOptions = [
  {
    label: 'Appaloosa',
    value: '493c42e4-207f-4619-a550-5bb23712e8cb',
  },
  {
    label: 'Bay',
    value: '2b520e1a-6f0d-4bbc-a7d8-0090eefccf86',
  },
  {
    label: 'Bay Roan',
    value: '8101b130-a32d-4235-84c1-43db2132efc5',
  },
  {
    label: 'Black',
    value: '3b7125a8-0d81-47e2-bce0-8ba506bff1b1',
  },
  {
    label: 'Blue Roan',
    value: '6cfb0e22-d285-4a14-9a98-22a6a77622b6',
  },
  {
    label: 'Brown',
    value: 'dd08a9a7-087f-464a-9fb2-6257ffe80f35',
  },
  {
    label: 'Buckskin',
    value: 'c5943596-94df-4656-b7fa-d723c45a9185',
  },
  {
    label: 'Chestnut',
    value: '6c212a6a-8118-451b-ab93-987947a50ba3',
  },
  {
    label: 'Cremello',
    value: 'deb98b7b-5003-423d-913d-7aeccf1f7b48',
  },
  {
    label: 'Dapple Grey',
    value: '0ebb87e6-49ec-4cf4-bb19-5b6fe919fe2d',
  },
  {
    label: 'Dark Bay',
    value: '5ca7e68c-a51e-4009-a6c0-e336b3571591',
  },
  {
    label: 'Dark Brown',
    value: '415c45b8-b2f4-4a15-8767-74fa6da2f488',
  },
  {
    label: 'Dun',
    value: 'af90ada9-a3dd-48c9-a330-e213b9ea3772',
  },
  {
    label: 'Flea-bitten Grey',
    value: '1a618f2c-2fb6-413e-968a-d3b3c2a1a5b6',
  },
  {
    label: 'Grey',
    value: '5d2dd6c0-065d-4bd1-aab1-4a918103749b',
  },
  {
    label: 'Iron Grey',
    value: '2b32355e-c694-42ed-b5ed-78e63e48e2e3',
  },
  {
    label: 'Liver Chestnut',
    value: '2eb33c64-99c7-44c3-b655-819918e2c5c0',
  },
  {
    label: 'Palomino',
    value: '3c806c9d-4d86-4b0a-967e-0289354a828e',
  },
  {
    label: 'Piebald',
    value: '38e51bb5-9758-4e15-9ca0-5aa92530a86c',
  },
  {
    label: 'Roan',
    value: '18abb053-1993-46b5-b7ea-958dd5cdb17b',
  },
  {
    label: 'Rose Grey',
    value: '3a393a8f-33df-4c7e-91f1-3971c5775002',
  },
  {
    label: 'Skewbald',
    value: 'e6d96227-916b-4f1f-811c-54d7c9024409',
  },
  {
    label: 'Spotted',
    value: '320f7904-c902-4a04-a12a-1b1d2297a98f',
  },
  {
    label: 'Strawberry Roan',
    value: '7fdfd080-09a5-439d-ab54-559bce68ad88',
  },
  {
    label: 'Tri-Coloured',
    value: '80d0b928-8c46-41b3-9f1c-e551cafd90c7',
  },
];
