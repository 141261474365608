import React, { ReactElement, useEffect, useRef } from "react";
import "./styles.less";

import { Space, Typography } from "antd";
import VizSensor from "react-visibility-sensor";

import { useLocation } from "react-router-dom";
import YouTube from "react-youtube";
import Card from "../Card";

import { categoryPathById } from "../../utils/categoryPaths";
import { isServer } from "../../utils";

const { REACT_APP_CLASSIFIEDS_URL, REACT_APP_WHICKR_URL } = process.env;

/**
 * Description
 */

type Props = {
  advertId: string;
  price?: string;
  sellerName: string | undefined;
  sellerLocation: string;
  title: string;
  imageSource?: string;
  size?: string;
  gender: string;
  age: number;
  height: number;
  saleType: string;
  favouriteId: string;
  isSold: boolean;
  isPOA: boolean;
  isFree: boolean;
  isFixedHeight?: boolean;
  showRemoveWarning?: boolean;
  youTubeVideo?: string;
  onFavouriteChanged?: (id: string, isFavourite: boolean) => void;
  mediaHeight?: number;
  categoryId?: string;
  publishedAt?: string;
  widgetTypeId?: string;
};

const defaultProps = {
  size: "regular",
  price: "0",
  showRemoveWarning: false,
  onFavouriteChanged: () => {},
  user: { loggedIn: false },
  isFixedHeight: false,
  mediaHeight: null,
  categoryId: "horse",
  publishedAt: null,
  widgetTypeId: "0",
};

const WidgetAdvertCard: React.FC<Props> = ({
  advertId,
  slug,
  price,
  sellerName,
  sellerLocation,
  title,
  previewMedia,
  description,
  size,
  gender,
  age,
  height,
  saleType,
  favouriteId,
  isSold,
  isPOA,
  isFree,
  isFixedHeight,
  onFavouriteChanged,
  showRemoveWarning,
  user,
  autoPlayOnScroll,
  mediaHeight,
  type,
  sellerId,
  categoryId,
  publishedAt,
  inIframe,
  widgetTypeId,
  index,
}: Props): ReactElement => {
  const location = useLocation();
  const [forcePlaceholder, setForcePlaceholder] = React.useState(false);

  useEffect(() => {
    if (previewMedia) {
      if (previewMedia.type === "youtube") {
        setTimeout(() => {
          const offset = 0;
          const top = youTubeContainerRef?.current?.getBoundingClientRect().top;
          if (top + offset >= 0 && top - offset <= window.innerHeight) {
            youTubeRef?.current?.playVideo();
          }
        }, 1000);
      }
    }
  }, []);

  const youTubeRef = useRef();
  const youTubeContainerRef = useRef();
  const videoPlaying = useRef(false);
  let mediaStyles = {
    backgroundImage: 'url("/img/photo-placeholder.png")',
    backgroundSize: "contain",
  };

  if (previewMedia && previewMedia.type === "image") {
    mediaStyles = {
      // backgroundImage: `url("${previewMedia.url || '/img/photo-placeholder.png'}")`,
      backgroundSize: previewMedia.url ? "cover" : "contain",
    };
  }

  // let backLink = `${REACT_APP_CLASSIFIEDS_URL}/`;

  // if (typeof window !== 'undefined') {
  //   backLink = encodeURIComponent(window?.location?.href);
  // }

  let advertLink = `${REACT_APP_WHICKR_URL}/advert/${advertId}`;
  if (slug) {
    advertLink = `${REACT_APP_WHICKR_URL}/marketplace/${categoryPathById[categoryId]}/${slug}`;
  }

  const extra = {};

  // if (inIframe) {
  //   extra.onClick = (e) => {
  //     e.preventDefault();
  //     parent.window.location.href = `${advertLink}?p=horseandhound&backLink=${backLink}`;
  //   };
  // }

  const mediaProps = {};

  // if (index > 3) {
  //   mediaProps.lazy = true;
  // }

  if (previewMedia) {
    if (previewMedia.type === "image") {
      const withoutExt = previewMedia?.url.replace(/\.[^/.]+$/, "");
      console.log("previewMedia", previewMedia);
      if (previewMedia.isProcessed) {
        mediaProps.srcSet = `
        ${withoutExt}-sm@1x.webp 320w,
        ${withoutExt}-sm@1x.jpg 320w,
      `;
        mediaProps.sizes = "(max-width: 320px) 320px, (max-width: 768px) 768px";
      } else {
        mediaProps.src = `${process.env.REACT_APP_OLD_CDN_URL}/adverts/${previewMedia.fileName}`;
      }
    }

    if (previewMedia.type === "video") {
      const withoutExt = previewMedia?.url.replace(/\.[^/.]+$/, "");
      if (previewMedia.isProcessed) {
        mediaProps.src = `${withoutExt}.mp4#t=0.1`;
      } else {
        mediaProps.src = `${process.env.REACT_APP_OLD_CDN_URL}/adverts/${previewMedia.fileName}`;
      }
    }
  }
  const handleImageError = (e) => {
    // e.target.src = `${image}.jpg`;
    setForcePlaceholder(true);
  };

  function handleMediaLoad(event) {
    event.target.style.opacity = 1;
  }
  const mediaComponent = (
    <>
      {!forcePlaceholder && previewMedia && previewMedia.type === "image" && (
        <img
          // src={`${image}-sm@1x.jpg`}
          {...mediaProps}
          width="100%"
          height="100%"
          className="advertCardImage"
          alt="Advert Thumbnail"
          loading={index > 1 ? "lazy" : "eager"}
          onLoad={handleMediaLoad}
          style={{ opacity: isServer ? 1 : 0 }}
          onError={handleImageError}
        />
      )}
      {!forcePlaceholder && previewMedia && previewMedia.type === "video" && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        >
          <video
            height="100%"
            id={previewMedia.id}
            muted
            controls={false}
            loop
            playsInline
            className="video"
            style={{
              overflow: "hidden",
              height: "100%",
            }}
          >
            <source src={`${previewMedia.url}#t=0.1`} />
            Your browser does not support the video tag.
          </video>
        </div>
      )}
      {previewMedia && previewMedia.type === "youtube" && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            width: "100%",
            height: "100%",
            overflow: "hidden",
          }}
          className="youtubeHolder"
          ref={youTubeContainerRef}
        >
          <YouTube
            onReady={(e) => {
              youTubeRef.current = e.target;
            }}
            videoId={previewMedia.id} // defaults -> null
            opts={{
              playerVars: { rel: 0, showinfo: 0, mute: 1, controls: 0 },
              width: "100%",
              height: "100%",
            }}
          />
        </div>
      )}
    </>
  );

  const cardComponentZero = (
    <Card style={{ backgroundColor: "transparent", height: "100%" }}>
      <a
        href={`${advertLink}?p=horseandhound&backLink=close`}
        className="advertCardContainer"
        target="_blank"
        rel="noreferrer"
      >
        <div
          className="advertCardImageHolder"
          style={{
            ...mediaStyles,
          }}
        >
          {categoryId !== "jobs" && (
            <div className="advertPriceInfoHolder">
              {isSold ? (
                <div className="advertPrice sold">SOLD</div>
              ) : (
                <div className="advertPrice">
                  {isPOA ? "POA" : isFree || parseInt(price) === 0 ? "Free" : price}
                </div>
              )}
            </div>
          )}
          {mediaComponent}
        </div>
        <div className="advertInfoHolder">
          <div className="advertHeaderHolder">
            <Typography.Title level={4} className="advertTitle">
              {title}
            </Typography.Title>
          </div>
          <div className="advertFooterHolder">
            {/* <Space size="large" className="sellerInfoText" style={{ marginBottom: 4 }}>
              {publishedAt && (
                <Space>
                  <CalendarOutlined />
                  <span>{publishedAt}</span>
                </Space>
              )}
              {sellerLocation && (
                <Space>
                  <PushpinOutlined />
                  <span>{sellerLocation}</span>
                </Space>
              )}
            </Space> */}
            {age && (
              <ul className="advertFooterInfo">
                {age && <li>{age?.toString()}yrs</li>}
                {height && <li>{height.toString()}hh</li>}
                {gender && <li>{gender}</li>}
              </ul>
            )}
            <div className="whickrLinkHolder">
              <Space>
                <span className="viewText">View on Whickr</span>
                <img src="/img/arrowRightIcon.svg" alt="View Advert" />
              </Space>
            </div>
          </div>
        </div>
      </a>
    </Card>
  );

  const mediaComponentTwo = (
    <>
      {!forcePlaceholder && previewMedia && previewMedia.type === "image" && (
        <img
          // src={`${image}-sm@1x.jpg`}
          {...mediaProps}
          width="100%"
          height="100%"
          className="advertCardImage"
          alt="Advert Thumbnail"
          loading={index > 1 ? "lazy" : "eager"}
          onLoad={handleMediaLoad}
          style={{ opacity: isServer ? 1 : 0 }}
          onError={handleImageError}
        />
      )}
      {!forcePlaceholder && previewMedia && previewMedia.type === "video" && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        >
          <video
            height="100%"
            id={previewMedia.id}
            muted
            controls={false}
            loop
            playsInline
            className="video"
            style={{
              overflow: "hidden",
              height: "100%",
            }}
          >
            <source src={`${previewMedia.url}#t=0.1`} />
            Your browser does not support the video tag.
          </video>
        </div>
      )}
      {previewMedia && previewMedia.type === "youtube" && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            width: "100%",
            height: "100%",
            overflow: "hidden",
          }}
          className="youtubeHolder"
          ref={youTubeContainerRef}
        >
          <YouTube
            onReady={(e) => {
              youTubeRef.current = e.target;
            }}
            videoId={previewMedia.id} // defaults -> null
            opts={{
              playerVars: { rel: 0, showinfo: 0, mute: 1, controls: 0 },
              width: "100%",
              height: "100%",
            }}
          />
        </div>
      )}
    </>
  );

  const cardComponentTwo = (
    <Card style={{ backgroundColor: "transparent", height: "100%" }}>
      <a
        href={`${advertLink}?p=horseandhound&backLink=close`}
        className="advertCardContainer"
        rel="noreferrer"
        target="_blank"
      >
        <div
          className="advertCardImageHolder"
          style={{
            ...mediaStyles,
          }}
        >
          {mediaComponentTwo}
        </div>

        <div className="advertInfoHolder">
          {categoryId !== "jobs" && (
            <div className="advertPriceInfoHolder">
              {isSold ? (
                <div className="advertPrice sold">SOLD</div>
              ) : (
                <div className="advertPrice">
                  {isPOA ? "POA" : isFree || parseInt(price) === 0 ? "Free" : price}
                </div>
              )}
            </div>
          )}
          <div className="advertHeaderHolder">
            <Typography.Title level={4} className="advertTitle">
              {title}
            </Typography.Title>
          </div>
          <div className="advertFooterHolder">
            {/* <Space size="large" className="sellerInfoText" style={{ marginBottom: 4 }}>
              {publishedAt && (
                <Space>
                  <CalendarOutlined />
                  <span>{publishedAt}</span>
                </Space>
              )}
              {sellerLocation && (
                <Space>
                  <PushpinOutlined />
                  <span>{sellerLocation}</span>
                </Space>
              )}
            </Space> */}
            {/* {age && (
              <ul className="advertFooterInfo">
                {age && <li>{age?.toString()}yrs</li>}
                {height && <li>{height.toString()}hh</li>}
                {gender && <li>{gender}</li>}
              </ul>
            )} */}
            <div className="whickrLinkHolder">
              <Space>
                <span className="viewText">View on Whickr</span>
                <img src="/img/arrowRightIcon.svg" alt="View Advert" />
              </Space>
            </div>
          </div>
        </div>
      </a>
    </Card>
  );

  const cardComponent = widgetTypeId === "2" ? cardComponentTwo : cardComponentZero;

  if (autoPlayOnScroll) {
    return (
      <VizSensor
        partialVisibility
        offset={{ top: 220, bottom: 260 }}
        onChange={(isVisible) => {
          if (previewMedia) {
            if (previewMedia.type === "youtube") {
              if (isVisible) {
                if (youTubeRef.current && !videoPlaying.current) {
                  videoPlaying.current = true;
                  youTubeRef.current.seekTo(0);
                  youTubeRef.current.playVideo();
                }
              } else if (youTubeRef.current && videoPlaying.current) {
                videoPlaying.current = false;
                youTubeRef.current.pauseVideo();
              }
            }

            if (previewMedia.type === "video") {
              if (isVisible) {
                if (!videoPlaying.current) {
                  videoPlaying.current = true;
                  if (document) {
                    document.getElementById(previewMedia.id).currentTime = 0;
                    document.getElementById(previewMedia.id)?.play();
                  }
                }
              } else if (videoPlaying.current) {
                videoPlaying.current = false;
                if (document) {
                  document.getElementById(previewMedia.id)?.pause();
                  document.getElementById(previewMedia.id).currentTime = 0;
                }
              }
            }
          }
        }}
      >
        {cardComponent}
      </VizSensor>
    );
  }

  return cardComponent;
};

WidgetAdvertCard.defaultProps = defaultProps;
export default WidgetAdvertCard;
