import React, { ReactElement } from 'react';

import { Button as AntButton } from 'antd';
/**
 * Primary UI component for user interaction
 */

type Props = {
  label: string;
  type?: string;
  onClick?: (e: React.MouseEvent) => void;
  block?: boolean;
};

const defaultProps = {
  type: 'default',
  onClick: undefined,
  block: false,
};

const Button: React.FC<Props> = ({
  onClick,
  label,
  type,
  block,
  ...props
}: Props): ReactElement => (
  <AntButton
    type={type === 'primary' ? 'primary' : 'default'}
    size="large"
    onClick={onClick}
    block={block}
    {...props}
  >
    {label}
  </AntButton>
);

Button.defaultProps = defaultProps;
export default Button;
