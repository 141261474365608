import { Carousel, Collapse } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import React, { ReactElement, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Head from '../../components/Head';

import './styles.less';

const { Panel } = Collapse;

const HowToGetScene: React.FC = (props): ReactElement => {
  const history = useHistory();

  const locationRef = useRef(null);

  const [category, setCategory] = useState('horse.sale');

  let backLink = `${process.env.REACT_APP_CLASSIFIEDS_URL}`;
  if (typeof window !== 'undefined') {
    backLink = encodeURIComponent(window?.location?.href);
  }
  const ref = useRef();

  return (
    <div>
      <Head title="How to get the most out of the Horse &amp; Hound Classified Site - Horse &amp; Hound" />
      <section>
        <div className="carouselHolder">
          <Carousel autoplay ref={ref}>
            <div className="carouselSlide">
              <img src="/img/slider-1.jpg" />
            </div>
            <div className="carouselSlide">
              <img src="/img/slider-2.jpg" />
            </div>
            <div className="carouselSlide">
              <img src="/img/slider-3.jpg" />
            </div>
          </Carousel>
          <div className="carouselArrowsHolder">
            <div
              className="arrowContainer"
              onClick={() => {
                ref.current?.prev();
              }}
            >
              <LeftOutlined className="arrow" />
            </div>
            <div className="arrowContainer">
              <RightOutlined
                className="arrow"
                onClick={() => {
                  ref.current?.next();
                }}
              />
            </div>
          </div>
        </div>
        <div className="container content">
          <h1 className="heading howToHeading">
            How to get the most out of the Horse &amp; Hound Classified Site
          </h1>
          <p className="howToParagraph">
            Welcome to the Horse &amp; Hound Classified Site – the home of quality horses
            for over 135 years.
          </p>
          <p className="howToParagraph">
            Whether you are casually browsing the horse market or have specific
            requirements for your next horse, we can help you on your journey. <br />
            Don&apos;t forget we also have adverts for horseboxes, equestrian jobs and
            equestrian properties too.
          </p>
          <p className="howToParagraph">
            Our easy to use site has been optimised to make searching for your new horse
            as simple as possible.
          </p>
          <p className="howToParagraph">
            We have put together the below helpful information about some of the key
            features of our website to ensure you make the most of the Horse &amp; Hound
            Classified Site;
          </p>
        </div>
        <div className="container content howToContent">
          <div className="howToSection">
            <div className="howToSectionImage">
              <img src="/img/how-to/icon-advanced-search.png" />
            </div>
            <div className="howToSectionContent">
              <h3 className="howToSectionHeading">How to use advanced search</h3>
              <p className="howToParagraph">
                We know that searching for a horse can be challenging so we have made our
                advanced search function practical and easy to use. Results are based on
                the adverts that are currently on the site, so if we don&apos;t have
                something that matches your specific requirements try widening your
                search.
              </p>
              <Collapse ghost expandIcon={() => null}>
                <Panel
                  header={
                    <span className="panelHeader">
                      Step-by-step guide to use advanced search
                    </span>
                  }
                  key="1"
                  className="howToPanel panel"
                >
                  <div className="panelContent">
                    <ol>
                      <li>Navigate to the home page search </li>
                      <li>Click Advanced Search where more search options will appear</li>
                      <li>
                        Select your criteria. In Advanced Search you can select more than
                        one type, gender and choose your height and price parameters{' '}
                      </li>
                      <li>Click search </li>
                    </ol>
                  </div>
                </Panel>
              </Collapse>
              <p className="howToParagraph">
                We may not always have horses that match your requirements but set up a
                &quot;Wanted&quot; advert and we will notify you as soon as a matching
                advert is added to the site.
              </p>
            </div>
          </div>
          <div className="howToSection">
            <div className="howToSectionImage">
              <img src="/img/how-to/icon-email.png" />
            </div>
            <div className="howToSectionContent">
              <h3 className="howToSectionHeading">How to set up a wanted advert</h3>
              <p className="howToParagraph">
                Be sure you are the first to hear about new adverts that match your search
                criteria with our &apos;wanted advert&apos; feature.
              </p>
              <p className="howToParagraph">
                Simply complete the &quot;create wanted advert form&quot; and when new
                adverts are added which match your criteria you will be one of the first
                people to know about it and be able to enquire about your next dream
                horse. Once your search is over you can simply turn off email alerts from
                within your account or by using the link within the email.
              </p>
              <Collapse ghost expandIcon={() => null}>
                <Panel
                  header={
                    <span className="panelHeader">
                      Step-by-step guide to create a wanted advert
                    </span>
                  }
                  key="1"
                  className="howToPanel panel"
                >
                  <div className="panelContent">
                    <ol>
                      <li>Visit the Horse &amp; Hound Classified Site</li>
                      <li>Navigate to the main search page</li>
                      <li>
                        Below the filters, click on the button &quot;Place a wanted
                        advert&quot;
                      </li>
                      <li>Once logged in, complete the create wanted advert form</li>
                      <li>Click &quot;Publish Wanted Advert&quot;</li>
                      <li>Sit back and let the adverts come to you</li>
                    </ol>
                  </div>
                </Panel>
              </Collapse>
            </div>
          </div>
          <div className="howToSection">
            <div className="howToSectionImage">
              <img src="/img/how-to/icon-like.png" />
            </div>
            <div className="howToSectionContent">
              <h3 className="howToSectionHeading">How to save an advert</h3>
              <p className="howToParagraph">
                Do you like the look of more than one horse or pony on our site? Save it!
                Whickr&apos;s &apos;Add to Stable&apos; feature allows you to easily mark
                an advert that you want to keep an eye on, find easily again later or
                compare to another. Use the heart button next to the advert title on
                Whickr and we will create a list in the &apos;My Stable&apos; area for
                you.
              </p>
              <Collapse ghost expandIcon={() => null}>
                <Panel
                  header={
                    <span className="panelHeader">
                      Step-by-step guide to saving an advert
                    </span>
                  }
                  key="1"
                  className="howToPanel panel"
                >
                  <div className="panelContent">
                    <ol>
                      <li>Log in or register for a Whickr account </li>
                      <li>
                        When viewing an advert which you want to come back to at a later
                        date, click the heart button at the top right of the page.
                      </li>
                      <li>
                        To see your saved adverts go to &apos;Stable&apos; and you will
                        see each horse (or horsebox!) side by side.
                      </li>
                      <li>
                        Send a link to a friend, trainer or vet for a first opinion!{' '}
                      </li>
                    </ol>
                  </div>
                </Panel>
              </Collapse>
              <p className="howToParagraph">
                You can remove an advert from your Stable by clicking on the advert heart
                again. Please note that most of our adverts are only on the site for 6
                weeks so once the advert has expired, it will be removed from your Saved
                Adverts list.
              </p>
            </div>
          </div>
          <div className="howToSection">
            <div className="howToSectionImage">
              <img src="/img/how-to/icon-location.png" />
            </div>
            <div className="howToSectionContent">
              <h3 className="howToSectionHeading">How to search by postcode</h3>
              <p className="howToParagraph">
                Find horses and ponies that are just down the road or 600 miles away. We
                are the only equestrian classified platform with postcode search
                functionality, making it easier to narrow down or widen your search by
                location.
              </p>
              <Collapse ghost expandIcon={() => null}>
                <Panel
                  header={
                    <span className="panelHeader">
                      Step-by-step guide to search by postcode
                    </span>
                  }
                  key="1"
                  className="howToPanel panel"
                >
                  <div className="panelContent">
                    <ol>
                      <li>Navigate to our Search Page </li>
                      <li>Enter your post code </li>
                      <li>Select your distance </li>
                      <li>Add other criteria such as height or gender </li>
                      <li>Click search </li>
                    </ol>
                  </div>
                </Panel>
              </Collapse>
              <p className="howToParagraph">
                We might not always have horses that match your requirements but set up a
                &quot;Wanted&quot; advert and we will notify you as soon as a matching
                advert is added to the site.
              </p>
            </div>
          </div>
          <div className="howToSection">
            <div className="howToSectionImage">
              <img src="/img/how-to/icon-video.png" />
            </div>
            <div className="howToSectionContent">
              <h3 className="howToSectionHeading">How to find adverts with media</h3>
              <p className="howToParagraph">
                We have made it really easy to find adverts that include media as we know
                how important it is to see a horse in action before you make that all
                important first visit. Our advertisers can add up to five photos, three x
                1 minute videos and five YouTube videos to their adverts and you can
                filter your search by them.
              </p>
              <Collapse ghost expandIcon={() => null}>
                <Panel
                  header={
                    <span className="panelHeader">
                      Step-by-step guide to find adverts with media
                    </span>
                  }
                  key="1"
                  className="howToPanel panel"
                >
                  <div className="panelContent">
                    <ol>
                      <li>Start your search by selecting your criteria</li>
                      <li>
                        On the search results page, navigate to the left hand column
                      </li>
                      <li>Select the With Media Only filter</li>
                      <li>
                        Browse adverts which include photos, videos and YouTube videos
                      </li>
                    </ol>
                  </div>
                </Panel>
              </Collapse>
            </div>
          </div>
          <div className="howToSection">
            <div className="howToSectionImage">
              <img src="/img/how-to/icon-profile.png" />
            </div>
            <div className="howToSectionContent">
              <h3 className="howToSectionHeading">What else can you do?</h3>
              <p className="howToParagraph">
                When you&apos;ve logged into your account, you can manage all aspects in
                the &apos;My Profile&apos; area. Change your password, update your profile
                and manage your notifications all from one easy portal. Plus, if you have
                a horse, property or horsebox to sell or a job to advertise you can easily
                manage your adverts.
              </p>
            </div>
          </div>
          <div className="howToSection">
            <div className="howToSectionImage">
              <img src="/img/how-to/icon-questionmark.png" />
            </div>
            <div className="howToSectionContent">
              <h3 className="howToSectionHeading">Do you need help?</h3>
              <p className="howToParagraph">
                Check out our FAQ section where we answer loads more questions.
              </p>
              <p className="howToParagraph">
                Our partners at Whickr have a dedicated customer service team who you can
                call or WhatsApp anytime 9am-5pm Mon-Fri on 07399 535683 or email{' '}
                <a style={{ color: '#ee5827' }} href="mailto:tackroom@whickr.com">
                  tackroom@whickr.com
                </a>
                . <br />
                They will be more than happy to help!
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

// HowToGetScene.fetchData = async (ssrStore, match, req) => {
//   const SSR = withSSRContext({ req });
//   const searchParamsInUrl = qs.parse('?sort=publishedAt.desc&page=1', {
//     ignoreQueryPrefix: true,
//   });

//   const { apiQuery } = getSearchOptions(searchParamsInUrl);
//   const advertItems = await getAdverts({ ...apiQuery, limit: 6, amp: SSR });
//   console.log('advertItems', advertItems);
//   ssrStore.dispatch(updateLanding({ adverts: advertItems }));
// };

export default HowToGetScene;
