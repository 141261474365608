export const setCookie = (cname, cvalue, exdays) => {
  if (typeof document !== 'undefined') {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${cname}=${cvalue};${expires};path=/`;
  }
};
export const getCookie = (cname) => {
  if (typeof document !== 'undefined') {
    const name = `${cname}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
  }

  return '';
};
export const removeCookie = (name, path, domain) => {
  if (typeof document !== 'undefined') {
    if (getCookie(name)) {
      document.cookie = `${name}=${path ? `;path=${path}` : ''}${
        domain ? `;domain=${domain}` : ''
      };expires=Thu, 01 Jan 1970 00:00:01 GMT`;
    }
  }
};

export const deleteAllCookies = () => {
  if (typeof document !== 'undefined') {
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    }
  }
};
