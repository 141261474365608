// @ts-nocheck
import React, { ReactElement, useEffect, useState } from 'react';

import { Switch, Route, withRouter, Link, useLocation } from 'react-router-dom';
import Amplify, { Auth } from 'aws-amplify';

import _ from 'lodash';

import { Affix, Button, Col, Menu, Row, Space } from 'antd';
import { CaretDownOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import qs from 'qs';
import HomeScene from './scenes/HomeScene';
import PricingScene from './scenes/PricingScene';
import AboutScene from './scenes/AboutScene';
import ContactUsScene from './scenes/ContactUsScene';
import HelpFaqScene from './scenes/HelpFaqScene';
import HowToGetScene from './scenes/HowToGetScene';
import SitemapScene from './scenes/SitemapScene';
import NotFoundScene from './scenes/NotFoundScene';
import { deleteAllCookies, removeCookie, setCookie } from './utils/cookies';
import BrowseScene from './scenes/BrowseScene';
import TopNav from './components/TopNav';
import Widget from './scenes/Widget';
import { disciplinesOptions } from './utils/options';
import ClassifiedsHeader from './components/ClassifiedsHeader';
import Footer from './components/Footer';
import store from './config/store';
import { updateDisciplines } from './actions/miscActions';
import getDisciplines from './methods/getDisciplines';

let config = require('./aws-exports-production.js').default;

const isTesting =
  process?.env?.NODE_ENV === 'testing' ||
  process?.env?.REACT_APP_NODE_ENV === 'testing' ||
  (typeof window !== 'undefined' && window.NODE_ENV === 'testing');

const isStaging =
  process?.env?.NODE_ENV === 'staging' ||
  process?.env?.REACT_APP_NODE_ENV === 'staging' ||
  (typeof window !== 'undefined' && window.NODE_ENV === 'staging');

const isDevelop =
  process?.env?.NODE_ENV === 'develop' ||
  process?.env?.REACT_APP_NODE_ENV === 'develop' ||
  (typeof window !== 'undefined' && window.NODE_ENV === 'develop');

if (isStaging) {
  config = require('./aws-exports-staging.js').default;
}
if (isTesting) {
  config = require('./aws-exports-testing.js').default;
}

if (isDevelop) {
  config = require('./aws-exports-develop.js').default;
}

class ScrollToTopComponent extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

const ScrollToTop = withRouter(ScrollToTopComponent);
// src="http://localhost:3001/widget?saleTypeId=80b50ee5-0f84-48e7-92f1-65793a48d597&typeId=5ac0973a-2239-4048-a953-f812bc74d927&isAdminLevel1=false&coords.lat=51.483707&coords.lon=-3.1680962&miles=50&searchLocation=Cardiff&includePOA=true&age.min=1&age.max=30&price.min=2&price.max=50000&height.min=3"

// const IframeTest = () => {
//   return (
//     <div
//       style={{
//         width: '100%',
//         height: '100%',
//         backgroundColor: '#333',
//         display: 'flex',
//         flex: 1,
//         alignItems: 'center',
//         justifyContent: 'center',
//       }}
//     >
//       <iframe
//         id="horses-for-sale-iframe"
//         src="http://localhost:3001/widget/4353453453/0"
//         width={1300}
//       />
//     </div>
//   );
// };

const App: React.FC = (props): ReactElement => {
  class ServerCookieStorage {
    constructor(requestCookie) {
      this.cookieObj = requestCookie;
    }

    setItem(key, value) {
      setCookie(key, value, 10);
      this.cookieObj[key] = value;
    }

    getItem(key) {
      return this.cookieObj[key];
    }

    removeItem(key) {
      removeCookie(key);

      this.cookieObj[key] = '';
    }

    clear() {
      deleteAllCookies();
      this.cookieObj = {};
    }
  }

  let cookiesToUse = props.req?.cookies;
  if (!props.req) {
    cookiesToUse = document.cookie.split('; ').reduce((prev, current) => {
      const [name, ...value] = current.split('=');
      prev[name] = value.join('=');
      return prev;
    }, {});
  }
  const customStorage = new ServerCookieStorage(cookiesToUse);
  customStorage.workaround = null;

  Amplify.configure({
    ...config,
    Auth: {
      storage: customStorage,
    },
  });

  Auth.configure({
    storage: customStorage,
  });

  const location = useLocation();

  let selectedPage = location.pathname;

  if (selectedPage === '/horses') {
    const searchParamsInUrlSnapshot = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    if (
      searchParamsInUrlSnapshot.disciplines &&
      typeof searchParamsInUrlSnapshot.disciplines === 'string'
    ) {
      selectedPage = `/horses-${searchParamsInUrlSnapshot.disciplines}`;
    }
  }

  useEffect(() => {
    if (
      !store.store.getState().misc.disciplines ||
      store.store.getState().misc.disciplines.length === 0
    ) {
      const getDisciplinesAction = async () => {
        const disciplines = await getDisciplines(Amplify);

        store.store.dispatch(
          updateDisciplines(disciplines?.filter((o) => o.showOn?.includes('web')))
        );
      };
      getDisciplinesAction();
    }
  }, []);

  return (
    <Switch>
      <Route path="/widget/:id/:widgetTypeId" component={Widget} />
      {/* <Route path="/iframe" component={IframeTest} /> */}

      <Route path="/">
        <ScrollToTop>
          <div
            className="App"
            style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}
          >
            <ClassifiedsHeader />

            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
              <Switch>
                <Route path="/horses-and-ponies-for-sale" component={BrowseScene} />

                <Route path="/advert-prices" component={PricingScene} />
                <Route path="/about" component={AboutScene} />
                <Route path="/contact-us" component={ContactUsScene} />
                <Route path="/help-faq" component={HelpFaqScene} />
                <Route
                  path="/how-to-get-the-most-out-of-the-horse-hound-classified-site"
                  component={HowToGetScene}
                />
                <Route path="/sitemap" component={SitemapScene} />

                <Route path="/listings" component={BrowseScene} />

                {[
                  '/listings',
                  '/horses',
                  '/horses-for-sale',
                  '/horses-for-loan-share',
                  '/horses-and-ponies-for-sale',
                  '/horseboxes-trailers',
                  '/horseboxes-and-trailers',
                  '/equestrian-property',
                  '/equestrian-jobs',
                  '/jobs',
                  '/properties',
                  '/livery-yards',
                  '/dogs',
                  '/cats',
                  '/stallions-at-stud',
                  '/miscellaneous',
                ].map((item) => (
                  <Route exact path={`${item}`} component={BrowseScene} />
                ))}

                <Route exact path="/" component={HomeScene} />
                <Route path="*" component={NotFoundScene} status={404} />
              </Switch>
            </div>
            <Footer />
          </div>
        </ScrollToTop>
      </Route>
    </Switch>
  );
};

export default App;
