/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const nearbyAdverts = /* GraphQL */ `
  query NearbyAdverts(
    $filter: AdvertSearchInput
    $from: String
    $sort: NearbyAdvertsSortInput
    $limit: Int
    $status: Int
    $soldAtCutOffDate: AWSDateTime
    $userCoords: CoordInput
  ) {
    nearbyAdverts(
      filter: $filter
      from: $from
      sort: $sort
      limit: $limit
      status: $status
      soldAtCutOffDate: $soldAtCutOffDate
      userCoords: $userCoords
    ) {
      items {
        id
        age
        breed
        breedId
        colourId
        coords {
          lat
          lon
          __typename
        }
        currency
        description
        disciplines
        exactCoords {
          lat
          lon
          __typename
        }
        formattedLocation
        genderId
        headline
        height
        isFree
        isPOA
        location
        media {
          id
          fileName
          width
          height
          type
          duration
          thumbnail
          status
          blurHash
          __typename
        }
        youTubeVideos {
          id
          title
          description
          __typename
        }
        hasMedia
        price
        saleTypeId
        propertySaleTypeId
        trailerSaleTypeId
        status
        shouldSync
        syncedAt
        typeId
        categoryId
        createdAt
        updatedAt
        deletedAt
        publishedAt
        expiresAt
        expiredAt
        owner
        userSuspended
        soldAt
        contactBy
        mongoId
        slug
        adminLevel1
        ownerStatus
        horseAndHoundMagazine
        weight
        sleeps
        payload
        condition
        numberOfHorses
        modelName
        manufacturer
        propertyTypeId
        numberOfBedrooms
        numberOfBathrooms
        numberOfReceptionRooms
        numberOfStables
        facilities
        living
        affiliatedCompetitionRecord
        annex
        numberOfPaddocks
        salary
        accommodation
        petsAllowed
        horsesAllowed
        ftpt
        stablesTypeId
        priceTypeId
        partner
        hhAdvertId
        magazineHeadline
        magazineDescription
        dam
        damGrandDam
        damGrandDamGreatGrandDam
        damGrandDamGreatGrandSire
        damGrandSire
        damGrandSireGreatGrandDam
        damGrandSireGreatGrandSire
        sire
        sireGrandDam
        sireGrandDamGreatGrandDam
        sireGrandDamGreatGrandSire
        sireGrandSire
        sireGrandSireGreatGrandDam
        sireGrandSireGreatGrandSire
        stallionsName
        studFeeIncludesVat
        studBookOfOrigin
        graded
        gradedWith
        availability
        concessions
        studPrice {
          __typename
        }
        trailerTypeId
        jobTypeId
        liveryTypeId
        stallionName
        sireDamsire
        packageId
        phone
        email
        whatsApp
        ifSoldReminderSent
        expiringSoonReminderSent
        needHelpReminderSent
        expiredButNotSoldReminderSent
        lastChanceToRenewReminderSent
        expiringNextDayReminderSent
        expiredReminderSent
        skippedExpiredReminder
        skippedExpiresSoonReminder
        websiteLink
        extraImagesLimit
        extraVideosLimit
        extraYouTubeVideosLimit
        purchased {
          extraImages
          extraVideos
          extraYouTubeVideos
          websiteLink
          whatsApp
          __typename
        }
        featuredAt
        includeSocialMediaPost
        includeWebsiteLink
        includeBoostOnce
        includeBoostMulti
        includeFeatured
        seller {
          id
          owner
          bio
          firstName
          lastName
          isBusiness
          email
          phone
          location
          formattedLocation
          isAdminLevel1
          adminLevel1
          businessName
          img
          imgStatus
          acceptedTerms
          horseAndHoundMarketing
          acceptedPrivacyPolicy
          allowNewsletter
          allowBlog
          createdAt
          updatedAt
          syncedAt
          mongoId
          resetPasswordToken
          emailVerificationToken
          newEmail
          stripeCustomerId
          isBilledOffline
          hasLiveAdverts
          wantedAdvertsNotificationsEnabled
          inAppMessagingNotificationsEnabled
          __typename
        }
        colour {
          id
          label
          updatedAt
          createdAt
          mongoId
          showOn
          slug
          __typename
        }
        gender {
          id
          label
          updatedAt
          mongoId
          showOn
          slug
          createdAt
          __typename
        }
        category {
          id
          label
          updatedAt
          createdAt
          __typename
        }
        type {
          id
          label
          updatedAt
          mongoId
          slug
          createdAt
          __typename
        }
        saleType {
          id
          label
          updatedAt
          mongoId
          slug
          createdAt
          __typename
        }
        addons {
          nextToken
          __typename
        }
        mediaProcessing {
          nextToken
          __typename
        }
        boosts {
          nextToken
          __typename
        }
        featured {
          nextToken
          __typename
        }
        socialPromotions {
          nextToken
          __typename
        }
        __typename
      }
      from
      total
      __typename
    }
  }
`;
export const wantedAdverts = /* GraphQL */ `
  query WantedAdverts(
    $filter: WantedAdvertSearchInput
    $from: String
    $sort: SortInput
    $limit: Int
  ) {
    wantedAdverts(filter: $filter, from: $from, sort: $sort, limit: $limit) {
      items {
        id
        headline
        description
        owner
        distance
        location
        formattedLocation
        isAdminLevel1
        adminLevel1
        miles
        ageMin
        ageMax
        heightMin
        heightMax
        priceMin
        priceMax
        saleTypeId
        propertySaleTypeId
        trailerSaleTypeId
        status
        typeId
        categoryId
        coords {
          lat
          lon
          __typename
        }
        exactCoords {
          lat
          lon
          __typename
        }
        isPOA
        disciplines
        createdAt
        updatedAt
        deletedAt
        syncedAt
        ownerProfile {
          id
          owner
          bio
          firstName
          lastName
          isBusiness
          email
          phone
          location
          formattedLocation
          isAdminLevel1
          adminLevel1
          businessName
          img
          imgStatus
          acceptedTerms
          horseAndHoundMarketing
          acceptedPrivacyPolicy
          allowNewsletter
          allowBlog
          createdAt
          updatedAt
          syncedAt
          mongoId
          resetPasswordToken
          emailVerificationToken
          newEmail
          stripeCustomerId
          isBilledOffline
          hasLiveAdverts
          wantedAdvertsNotificationsEnabled
          inAppMessagingNotificationsEnabled
          __typename
        }
        category {
          id
          label
          updatedAt
          createdAt
          __typename
        }
        type {
          id
          label
          updatedAt
          mongoId
          slug
          createdAt
          __typename
        }
        saleType {
          id
          label
          updatedAt
          mongoId
          slug
          createdAt
          __typename
        }
        __typename
      }
      from
      total
      __typename
    }
  }
`;
export const customSearchAdverts = /* GraphQL */ `
  query CustomSearchAdverts(
    $filter: SearchableAdvertFilterInput
    $sort: SearchableAdvertSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    customSearchAdverts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        age
        breed
        breedId
        colourId
        coords {
          lat
          lon
          __typename
        }
        currency
        description
        disciplines
        exactCoords {
          lat
          lon
          __typename
        }
        formattedLocation
        genderId
        headline
        height
        isFree
        isPOA
        location
        media {
          id
          fileName
          width
          height
          type
          duration
          thumbnail
          status
          blurHash
          __typename
        }
        youTubeVideos {
          id
          title
          description
          __typename
        }
        hasMedia
        price
        saleTypeId
        propertySaleTypeId
        trailerSaleTypeId
        status
        shouldSync
        syncedAt
        typeId
        categoryId
        createdAt
        updatedAt
        deletedAt
        publishedAt
        expiresAt
        expiredAt
        owner
        userSuspended
        soldAt
        contactBy
        mongoId
        slug
        adminLevel1
        ownerStatus
        horseAndHoundMagazine
        weight
        sleeps
        payload
        condition
        numberOfHorses
        modelName
        manufacturer
        propertyTypeId
        numberOfBedrooms
        numberOfBathrooms
        numberOfReceptionRooms
        numberOfStables
        facilities
        living
        affiliatedCompetitionRecord
        annex
        numberOfPaddocks
        salary
        accommodation
        petsAllowed
        horsesAllowed
        ftpt
        stablesTypeId
        priceTypeId
        partner
        hhAdvertId
        magazineHeadline
        magazineDescription
        dam
        damGrandDam
        damGrandDamGreatGrandDam
        damGrandDamGreatGrandSire
        damGrandSire
        damGrandSireGreatGrandDam
        damGrandSireGreatGrandSire
        sire
        sireGrandDam
        sireGrandDamGreatGrandDam
        sireGrandDamGreatGrandSire
        sireGrandSire
        sireGrandSireGreatGrandDam
        sireGrandSireGreatGrandSire
        stallionsName
        studFeeIncludesVat
        studBookOfOrigin
        graded
        gradedWith
        availability
        concessions
        studPrice {
          __typename
        }
        trailerTypeId
        jobTypeId
        liveryTypeId
        stallionName
        sireDamsire
        packageId
        phone
        email
        whatsApp
        ifSoldReminderSent
        expiringSoonReminderSent
        needHelpReminderSent
        expiredButNotSoldReminderSent
        lastChanceToRenewReminderSent
        expiringNextDayReminderSent
        expiredReminderSent
        skippedExpiredReminder
        skippedExpiresSoonReminder
        websiteLink
        extraImagesLimit
        extraVideosLimit
        extraYouTubeVideosLimit
        purchased {
          extraImages
          extraVideos
          extraYouTubeVideos
          websiteLink
          whatsApp
          __typename
        }
        featuredAt
        includeSocialMediaPost
        includeWebsiteLink
        includeBoostOnce
        includeBoostMulti
        includeFeatured
        seller {
          id
          owner
          bio
          firstName
          lastName
          isBusiness
          email
          phone
          location
          formattedLocation
          isAdminLevel1
          adminLevel1
          businessName
          img
          imgStatus
          acceptedTerms
          horseAndHoundMarketing
          acceptedPrivacyPolicy
          allowNewsletter
          allowBlog
          createdAt
          updatedAt
          syncedAt
          mongoId
          resetPasswordToken
          emailVerificationToken
          newEmail
          stripeCustomerId
          isBilledOffline
          hasLiveAdverts
          wantedAdvertsNotificationsEnabled
          inAppMessagingNotificationsEnabled
          __typename
        }
        colour {
          id
          label
          updatedAt
          createdAt
          mongoId
          showOn
          slug
          __typename
        }
        gender {
          id
          label
          updatedAt
          mongoId
          showOn
          slug
          createdAt
          __typename
        }
        category {
          id
          label
          updatedAt
          createdAt
          __typename
        }
        type {
          id
          label
          updatedAt
          mongoId
          slug
          createdAt
          __typename
        }
        saleType {
          id
          label
          updatedAt
          mongoId
          slug
          createdAt
          __typename
        }
        addons {
          nextToken
          __typename
        }
        mediaProcessing {
          nextToken
          __typename
        }
        boosts {
          nextToken
          __typename
        }
        featured {
          nextToken
          __typename
        }
        socialPromotions {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      total
      __typename
    }
  }
`;
export const customSearchProfiles = /* GraphQL */ `
  query CustomSearchProfiles(
    $filter: SearchableProfileFilterInput
    $sort: SearchableProfileSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    customSearchProfiles(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        owner
        bio
        firstName
        lastName
        isBusiness
        coords {
          lat
          lon
          __typename
        }
        email
        phone
        location
        formattedLocation
        isAdminLevel1
        adminLevel1
        businessName
        exactCoords {
          lat
          lon
          __typename
        }
        img
        imgStatus
        acceptedTerms
        horseAndHoundMarketing
        acceptedPrivacyPolicy
        allowNewsletter
        allowBlog
        createdAt
        updatedAt
        syncedAt
        mongoId
        resetPasswordToken
        emailVerificationToken
        newEmail
        stripeCustomerId
        isBilledOffline
        hasLiveAdverts
        wantedAdvertsNotificationsEnabled
        inAppMessagingNotificationsEnabled
        __typename
      }
      nextToken
      total
      __typename
    }
  }
`;
export const classifiedsSearchAdverts = /* GraphQL */ `
  query ClassifiedsSearchAdverts(
    $filter: AdvertSearchInput
    $from: String
    $sort: NearbyAdvertsSortInput
    $limit: Int
  ) {
    classifiedsSearchAdverts(
      filter: $filter
      from: $from
      sort: $sort
      limit: $limit
    ) {
      items {
        id
        age
        breed
        breedId
        colourId
        coords {
          lat
          lon
          __typename
        }
        currency
        description
        disciplines
        exactCoords {
          lat
          lon
          __typename
        }
        formattedLocation
        genderId
        headline
        height
        isFree
        isPOA
        location
        media {
          id
          fileName
          width
          height
          type
          duration
          thumbnail
          status
          blurHash
          __typename
        }
        youTubeVideos {
          id
          title
          description
          __typename
        }
        hasMedia
        price
        saleTypeId
        propertySaleTypeId
        trailerSaleTypeId
        status
        shouldSync
        syncedAt
        typeId
        categoryId
        createdAt
        updatedAt
        deletedAt
        publishedAt
        expiresAt
        expiredAt
        owner
        userSuspended
        soldAt
        contactBy
        mongoId
        slug
        adminLevel1
        ownerStatus
        horseAndHoundMagazine
        weight
        sleeps
        payload
        condition
        numberOfHorses
        modelName
        manufacturer
        propertyTypeId
        numberOfBedrooms
        numberOfBathrooms
        numberOfReceptionRooms
        numberOfStables
        facilities
        living
        affiliatedCompetitionRecord
        annex
        numberOfPaddocks
        salary
        accommodation
        petsAllowed
        horsesAllowed
        ftpt
        stablesTypeId
        priceTypeId
        partner
        hhAdvertId
        magazineHeadline
        magazineDescription
        dam
        damGrandDam
        damGrandDamGreatGrandDam
        damGrandDamGreatGrandSire
        damGrandSire
        damGrandSireGreatGrandDam
        damGrandSireGreatGrandSire
        sire
        sireGrandDam
        sireGrandDamGreatGrandDam
        sireGrandDamGreatGrandSire
        sireGrandSire
        sireGrandSireGreatGrandDam
        sireGrandSireGreatGrandSire
        stallionsName
        studFeeIncludesVat
        studBookOfOrigin
        graded
        gradedWith
        availability
        concessions
        studPrice {
          __typename
        }
        trailerTypeId
        jobTypeId
        liveryTypeId
        stallionName
        sireDamsire
        packageId
        phone
        email
        whatsApp
        ifSoldReminderSent
        expiringSoonReminderSent
        needHelpReminderSent
        expiredButNotSoldReminderSent
        lastChanceToRenewReminderSent
        expiringNextDayReminderSent
        expiredReminderSent
        skippedExpiredReminder
        skippedExpiresSoonReminder
        websiteLink
        extraImagesLimit
        extraVideosLimit
        extraYouTubeVideosLimit
        purchased {
          extraImages
          extraVideos
          extraYouTubeVideos
          websiteLink
          whatsApp
          __typename
        }
        featuredAt
        includeSocialMediaPost
        includeWebsiteLink
        includeBoostOnce
        includeBoostMulti
        includeFeatured
        seller {
          id
          owner
          bio
          firstName
          lastName
          isBusiness
          email
          phone
          location
          formattedLocation
          isAdminLevel1
          adminLevel1
          businessName
          img
          imgStatus
          acceptedTerms
          horseAndHoundMarketing
          acceptedPrivacyPolicy
          allowNewsletter
          allowBlog
          createdAt
          updatedAt
          syncedAt
          mongoId
          resetPasswordToken
          emailVerificationToken
          newEmail
          stripeCustomerId
          isBilledOffline
          hasLiveAdverts
          wantedAdvertsNotificationsEnabled
          inAppMessagingNotificationsEnabled
          __typename
        }
        colour {
          id
          label
          updatedAt
          createdAt
          mongoId
          showOn
          slug
          __typename
        }
        gender {
          id
          label
          updatedAt
          mongoId
          showOn
          slug
          createdAt
          __typename
        }
        category {
          id
          label
          updatedAt
          createdAt
          __typename
        }
        type {
          id
          label
          updatedAt
          mongoId
          slug
          createdAt
          __typename
        }
        saleType {
          id
          label
          updatedAt
          mongoId
          slug
          createdAt
          __typename
        }
        addons {
          nextToken
          __typename
        }
        mediaProcessing {
          nextToken
          __typename
        }
        boosts {
          nextToken
          __typename
        }
        featured {
          nextToken
          __typename
        }
        socialPromotions {
          nextToken
          __typename
        }
        __typename
      }
      from
      total
      __typename
    }
  }
`;
export const advertsByOwner = /* GraphQL */ `
  query AdvertsByOwner(
    $owner: ID!
    $soldAtCutOffDate: AWSDateTime
    $expiredAtCutOffDate: AWSDateTime
    $from: String
    $sort: NearbyAdvertsSortInput
    $limit: Int
  ) {
    advertsByOwner(
      owner: $owner
      soldAtCutOffDate: $soldAtCutOffDate
      expiredAtCutOffDate: $expiredAtCutOffDate
      from: $from
      sort: $sort
      limit: $limit
    ) {
      items {
        id
        age
        breed
        breedId
        colourId
        coords {
          lat
          lon
          __typename
        }
        currency
        description
        disciplines
        exactCoords {
          lat
          lon
          __typename
        }
        formattedLocation
        genderId
        headline
        height
        isFree
        isPOA
        location
        media {
          id
          fileName
          width
          height
          type
          duration
          thumbnail
          status
          blurHash
          __typename
        }
        youTubeVideos {
          id
          title
          description
          __typename
        }
        hasMedia
        price
        saleTypeId
        propertySaleTypeId
        trailerSaleTypeId
        status
        shouldSync
        syncedAt
        typeId
        categoryId
        createdAt
        updatedAt
        deletedAt
        publishedAt
        expiresAt
        expiredAt
        owner
        userSuspended
        soldAt
        contactBy
        mongoId
        slug
        adminLevel1
        ownerStatus
        horseAndHoundMagazine
        weight
        sleeps
        payload
        condition
        numberOfHorses
        modelName
        manufacturer
        propertyTypeId
        numberOfBedrooms
        numberOfBathrooms
        numberOfReceptionRooms
        numberOfStables
        facilities
        living
        affiliatedCompetitionRecord
        annex
        numberOfPaddocks
        salary
        accommodation
        petsAllowed
        horsesAllowed
        ftpt
        stablesTypeId
        priceTypeId
        partner
        hhAdvertId
        magazineHeadline
        magazineDescription
        dam
        damGrandDam
        damGrandDamGreatGrandDam
        damGrandDamGreatGrandSire
        damGrandSire
        damGrandSireGreatGrandDam
        damGrandSireGreatGrandSire
        sire
        sireGrandDam
        sireGrandDamGreatGrandDam
        sireGrandDamGreatGrandSire
        sireGrandSire
        sireGrandSireGreatGrandDam
        sireGrandSireGreatGrandSire
        stallionsName
        studFeeIncludesVat
        studBookOfOrigin
        graded
        gradedWith
        availability
        concessions
        studPrice {
          __typename
        }
        trailerTypeId
        jobTypeId
        liveryTypeId
        stallionName
        sireDamsire
        packageId
        phone
        email
        whatsApp
        ifSoldReminderSent
        expiringSoonReminderSent
        needHelpReminderSent
        expiredButNotSoldReminderSent
        lastChanceToRenewReminderSent
        expiringNextDayReminderSent
        expiredReminderSent
        skippedExpiredReminder
        skippedExpiresSoonReminder
        websiteLink
        extraImagesLimit
        extraVideosLimit
        extraYouTubeVideosLimit
        purchased {
          extraImages
          extraVideos
          extraYouTubeVideos
          websiteLink
          whatsApp
          __typename
        }
        featuredAt
        includeSocialMediaPost
        includeWebsiteLink
        includeBoostOnce
        includeBoostMulti
        includeFeatured
        seller {
          id
          owner
          bio
          firstName
          lastName
          isBusiness
          email
          phone
          location
          formattedLocation
          isAdminLevel1
          adminLevel1
          businessName
          img
          imgStatus
          acceptedTerms
          horseAndHoundMarketing
          acceptedPrivacyPolicy
          allowNewsletter
          allowBlog
          createdAt
          updatedAt
          syncedAt
          mongoId
          resetPasswordToken
          emailVerificationToken
          newEmail
          stripeCustomerId
          isBilledOffline
          hasLiveAdverts
          wantedAdvertsNotificationsEnabled
          inAppMessagingNotificationsEnabled
          __typename
        }
        colour {
          id
          label
          updatedAt
          createdAt
          mongoId
          showOn
          slug
          __typename
        }
        gender {
          id
          label
          updatedAt
          mongoId
          showOn
          slug
          createdAt
          __typename
        }
        category {
          id
          label
          updatedAt
          createdAt
          __typename
        }
        type {
          id
          label
          updatedAt
          mongoId
          slug
          createdAt
          __typename
        }
        saleType {
          id
          label
          updatedAt
          mongoId
          slug
          createdAt
          __typename
        }
        addons {
          nextToken
          __typename
        }
        mediaProcessing {
          nextToken
          __typename
        }
        boosts {
          nextToken
          __typename
        }
        featured {
          nextToken
          __typename
        }
        socialPromotions {
          nextToken
          __typename
        }
        __typename
      }
      from
      total
      __typename
    }
  }
`;
export const myAdverts = /* GraphQL */ `
  query MyAdverts(
    $filter: AdvertSearchInput
    $from: String
    $sort: NearbyAdvertsSortInput
    $limit: Int
  ) {
    myAdverts(filter: $filter, from: $from, sort: $sort, limit: $limit) {
      items {
        id
        age
        breed
        breedId
        colourId
        coords {
          lat
          lon
          __typename
        }
        currency
        description
        disciplines
        exactCoords {
          lat
          lon
          __typename
        }
        formattedLocation
        genderId
        headline
        height
        isFree
        isPOA
        location
        media {
          id
          fileName
          width
          height
          type
          duration
          thumbnail
          status
          blurHash
          __typename
        }
        youTubeVideos {
          id
          title
          description
          __typename
        }
        hasMedia
        price
        saleTypeId
        propertySaleTypeId
        trailerSaleTypeId
        status
        shouldSync
        syncedAt
        typeId
        categoryId
        createdAt
        updatedAt
        deletedAt
        publishedAt
        expiresAt
        expiredAt
        owner
        userSuspended
        soldAt
        contactBy
        mongoId
        slug
        adminLevel1
        ownerStatus
        horseAndHoundMagazine
        weight
        sleeps
        payload
        condition
        numberOfHorses
        modelName
        manufacturer
        propertyTypeId
        numberOfBedrooms
        numberOfBathrooms
        numberOfReceptionRooms
        numberOfStables
        facilities
        living
        affiliatedCompetitionRecord
        annex
        numberOfPaddocks
        salary
        accommodation
        petsAllowed
        horsesAllowed
        ftpt
        stablesTypeId
        priceTypeId
        partner
        hhAdvertId
        magazineHeadline
        magazineDescription
        dam
        damGrandDam
        damGrandDamGreatGrandDam
        damGrandDamGreatGrandSire
        damGrandSire
        damGrandSireGreatGrandDam
        damGrandSireGreatGrandSire
        sire
        sireGrandDam
        sireGrandDamGreatGrandDam
        sireGrandDamGreatGrandSire
        sireGrandSire
        sireGrandSireGreatGrandDam
        sireGrandSireGreatGrandSire
        stallionsName
        studFeeIncludesVat
        studBookOfOrigin
        graded
        gradedWith
        availability
        concessions
        studPrice {
          __typename
        }
        trailerTypeId
        jobTypeId
        liveryTypeId
        stallionName
        sireDamsire
        packageId
        phone
        email
        whatsApp
        ifSoldReminderSent
        expiringSoonReminderSent
        needHelpReminderSent
        expiredButNotSoldReminderSent
        lastChanceToRenewReminderSent
        expiringNextDayReminderSent
        expiredReminderSent
        skippedExpiredReminder
        skippedExpiresSoonReminder
        websiteLink
        extraImagesLimit
        extraVideosLimit
        extraYouTubeVideosLimit
        purchased {
          extraImages
          extraVideos
          extraYouTubeVideos
          websiteLink
          whatsApp
          __typename
        }
        featuredAt
        includeSocialMediaPost
        includeWebsiteLink
        includeBoostOnce
        includeBoostMulti
        includeFeatured
        seller {
          id
          owner
          bio
          firstName
          lastName
          isBusiness
          email
          phone
          location
          formattedLocation
          isAdminLevel1
          adminLevel1
          businessName
          img
          imgStatus
          acceptedTerms
          horseAndHoundMarketing
          acceptedPrivacyPolicy
          allowNewsletter
          allowBlog
          createdAt
          updatedAt
          syncedAt
          mongoId
          resetPasswordToken
          emailVerificationToken
          newEmail
          stripeCustomerId
          isBilledOffline
          hasLiveAdverts
          wantedAdvertsNotificationsEnabled
          inAppMessagingNotificationsEnabled
          __typename
        }
        colour {
          id
          label
          updatedAt
          createdAt
          mongoId
          showOn
          slug
          __typename
        }
        gender {
          id
          label
          updatedAt
          mongoId
          showOn
          slug
          createdAt
          __typename
        }
        category {
          id
          label
          updatedAt
          createdAt
          __typename
        }
        type {
          id
          label
          updatedAt
          mongoId
          slug
          createdAt
          __typename
        }
        saleType {
          id
          label
          updatedAt
          mongoId
          slug
          createdAt
          __typename
        }
        addons {
          nextToken
          __typename
        }
        mediaProcessing {
          nextToken
          __typename
        }
        boosts {
          nextToken
          __typename
        }
        featured {
          nextToken
          __typename
        }
        socialPromotions {
          nextToken
          __typename
        }
        __typename
      }
      from
      total
      __typename
    }
  }
`;
export const searchFavouritesForOwner = /* GraphQL */ `
  query SearchFavouritesForOwner(
    $owner: ID!
    $from: String
    $limit: Int
    $advertId: ID
  ) {
    searchFavouritesForOwner(
      owner: $owner
      from: $from
      limit: $limit
      advertId: $advertId
    ) {
      items {
        id
        advertId
        owner
        syncedAt
        updatedAt
        deletedAt
        createdAt
        advert {
          id
          age
          breed
          breedId
          colourId
          currency
          description
          disciplines
          formattedLocation
          genderId
          headline
          height
          isFree
          isPOA
          location
          hasMedia
          price
          saleTypeId
          propertySaleTypeId
          trailerSaleTypeId
          status
          shouldSync
          syncedAt
          typeId
          categoryId
          createdAt
          updatedAt
          deletedAt
          publishedAt
          expiresAt
          expiredAt
          owner
          userSuspended
          soldAt
          contactBy
          mongoId
          slug
          adminLevel1
          ownerStatus
          horseAndHoundMagazine
          weight
          sleeps
          payload
          condition
          numberOfHorses
          modelName
          manufacturer
          propertyTypeId
          numberOfBedrooms
          numberOfBathrooms
          numberOfReceptionRooms
          numberOfStables
          facilities
          living
          affiliatedCompetitionRecord
          annex
          numberOfPaddocks
          salary
          accommodation
          petsAllowed
          horsesAllowed
          ftpt
          stablesTypeId
          priceTypeId
          partner
          hhAdvertId
          magazineHeadline
          magazineDescription
          dam
          damGrandDam
          damGrandDamGreatGrandDam
          damGrandDamGreatGrandSire
          damGrandSire
          damGrandSireGreatGrandDam
          damGrandSireGreatGrandSire
          sire
          sireGrandDam
          sireGrandDamGreatGrandDam
          sireGrandDamGreatGrandSire
          sireGrandSire
          sireGrandSireGreatGrandDam
          sireGrandSireGreatGrandSire
          stallionsName
          studFeeIncludesVat
          studBookOfOrigin
          graded
          gradedWith
          availability
          concessions
          trailerTypeId
          jobTypeId
          liveryTypeId
          stallionName
          sireDamsire
          packageId
          phone
          email
          whatsApp
          ifSoldReminderSent
          expiringSoonReminderSent
          needHelpReminderSent
          expiredButNotSoldReminderSent
          lastChanceToRenewReminderSent
          expiringNextDayReminderSent
          expiredReminderSent
          skippedExpiredReminder
          skippedExpiresSoonReminder
          websiteLink
          extraImagesLimit
          extraVideosLimit
          extraYouTubeVideosLimit
          featuredAt
          includeSocialMediaPost
          includeWebsiteLink
          includeBoostOnce
          includeBoostMulti
          includeFeatured
          __typename
        }
        __typename
      }
      from
      total
      __typename
    }
  }
`;
export const getFavourite = /* GraphQL */ `
  query GetFavourite($id: ID!) {
    getFavourite(id: $id) {
      id
      advertId
      owner
      syncedAt
      updatedAt
      deletedAt
      createdAt
      advert {
        id
        age
        breed
        breedId
        colourId
        coords {
          lat
          lon
          __typename
        }
        currency
        description
        disciplines
        exactCoords {
          lat
          lon
          __typename
        }
        formattedLocation
        genderId
        headline
        height
        isFree
        isPOA
        location
        media {
          id
          fileName
          width
          height
          type
          duration
          thumbnail
          status
          blurHash
          __typename
        }
        youTubeVideos {
          id
          title
          description
          __typename
        }
        hasMedia
        price
        saleTypeId
        propertySaleTypeId
        trailerSaleTypeId
        status
        shouldSync
        syncedAt
        typeId
        categoryId
        createdAt
        updatedAt
        deletedAt
        publishedAt
        expiresAt
        expiredAt
        owner
        userSuspended
        soldAt
        contactBy
        mongoId
        slug
        adminLevel1
        ownerStatus
        horseAndHoundMagazine
        weight
        sleeps
        payload
        condition
        numberOfHorses
        modelName
        manufacturer
        propertyTypeId
        numberOfBedrooms
        numberOfBathrooms
        numberOfReceptionRooms
        numberOfStables
        facilities
        living
        affiliatedCompetitionRecord
        annex
        numberOfPaddocks
        salary
        accommodation
        petsAllowed
        horsesAllowed
        ftpt
        stablesTypeId
        priceTypeId
        partner
        hhAdvertId
        magazineHeadline
        magazineDescription
        dam
        damGrandDam
        damGrandDamGreatGrandDam
        damGrandDamGreatGrandSire
        damGrandSire
        damGrandSireGreatGrandDam
        damGrandSireGreatGrandSire
        sire
        sireGrandDam
        sireGrandDamGreatGrandDam
        sireGrandDamGreatGrandSire
        sireGrandSire
        sireGrandSireGreatGrandDam
        sireGrandSireGreatGrandSire
        stallionsName
        studFeeIncludesVat
        studBookOfOrigin
        graded
        gradedWith
        availability
        concessions
        studPrice {
          __typename
        }
        trailerTypeId
        jobTypeId
        liveryTypeId
        stallionName
        sireDamsire
        packageId
        phone
        email
        whatsApp
        ifSoldReminderSent
        expiringSoonReminderSent
        needHelpReminderSent
        expiredButNotSoldReminderSent
        lastChanceToRenewReminderSent
        expiringNextDayReminderSent
        expiredReminderSent
        skippedExpiredReminder
        skippedExpiresSoonReminder
        websiteLink
        extraImagesLimit
        extraVideosLimit
        extraYouTubeVideosLimit
        purchased {
          extraImages
          extraVideos
          extraYouTubeVideos
          websiteLink
          whatsApp
          __typename
        }
        featuredAt
        includeSocialMediaPost
        includeWebsiteLink
        includeBoostOnce
        includeBoostMulti
        includeFeatured
        seller {
          id
          owner
          bio
          firstName
          lastName
          isBusiness
          email
          phone
          location
          formattedLocation
          isAdminLevel1
          adminLevel1
          businessName
          img
          imgStatus
          acceptedTerms
          horseAndHoundMarketing
          acceptedPrivacyPolicy
          allowNewsletter
          allowBlog
          createdAt
          updatedAt
          syncedAt
          mongoId
          resetPasswordToken
          emailVerificationToken
          newEmail
          stripeCustomerId
          isBilledOffline
          hasLiveAdverts
          wantedAdvertsNotificationsEnabled
          inAppMessagingNotificationsEnabled
          __typename
        }
        colour {
          id
          label
          updatedAt
          createdAt
          mongoId
          showOn
          slug
          __typename
        }
        gender {
          id
          label
          updatedAt
          mongoId
          showOn
          slug
          createdAt
          __typename
        }
        category {
          id
          label
          updatedAt
          createdAt
          __typename
        }
        type {
          id
          label
          updatedAt
          mongoId
          slug
          createdAt
          __typename
        }
        saleType {
          id
          label
          updatedAt
          mongoId
          slug
          createdAt
          __typename
        }
        addons {
          nextToken
          __typename
        }
        mediaProcessing {
          nextToken
          __typename
        }
        boosts {
          nextToken
          __typename
        }
        featured {
          nextToken
          __typename
        }
        socialPromotions {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const listFavourites = /* GraphQL */ `
  query ListFavourites(
    $filter: ModelFavouriteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFavourites(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        advertId
        owner
        syncedAt
        updatedAt
        deletedAt
        createdAt
        advert {
          id
          age
          breed
          breedId
          colourId
          currency
          description
          disciplines
          formattedLocation
          genderId
          headline
          height
          isFree
          isPOA
          location
          hasMedia
          price
          saleTypeId
          propertySaleTypeId
          trailerSaleTypeId
          status
          shouldSync
          syncedAt
          typeId
          categoryId
          createdAt
          updatedAt
          deletedAt
          publishedAt
          expiresAt
          expiredAt
          owner
          userSuspended
          soldAt
          contactBy
          mongoId
          slug
          adminLevel1
          ownerStatus
          horseAndHoundMagazine
          weight
          sleeps
          payload
          condition
          numberOfHorses
          modelName
          manufacturer
          propertyTypeId
          numberOfBedrooms
          numberOfBathrooms
          numberOfReceptionRooms
          numberOfStables
          facilities
          living
          affiliatedCompetitionRecord
          annex
          numberOfPaddocks
          salary
          accommodation
          petsAllowed
          horsesAllowed
          ftpt
          stablesTypeId
          priceTypeId
          partner
          hhAdvertId
          magazineHeadline
          magazineDescription
          dam
          damGrandDam
          damGrandDamGreatGrandDam
          damGrandDamGreatGrandSire
          damGrandSire
          damGrandSireGreatGrandDam
          damGrandSireGreatGrandSire
          sire
          sireGrandDam
          sireGrandDamGreatGrandDam
          sireGrandDamGreatGrandSire
          sireGrandSire
          sireGrandSireGreatGrandDam
          sireGrandSireGreatGrandSire
          stallionsName
          studFeeIncludesVat
          studBookOfOrigin
          graded
          gradedWith
          availability
          concessions
          trailerTypeId
          jobTypeId
          liveryTypeId
          stallionName
          sireDamsire
          packageId
          phone
          email
          whatsApp
          ifSoldReminderSent
          expiringSoonReminderSent
          needHelpReminderSent
          expiredButNotSoldReminderSent
          lastChanceToRenewReminderSent
          expiringNextDayReminderSent
          expiredReminderSent
          skippedExpiredReminder
          skippedExpiresSoonReminder
          websiteLink
          extraImagesLimit
          extraVideosLimit
          extraYouTubeVideosLimit
          featuredAt
          includeSocialMediaPost
          includeWebsiteLink
          includeBoostOnce
          includeBoostMulti
          includeFeatured
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDeviceToken = /* GraphQL */ `
  query GetDeviceToken($id: ID!) {
    getDeviceToken(id: $id) {
      id
      owner
      platform
      token
      createdAt
      updatedAt
      deletedAt
      __typename
    }
  }
`;
export const listDeviceTokens = /* GraphQL */ `
  query ListDeviceTokens(
    $filter: ModelDeviceTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeviceTokens(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        platform
        token
        createdAt
        updatedAt
        deletedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getConversation = /* GraphQL */ `
  query GetConversation($id: ID!) {
    getConversation(id: $id) {
      id
      mongoId
      sellerId
      customerId
      advertId
      usersDeleted
      createdAt
      updatedAt
      deletedAt
      syncedAt
      messages {
        items {
          id
          message
          createdAt
          updatedAt
          syncedAt
          deletedAt
          owner
          recipientId
          conversationId
          encryptionKey
          read
          __typename
        }
        nextToken
        __typename
      }
      seller {
        id
        owner
        bio
        firstName
        lastName
        isBusiness
        coords {
          lat
          lon
          __typename
        }
        email
        phone
        location
        formattedLocation
        isAdminLevel1
        adminLevel1
        businessName
        exactCoords {
          lat
          lon
          __typename
        }
        img
        imgStatus
        acceptedTerms
        horseAndHoundMarketing
        acceptedPrivacyPolicy
        allowNewsletter
        allowBlog
        createdAt
        updatedAt
        syncedAt
        mongoId
        resetPasswordToken
        emailVerificationToken
        newEmail
        stripeCustomerId
        isBilledOffline
        hasLiveAdverts
        wantedAdvertsNotificationsEnabled
        inAppMessagingNotificationsEnabled
        __typename
      }
      customer {
        id
        owner
        bio
        firstName
        lastName
        isBusiness
        coords {
          lat
          lon
          __typename
        }
        email
        phone
        location
        formattedLocation
        isAdminLevel1
        adminLevel1
        businessName
        exactCoords {
          lat
          lon
          __typename
        }
        img
        imgStatus
        acceptedTerms
        horseAndHoundMarketing
        acceptedPrivacyPolicy
        allowNewsletter
        allowBlog
        createdAt
        updatedAt
        syncedAt
        mongoId
        resetPasswordToken
        emailVerificationToken
        newEmail
        stripeCustomerId
        isBilledOffline
        hasLiveAdverts
        wantedAdvertsNotificationsEnabled
        inAppMessagingNotificationsEnabled
        __typename
      }
      advert {
        id
        age
        breed
        breedId
        colourId
        coords {
          lat
          lon
          __typename
        }
        currency
        description
        disciplines
        exactCoords {
          lat
          lon
          __typename
        }
        formattedLocation
        genderId
        headline
        height
        isFree
        isPOA
        location
        media {
          id
          fileName
          width
          height
          type
          duration
          thumbnail
          status
          blurHash
          __typename
        }
        youTubeVideos {
          id
          title
          description
          __typename
        }
        hasMedia
        price
        saleTypeId
        propertySaleTypeId
        trailerSaleTypeId
        status
        shouldSync
        syncedAt
        typeId
        categoryId
        createdAt
        updatedAt
        deletedAt
        publishedAt
        expiresAt
        expiredAt
        owner
        userSuspended
        soldAt
        contactBy
        mongoId
        slug
        adminLevel1
        ownerStatus
        horseAndHoundMagazine
        weight
        sleeps
        payload
        condition
        numberOfHorses
        modelName
        manufacturer
        propertyTypeId
        numberOfBedrooms
        numberOfBathrooms
        numberOfReceptionRooms
        numberOfStables
        facilities
        living
        affiliatedCompetitionRecord
        annex
        numberOfPaddocks
        salary
        accommodation
        petsAllowed
        horsesAllowed
        ftpt
        stablesTypeId
        priceTypeId
        partner
        hhAdvertId
        magazineHeadline
        magazineDescription
        dam
        damGrandDam
        damGrandDamGreatGrandDam
        damGrandDamGreatGrandSire
        damGrandSire
        damGrandSireGreatGrandDam
        damGrandSireGreatGrandSire
        sire
        sireGrandDam
        sireGrandDamGreatGrandDam
        sireGrandDamGreatGrandSire
        sireGrandSire
        sireGrandSireGreatGrandDam
        sireGrandSireGreatGrandSire
        stallionsName
        studFeeIncludesVat
        studBookOfOrigin
        graded
        gradedWith
        availability
        concessions
        studPrice {
          __typename
        }
        trailerTypeId
        jobTypeId
        liveryTypeId
        stallionName
        sireDamsire
        packageId
        phone
        email
        whatsApp
        ifSoldReminderSent
        expiringSoonReminderSent
        needHelpReminderSent
        expiredButNotSoldReminderSent
        lastChanceToRenewReminderSent
        expiringNextDayReminderSent
        expiredReminderSent
        skippedExpiredReminder
        skippedExpiresSoonReminder
        websiteLink
        extraImagesLimit
        extraVideosLimit
        extraYouTubeVideosLimit
        purchased {
          extraImages
          extraVideos
          extraYouTubeVideos
          websiteLink
          whatsApp
          __typename
        }
        featuredAt
        includeSocialMediaPost
        includeWebsiteLink
        includeBoostOnce
        includeBoostMulti
        includeFeatured
        seller {
          id
          owner
          bio
          firstName
          lastName
          isBusiness
          email
          phone
          location
          formattedLocation
          isAdminLevel1
          adminLevel1
          businessName
          img
          imgStatus
          acceptedTerms
          horseAndHoundMarketing
          acceptedPrivacyPolicy
          allowNewsletter
          allowBlog
          createdAt
          updatedAt
          syncedAt
          mongoId
          resetPasswordToken
          emailVerificationToken
          newEmail
          stripeCustomerId
          isBilledOffline
          hasLiveAdverts
          wantedAdvertsNotificationsEnabled
          inAppMessagingNotificationsEnabled
          __typename
        }
        colour {
          id
          label
          updatedAt
          createdAt
          mongoId
          showOn
          slug
          __typename
        }
        gender {
          id
          label
          updatedAt
          mongoId
          showOn
          slug
          createdAt
          __typename
        }
        category {
          id
          label
          updatedAt
          createdAt
          __typename
        }
        type {
          id
          label
          updatedAt
          mongoId
          slug
          createdAt
          __typename
        }
        saleType {
          id
          label
          updatedAt
          mongoId
          slug
          createdAt
          __typename
        }
        addons {
          nextToken
          __typename
        }
        mediaProcessing {
          nextToken
          __typename
        }
        boosts {
          nextToken
          __typename
        }
        featured {
          nextToken
          __typename
        }
        socialPromotions {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const listConversations = /* GraphQL */ `
  query ListConversations(
    $filter: ModelConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConversations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        mongoId
        sellerId
        customerId
        advertId
        usersDeleted
        createdAt
        updatedAt
        deletedAt
        syncedAt
        messages {
          nextToken
          __typename
        }
        seller {
          id
          owner
          bio
          firstName
          lastName
          isBusiness
          email
          phone
          location
          formattedLocation
          isAdminLevel1
          adminLevel1
          businessName
          img
          imgStatus
          acceptedTerms
          horseAndHoundMarketing
          acceptedPrivacyPolicy
          allowNewsletter
          allowBlog
          createdAt
          updatedAt
          syncedAt
          mongoId
          resetPasswordToken
          emailVerificationToken
          newEmail
          stripeCustomerId
          isBilledOffline
          hasLiveAdverts
          wantedAdvertsNotificationsEnabled
          inAppMessagingNotificationsEnabled
          __typename
        }
        customer {
          id
          owner
          bio
          firstName
          lastName
          isBusiness
          email
          phone
          location
          formattedLocation
          isAdminLevel1
          adminLevel1
          businessName
          img
          imgStatus
          acceptedTerms
          horseAndHoundMarketing
          acceptedPrivacyPolicy
          allowNewsletter
          allowBlog
          createdAt
          updatedAt
          syncedAt
          mongoId
          resetPasswordToken
          emailVerificationToken
          newEmail
          stripeCustomerId
          isBilledOffline
          hasLiveAdverts
          wantedAdvertsNotificationsEnabled
          inAppMessagingNotificationsEnabled
          __typename
        }
        advert {
          id
          age
          breed
          breedId
          colourId
          currency
          description
          disciplines
          formattedLocation
          genderId
          headline
          height
          isFree
          isPOA
          location
          hasMedia
          price
          saleTypeId
          propertySaleTypeId
          trailerSaleTypeId
          status
          shouldSync
          syncedAt
          typeId
          categoryId
          createdAt
          updatedAt
          deletedAt
          publishedAt
          expiresAt
          expiredAt
          owner
          userSuspended
          soldAt
          contactBy
          mongoId
          slug
          adminLevel1
          ownerStatus
          horseAndHoundMagazine
          weight
          sleeps
          payload
          condition
          numberOfHorses
          modelName
          manufacturer
          propertyTypeId
          numberOfBedrooms
          numberOfBathrooms
          numberOfReceptionRooms
          numberOfStables
          facilities
          living
          affiliatedCompetitionRecord
          annex
          numberOfPaddocks
          salary
          accommodation
          petsAllowed
          horsesAllowed
          ftpt
          stablesTypeId
          priceTypeId
          partner
          hhAdvertId
          magazineHeadline
          magazineDescription
          dam
          damGrandDam
          damGrandDamGreatGrandDam
          damGrandDamGreatGrandSire
          damGrandSire
          damGrandSireGreatGrandDam
          damGrandSireGreatGrandSire
          sire
          sireGrandDam
          sireGrandDamGreatGrandDam
          sireGrandDamGreatGrandSire
          sireGrandSire
          sireGrandSireGreatGrandDam
          sireGrandSireGreatGrandSire
          stallionsName
          studFeeIncludesVat
          studBookOfOrigin
          graded
          gradedWith
          availability
          concessions
          trailerTypeId
          jobTypeId
          liveryTypeId
          stallionName
          sireDamsire
          packageId
          phone
          email
          whatsApp
          ifSoldReminderSent
          expiringSoonReminderSent
          needHelpReminderSent
          expiredButNotSoldReminderSent
          lastChanceToRenewReminderSent
          expiringNextDayReminderSent
          expiredReminderSent
          skippedExpiredReminder
          skippedExpiresSoonReminder
          websiteLink
          extraImagesLimit
          extraVideosLimit
          extraYouTubeVideosLimit
          featuredAt
          includeSocialMediaPost
          includeWebsiteLink
          includeBoostOnce
          includeBoostMulti
          includeFeatured
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      message
      createdAt
      updatedAt
      syncedAt
      deletedAt
      owner
      recipientId
      conversationId
      encryptionKey
      read
      conversation {
        id
        mongoId
        sellerId
        customerId
        advertId
        usersDeleted
        createdAt
        updatedAt
        deletedAt
        syncedAt
        messages {
          nextToken
          __typename
        }
        seller {
          id
          owner
          bio
          firstName
          lastName
          isBusiness
          email
          phone
          location
          formattedLocation
          isAdminLevel1
          adminLevel1
          businessName
          img
          imgStatus
          acceptedTerms
          horseAndHoundMarketing
          acceptedPrivacyPolicy
          allowNewsletter
          allowBlog
          createdAt
          updatedAt
          syncedAt
          mongoId
          resetPasswordToken
          emailVerificationToken
          newEmail
          stripeCustomerId
          isBilledOffline
          hasLiveAdverts
          wantedAdvertsNotificationsEnabled
          inAppMessagingNotificationsEnabled
          __typename
        }
        customer {
          id
          owner
          bio
          firstName
          lastName
          isBusiness
          email
          phone
          location
          formattedLocation
          isAdminLevel1
          adminLevel1
          businessName
          img
          imgStatus
          acceptedTerms
          horseAndHoundMarketing
          acceptedPrivacyPolicy
          allowNewsletter
          allowBlog
          createdAt
          updatedAt
          syncedAt
          mongoId
          resetPasswordToken
          emailVerificationToken
          newEmail
          stripeCustomerId
          isBilledOffline
          hasLiveAdverts
          wantedAdvertsNotificationsEnabled
          inAppMessagingNotificationsEnabled
          __typename
        }
        advert {
          id
          age
          breed
          breedId
          colourId
          currency
          description
          disciplines
          formattedLocation
          genderId
          headline
          height
          isFree
          isPOA
          location
          hasMedia
          price
          saleTypeId
          propertySaleTypeId
          trailerSaleTypeId
          status
          shouldSync
          syncedAt
          typeId
          categoryId
          createdAt
          updatedAt
          deletedAt
          publishedAt
          expiresAt
          expiredAt
          owner
          userSuspended
          soldAt
          contactBy
          mongoId
          slug
          adminLevel1
          ownerStatus
          horseAndHoundMagazine
          weight
          sleeps
          payload
          condition
          numberOfHorses
          modelName
          manufacturer
          propertyTypeId
          numberOfBedrooms
          numberOfBathrooms
          numberOfReceptionRooms
          numberOfStables
          facilities
          living
          affiliatedCompetitionRecord
          annex
          numberOfPaddocks
          salary
          accommodation
          petsAllowed
          horsesAllowed
          ftpt
          stablesTypeId
          priceTypeId
          partner
          hhAdvertId
          magazineHeadline
          magazineDescription
          dam
          damGrandDam
          damGrandDamGreatGrandDam
          damGrandDamGreatGrandSire
          damGrandSire
          damGrandSireGreatGrandDam
          damGrandSireGreatGrandSire
          sire
          sireGrandDam
          sireGrandDamGreatGrandDam
          sireGrandDamGreatGrandSire
          sireGrandSire
          sireGrandSireGreatGrandDam
          sireGrandSireGreatGrandSire
          stallionsName
          studFeeIncludesVat
          studBookOfOrigin
          graded
          gradedWith
          availability
          concessions
          trailerTypeId
          jobTypeId
          liveryTypeId
          stallionName
          sireDamsire
          packageId
          phone
          email
          whatsApp
          ifSoldReminderSent
          expiringSoonReminderSent
          needHelpReminderSent
          expiredButNotSoldReminderSent
          lastChanceToRenewReminderSent
          expiringNextDayReminderSent
          expiredReminderSent
          skippedExpiredReminder
          skippedExpiresSoonReminder
          websiteLink
          extraImagesLimit
          extraVideosLimit
          extraYouTubeVideosLimit
          featuredAt
          includeSocialMediaPost
          includeWebsiteLink
          includeBoostOnce
          includeBoostMulti
          includeFeatured
          __typename
        }
        __typename
      }
      user {
        id
        owner
        bio
        firstName
        lastName
        isBusiness
        coords {
          lat
          lon
          __typename
        }
        email
        phone
        location
        formattedLocation
        isAdminLevel1
        adminLevel1
        businessName
        exactCoords {
          lat
          lon
          __typename
        }
        img
        imgStatus
        acceptedTerms
        horseAndHoundMarketing
        acceptedPrivacyPolicy
        allowNewsletter
        allowBlog
        createdAt
        updatedAt
        syncedAt
        mongoId
        resetPasswordToken
        emailVerificationToken
        newEmail
        stripeCustomerId
        isBilledOffline
        hasLiveAdverts
        wantedAdvertsNotificationsEnabled
        inAppMessagingNotificationsEnabled
        __typename
      }
      __typename
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        message
        createdAt
        updatedAt
        syncedAt
        deletedAt
        owner
        recipientId
        conversationId
        encryptionKey
        read
        conversation {
          id
          mongoId
          sellerId
          customerId
          advertId
          usersDeleted
          createdAt
          updatedAt
          deletedAt
          syncedAt
          __typename
        }
        user {
          id
          owner
          bio
          firstName
          lastName
          isBusiness
          email
          phone
          location
          formattedLocation
          isAdminLevel1
          adminLevel1
          businessName
          img
          imgStatus
          acceptedTerms
          horseAndHoundMarketing
          acceptedPrivacyPolicy
          allowNewsletter
          allowBlog
          createdAt
          updatedAt
          syncedAt
          mongoId
          resetPasswordToken
          emailVerificationToken
          newEmail
          stripeCustomerId
          isBilledOffline
          hasLiveAdverts
          wantedAdvertsNotificationsEnabled
          inAppMessagingNotificationsEnabled
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const favouritesForOwner = /* GraphQL */ `
  query FavouritesForOwner(
    $owner: ID
    $syncedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFavouriteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    favouritesForOwner(
      owner: $owner
      syncedAt: $syncedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        advertId
        owner
        syncedAt
        updatedAt
        deletedAt
        createdAt
        advert {
          id
          age
          breed
          breedId
          colourId
          currency
          description
          disciplines
          formattedLocation
          genderId
          headline
          height
          isFree
          isPOA
          location
          hasMedia
          price
          saleTypeId
          propertySaleTypeId
          trailerSaleTypeId
          status
          shouldSync
          syncedAt
          typeId
          categoryId
          createdAt
          updatedAt
          deletedAt
          publishedAt
          expiresAt
          expiredAt
          owner
          userSuspended
          soldAt
          contactBy
          mongoId
          slug
          adminLevel1
          ownerStatus
          horseAndHoundMagazine
          weight
          sleeps
          payload
          condition
          numberOfHorses
          modelName
          manufacturer
          propertyTypeId
          numberOfBedrooms
          numberOfBathrooms
          numberOfReceptionRooms
          numberOfStables
          facilities
          living
          affiliatedCompetitionRecord
          annex
          numberOfPaddocks
          salary
          accommodation
          petsAllowed
          horsesAllowed
          ftpt
          stablesTypeId
          priceTypeId
          partner
          hhAdvertId
          magazineHeadline
          magazineDescription
          dam
          damGrandDam
          damGrandDamGreatGrandDam
          damGrandDamGreatGrandSire
          damGrandSire
          damGrandSireGreatGrandDam
          damGrandSireGreatGrandSire
          sire
          sireGrandDam
          sireGrandDamGreatGrandDam
          sireGrandDamGreatGrandSire
          sireGrandSire
          sireGrandSireGreatGrandDam
          sireGrandSireGreatGrandSire
          stallionsName
          studFeeIncludesVat
          studBookOfOrigin
          graded
          gradedWith
          availability
          concessions
          trailerTypeId
          jobTypeId
          liveryTypeId
          stallionName
          sireDamsire
          packageId
          phone
          email
          whatsApp
          ifSoldReminderSent
          expiringSoonReminderSent
          needHelpReminderSent
          expiredButNotSoldReminderSent
          lastChanceToRenewReminderSent
          expiringNextDayReminderSent
          expiredReminderSent
          skippedExpiredReminder
          skippedExpiresSoonReminder
          websiteLink
          extraImagesLimit
          extraVideosLimit
          extraYouTubeVideosLimit
          featuredAt
          includeSocialMediaPost
          includeWebsiteLink
          includeBoostOnce
          includeBoostMulti
          includeFeatured
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const conversationsForSeller = /* GraphQL */ `
  query ConversationsForSeller(
    $sellerId: ID
    $syncedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    conversationsForSeller(
      sellerId: $sellerId
      syncedAt: $syncedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mongoId
        sellerId
        customerId
        advertId
        usersDeleted
        createdAt
        updatedAt
        deletedAt
        syncedAt
        messages {
          nextToken
          __typename
        }
        seller {
          id
          owner
          bio
          firstName
          lastName
          isBusiness
          email
          phone
          location
          formattedLocation
          isAdminLevel1
          adminLevel1
          businessName
          img
          imgStatus
          acceptedTerms
          horseAndHoundMarketing
          acceptedPrivacyPolicy
          allowNewsletter
          allowBlog
          createdAt
          updatedAt
          syncedAt
          mongoId
          resetPasswordToken
          emailVerificationToken
          newEmail
          stripeCustomerId
          isBilledOffline
          hasLiveAdverts
          wantedAdvertsNotificationsEnabled
          inAppMessagingNotificationsEnabled
          __typename
        }
        customer {
          id
          owner
          bio
          firstName
          lastName
          isBusiness
          email
          phone
          location
          formattedLocation
          isAdminLevel1
          adminLevel1
          businessName
          img
          imgStatus
          acceptedTerms
          horseAndHoundMarketing
          acceptedPrivacyPolicy
          allowNewsletter
          allowBlog
          createdAt
          updatedAt
          syncedAt
          mongoId
          resetPasswordToken
          emailVerificationToken
          newEmail
          stripeCustomerId
          isBilledOffline
          hasLiveAdverts
          wantedAdvertsNotificationsEnabled
          inAppMessagingNotificationsEnabled
          __typename
        }
        advert {
          id
          age
          breed
          breedId
          colourId
          currency
          description
          disciplines
          formattedLocation
          genderId
          headline
          height
          isFree
          isPOA
          location
          hasMedia
          price
          saleTypeId
          propertySaleTypeId
          trailerSaleTypeId
          status
          shouldSync
          syncedAt
          typeId
          categoryId
          createdAt
          updatedAt
          deletedAt
          publishedAt
          expiresAt
          expiredAt
          owner
          userSuspended
          soldAt
          contactBy
          mongoId
          slug
          adminLevel1
          ownerStatus
          horseAndHoundMagazine
          weight
          sleeps
          payload
          condition
          numberOfHorses
          modelName
          manufacturer
          propertyTypeId
          numberOfBedrooms
          numberOfBathrooms
          numberOfReceptionRooms
          numberOfStables
          facilities
          living
          affiliatedCompetitionRecord
          annex
          numberOfPaddocks
          salary
          accommodation
          petsAllowed
          horsesAllowed
          ftpt
          stablesTypeId
          priceTypeId
          partner
          hhAdvertId
          magazineHeadline
          magazineDescription
          dam
          damGrandDam
          damGrandDamGreatGrandDam
          damGrandDamGreatGrandSire
          damGrandSire
          damGrandSireGreatGrandDam
          damGrandSireGreatGrandSire
          sire
          sireGrandDam
          sireGrandDamGreatGrandDam
          sireGrandDamGreatGrandSire
          sireGrandSire
          sireGrandSireGreatGrandDam
          sireGrandSireGreatGrandSire
          stallionsName
          studFeeIncludesVat
          studBookOfOrigin
          graded
          gradedWith
          availability
          concessions
          trailerTypeId
          jobTypeId
          liveryTypeId
          stallionName
          sireDamsire
          packageId
          phone
          email
          whatsApp
          ifSoldReminderSent
          expiringSoonReminderSent
          needHelpReminderSent
          expiredButNotSoldReminderSent
          lastChanceToRenewReminderSent
          expiringNextDayReminderSent
          expiredReminderSent
          skippedExpiredReminder
          skippedExpiresSoonReminder
          websiteLink
          extraImagesLimit
          extraVideosLimit
          extraYouTubeVideosLimit
          featuredAt
          includeSocialMediaPost
          includeWebsiteLink
          includeBoostOnce
          includeBoostMulti
          includeFeatured
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const conversationsForCustomer = /* GraphQL */ `
  query ConversationsForCustomer(
    $customerId: ID
    $syncedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    conversationsForCustomer(
      customerId: $customerId
      syncedAt: $syncedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        mongoId
        sellerId
        customerId
        advertId
        usersDeleted
        createdAt
        updatedAt
        deletedAt
        syncedAt
        messages {
          nextToken
          __typename
        }
        seller {
          id
          owner
          bio
          firstName
          lastName
          isBusiness
          email
          phone
          location
          formattedLocation
          isAdminLevel1
          adminLevel1
          businessName
          img
          imgStatus
          acceptedTerms
          horseAndHoundMarketing
          acceptedPrivacyPolicy
          allowNewsletter
          allowBlog
          createdAt
          updatedAt
          syncedAt
          mongoId
          resetPasswordToken
          emailVerificationToken
          newEmail
          stripeCustomerId
          isBilledOffline
          hasLiveAdverts
          wantedAdvertsNotificationsEnabled
          inAppMessagingNotificationsEnabled
          __typename
        }
        customer {
          id
          owner
          bio
          firstName
          lastName
          isBusiness
          email
          phone
          location
          formattedLocation
          isAdminLevel1
          adminLevel1
          businessName
          img
          imgStatus
          acceptedTerms
          horseAndHoundMarketing
          acceptedPrivacyPolicy
          allowNewsletter
          allowBlog
          createdAt
          updatedAt
          syncedAt
          mongoId
          resetPasswordToken
          emailVerificationToken
          newEmail
          stripeCustomerId
          isBilledOffline
          hasLiveAdverts
          wantedAdvertsNotificationsEnabled
          inAppMessagingNotificationsEnabled
          __typename
        }
        advert {
          id
          age
          breed
          breedId
          colourId
          currency
          description
          disciplines
          formattedLocation
          genderId
          headline
          height
          isFree
          isPOA
          location
          hasMedia
          price
          saleTypeId
          propertySaleTypeId
          trailerSaleTypeId
          status
          shouldSync
          syncedAt
          typeId
          categoryId
          createdAt
          updatedAt
          deletedAt
          publishedAt
          expiresAt
          expiredAt
          owner
          userSuspended
          soldAt
          contactBy
          mongoId
          slug
          adminLevel1
          ownerStatus
          horseAndHoundMagazine
          weight
          sleeps
          payload
          condition
          numberOfHorses
          modelName
          manufacturer
          propertyTypeId
          numberOfBedrooms
          numberOfBathrooms
          numberOfReceptionRooms
          numberOfStables
          facilities
          living
          affiliatedCompetitionRecord
          annex
          numberOfPaddocks
          salary
          accommodation
          petsAllowed
          horsesAllowed
          ftpt
          stablesTypeId
          priceTypeId
          partner
          hhAdvertId
          magazineHeadline
          magazineDescription
          dam
          damGrandDam
          damGrandDamGreatGrandDam
          damGrandDamGreatGrandSire
          damGrandSire
          damGrandSireGreatGrandDam
          damGrandSireGreatGrandSire
          sire
          sireGrandDam
          sireGrandDamGreatGrandDam
          sireGrandDamGreatGrandSire
          sireGrandSire
          sireGrandSireGreatGrandDam
          sireGrandSireGreatGrandSire
          stallionsName
          studFeeIncludesVat
          studBookOfOrigin
          graded
          gradedWith
          availability
          concessions
          trailerTypeId
          jobTypeId
          liveryTypeId
          stallionName
          sireDamsire
          packageId
          phone
          email
          whatsApp
          ifSoldReminderSent
          expiringSoonReminderSent
          needHelpReminderSent
          expiredButNotSoldReminderSent
          lastChanceToRenewReminderSent
          expiringNextDayReminderSent
          expiredReminderSent
          skippedExpiredReminder
          skippedExpiresSoonReminder
          websiteLink
          extraImagesLimit
          extraVideosLimit
          extraYouTubeVideosLimit
          featuredAt
          includeSocialMediaPost
          includeWebsiteLink
          includeBoostOnce
          includeBoostMulti
          includeFeatured
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const messagesForConversationId = /* GraphQL */ `
  query MessagesForConversationId(
    $conversationId: ID
    $syncedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesForConversationId(
      conversationId: $conversationId
      syncedAt: $syncedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        message
        createdAt
        updatedAt
        syncedAt
        deletedAt
        owner
        recipientId
        conversationId
        encryptionKey
        read
        conversation {
          id
          mongoId
          sellerId
          customerId
          advertId
          usersDeleted
          createdAt
          updatedAt
          deletedAt
          syncedAt
          __typename
        }
        user {
          id
          owner
          bio
          firstName
          lastName
          isBusiness
          email
          phone
          location
          formattedLocation
          isAdminLevel1
          adminLevel1
          businessName
          img
          imgStatus
          acceptedTerms
          horseAndHoundMarketing
          acceptedPrivacyPolicy
          allowNewsletter
          allowBlog
          createdAt
          updatedAt
          syncedAt
          mongoId
          resetPasswordToken
          emailVerificationToken
          newEmail
          stripeCustomerId
          isBilledOffline
          hasLiveAdverts
          wantedAdvertsNotificationsEnabled
          inAppMessagingNotificationsEnabled
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const messagesForOwner = /* GraphQL */ `
  query MessagesForOwner(
    $owner: ID
    $syncedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesForOwner(
      owner: $owner
      syncedAt: $syncedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        message
        createdAt
        updatedAt
        syncedAt
        deletedAt
        owner
        recipientId
        conversationId
        encryptionKey
        read
        conversation {
          id
          mongoId
          sellerId
          customerId
          advertId
          usersDeleted
          createdAt
          updatedAt
          deletedAt
          syncedAt
          __typename
        }
        user {
          id
          owner
          bio
          firstName
          lastName
          isBusiness
          email
          phone
          location
          formattedLocation
          isAdminLevel1
          adminLevel1
          businessName
          img
          imgStatus
          acceptedTerms
          horseAndHoundMarketing
          acceptedPrivacyPolicy
          allowNewsletter
          allowBlog
          createdAt
          updatedAt
          syncedAt
          mongoId
          resetPasswordToken
          emailVerificationToken
          newEmail
          stripeCustomerId
          isBilledOffline
          hasLiveAdverts
          wantedAdvertsNotificationsEnabled
          inAppMessagingNotificationsEnabled
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const messagesForRecipient = /* GraphQL */ `
  query MessagesForRecipient(
    $recipientId: ID
    $syncedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesForRecipient(
      recipientId: $recipientId
      syncedAt: $syncedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        message
        createdAt
        updatedAt
        syncedAt
        deletedAt
        owner
        recipientId
        conversationId
        encryptionKey
        read
        conversation {
          id
          mongoId
          sellerId
          customerId
          advertId
          usersDeleted
          createdAt
          updatedAt
          deletedAt
          syncedAt
          __typename
        }
        user {
          id
          owner
          bio
          firstName
          lastName
          isBusiness
          email
          phone
          location
          formattedLocation
          isAdminLevel1
          adminLevel1
          businessName
          img
          imgStatus
          acceptedTerms
          horseAndHoundMarketing
          acceptedPrivacyPolicy
          allowNewsletter
          allowBlog
          createdAt
          updatedAt
          syncedAt
          mongoId
          resetPasswordToken
          emailVerificationToken
          newEmail
          stripeCustomerId
          isBilledOffline
          hasLiveAdverts
          wantedAdvertsNotificationsEnabled
          inAppMessagingNotificationsEnabled
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchFavourites = /* GraphQL */ `
  query SearchFavourites(
    $filter: SearchableFavouriteFilterInput
    $sort: SearchableFavouriteSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchFavourites(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        advertId
        owner
        syncedAt
        updatedAt
        deletedAt
        createdAt
        advert {
          id
          age
          breed
          breedId
          colourId
          currency
          description
          disciplines
          formattedLocation
          genderId
          headline
          height
          isFree
          isPOA
          location
          hasMedia
          price
          saleTypeId
          propertySaleTypeId
          trailerSaleTypeId
          status
          shouldSync
          syncedAt
          typeId
          categoryId
          createdAt
          updatedAt
          deletedAt
          publishedAt
          expiresAt
          expiredAt
          owner
          userSuspended
          soldAt
          contactBy
          mongoId
          slug
          adminLevel1
          ownerStatus
          horseAndHoundMagazine
          weight
          sleeps
          payload
          condition
          numberOfHorses
          modelName
          manufacturer
          propertyTypeId
          numberOfBedrooms
          numberOfBathrooms
          numberOfReceptionRooms
          numberOfStables
          facilities
          living
          affiliatedCompetitionRecord
          annex
          numberOfPaddocks
          salary
          accommodation
          petsAllowed
          horsesAllowed
          ftpt
          stablesTypeId
          priceTypeId
          partner
          hhAdvertId
          magazineHeadline
          magazineDescription
          dam
          damGrandDam
          damGrandDamGreatGrandDam
          damGrandDamGreatGrandSire
          damGrandSire
          damGrandSireGreatGrandDam
          damGrandSireGreatGrandSire
          sire
          sireGrandDam
          sireGrandDamGreatGrandDam
          sireGrandDamGreatGrandSire
          sireGrandSire
          sireGrandSireGreatGrandDam
          sireGrandSireGreatGrandSire
          stallionsName
          studFeeIncludesVat
          studBookOfOrigin
          graded
          gradedWith
          availability
          concessions
          trailerTypeId
          jobTypeId
          liveryTypeId
          stallionName
          sireDamsire
          packageId
          phone
          email
          whatsApp
          ifSoldReminderSent
          expiringSoonReminderSent
          needHelpReminderSent
          expiredButNotSoldReminderSent
          lastChanceToRenewReminderSent
          expiringNextDayReminderSent
          expiredReminderSent
          skippedExpiredReminder
          skippedExpiresSoonReminder
          websiteLink
          extraImagesLimit
          extraVideosLimit
          extraYouTubeVideosLimit
          featuredAt
          includeSocialMediaPost
          includeWebsiteLink
          includeBoostOnce
          includeBoostMulti
          includeFeatured
          __typename
        }
        __typename
      }
      nextToken
      total
      __typename
    }
  }
`;
export const getProfile = /* GraphQL */ `
  query GetProfile($id: ID!) {
    getProfile(id: $id) {
      id
      owner
      bio
      firstName
      lastName
      isBusiness
      coords {
        lat
        lon
        __typename
      }
      email
      phone
      location
      formattedLocation
      isAdminLevel1
      adminLevel1
      businessName
      exactCoords {
        lat
        lon
        __typename
      }
      img
      imgStatus
      acceptedTerms
      horseAndHoundMarketing
      acceptedPrivacyPolicy
      allowNewsletter
      allowBlog
      createdAt
      updatedAt
      syncedAt
      mongoId
      resetPasswordToken
      emailVerificationToken
      newEmail
      stripeCustomerId
      isBilledOffline
      hasLiveAdverts
      wantedAdvertsNotificationsEnabled
      inAppMessagingNotificationsEnabled
      __typename
    }
  }
`;
export const listProfiles = /* GraphQL */ `
  query ListProfiles(
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        bio
        firstName
        lastName
        isBusiness
        coords {
          lat
          lon
          __typename
        }
        email
        phone
        location
        formattedLocation
        isAdminLevel1
        adminLevel1
        businessName
        exactCoords {
          lat
          lon
          __typename
        }
        img
        imgStatus
        acceptedTerms
        horseAndHoundMarketing
        acceptedPrivacyPolicy
        allowNewsletter
        allowBlog
        createdAt
        updatedAt
        syncedAt
        mongoId
        resetPasswordToken
        emailVerificationToken
        newEmail
        stripeCustomerId
        isBilledOffline
        hasLiveAdverts
        wantedAdvertsNotificationsEnabled
        inAppMessagingNotificationsEnabled
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const profileByVerificationToken = /* GraphQL */ `
  query ProfileByVerificationToken(
    $emailVerificationToken: String
    $sortDirection: ModelSortDirection
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    profileByVerificationToken(
      emailVerificationToken: $emailVerificationToken
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        bio
        firstName
        lastName
        isBusiness
        coords {
          lat
          lon
          __typename
        }
        email
        phone
        location
        formattedLocation
        isAdminLevel1
        adminLevel1
        businessName
        exactCoords {
          lat
          lon
          __typename
        }
        img
        imgStatus
        acceptedTerms
        horseAndHoundMarketing
        acceptedPrivacyPolicy
        allowNewsletter
        allowBlog
        createdAt
        updatedAt
        syncedAt
        mongoId
        resetPasswordToken
        emailVerificationToken
        newEmail
        stripeCustomerId
        isBilledOffline
        hasLiveAdverts
        wantedAdvertsNotificationsEnabled
        inAppMessagingNotificationsEnabled
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchProfiles = /* GraphQL */ `
  query SearchProfiles(
    $filter: SearchableProfileFilterInput
    $sort: SearchableProfileSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchProfiles(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        owner
        bio
        firstName
        lastName
        isBusiness
        coords {
          lat
          lon
          __typename
        }
        email
        phone
        location
        formattedLocation
        isAdminLevel1
        adminLevel1
        businessName
        exactCoords {
          lat
          lon
          __typename
        }
        img
        imgStatus
        acceptedTerms
        horseAndHoundMarketing
        acceptedPrivacyPolicy
        allowNewsletter
        allowBlog
        createdAt
        updatedAt
        syncedAt
        mongoId
        resetPasswordToken
        emailVerificationToken
        newEmail
        stripeCustomerId
        isBilledOffline
        hasLiveAdverts
        wantedAdvertsNotificationsEnabled
        inAppMessagingNotificationsEnabled
        __typename
      }
      nextToken
      total
      __typename
    }
  }
`;
export const getColour = /* GraphQL */ `
  query GetColour($id: ID!) {
    getColour(id: $id) {
      id
      label
      updatedAt
      createdAt
      mongoId
      showOn
      slug
      __typename
    }
  }
`;
export const listColours = /* GraphQL */ `
  query ListColours(
    $filter: ModelColourFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listColours(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        label
        updatedAt
        createdAt
        mongoId
        showOn
        slug
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const colourWithSlug = /* GraphQL */ `
  query ColourWithSlug(
    $slug: String
    $sortDirection: ModelSortDirection
    $filter: ModelColourFilterInput
    $limit: Int
    $nextToken: String
  ) {
    colourWithSlug(
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        label
        updatedAt
        createdAt
        mongoId
        showOn
        slug
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGender = /* GraphQL */ `
  query GetGender($id: ID!) {
    getGender(id: $id) {
      id
      label
      updatedAt
      mongoId
      showOn
      slug
      createdAt
      __typename
    }
  }
`;
export const listGenders = /* GraphQL */ `
  query ListGenders(
    $filter: ModelGenderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGenders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        label
        updatedAt
        mongoId
        showOn
        slug
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const genderWithSlug = /* GraphQL */ `
  query GenderWithSlug(
    $slug: String
    $sortDirection: ModelSortDirection
    $filter: ModelGenderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    genderWithSlug(
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        label
        updatedAt
        mongoId
        showOn
        slug
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      label
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const listCategorys = /* GraphQL */ `
  query ListCategorys(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        label
        updatedAt
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getType = /* GraphQL */ `
  query GetType($id: ID!) {
    getType(id: $id) {
      id
      label
      updatedAt
      mongoId
      slug
      createdAt
      __typename
    }
  }
`;
export const listTypes = /* GraphQL */ `
  query ListTypes(
    $filter: ModelTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        label
        updatedAt
        mongoId
        slug
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const typeWithSlug = /* GraphQL */ `
  query TypeWithSlug(
    $slug: String
    $sortDirection: ModelSortDirection
    $filter: ModelTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    typeWithSlug(
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        label
        updatedAt
        mongoId
        slug
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSaleType = /* GraphQL */ `
  query GetSaleType($id: ID!) {
    getSaleType(id: $id) {
      id
      label
      updatedAt
      mongoId
      slug
      createdAt
      __typename
    }
  }
`;
export const listSaleTypes = /* GraphQL */ `
  query ListSaleTypes(
    $filter: ModelSaleTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSaleTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        label
        updatedAt
        mongoId
        slug
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const saleTypeWithSlug = /* GraphQL */ `
  query SaleTypeWithSlug(
    $slug: String
    $sortDirection: ModelSortDirection
    $filter: ModelSaleTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    saleTypeWithSlug(
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        label
        updatedAt
        mongoId
        slug
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDiscipline = /* GraphQL */ `
  query GetDiscipline($id: ID!) {
    getDiscipline(id: $id) {
      id
      label
      type
      updatedAt
      mongoId
      showOn
      slug
      createdAt
      __typename
    }
  }
`;
export const listDisciplines = /* GraphQL */ `
  query ListDisciplines(
    $filter: ModelDisciplineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDisciplines(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        label
        type
        updatedAt
        mongoId
        showOn
        slug
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const disciplineWithSlug = /* GraphQL */ `
  query DisciplineWithSlug(
    $slug: String
    $sortDirection: ModelSortDirection
    $filter: ModelDisciplineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    disciplineWithSlug(
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        label
        type
        updatedAt
        mongoId
        showOn
        slug
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAdvert = /* GraphQL */ `
  query GetAdvert($id: ID!) {
    getAdvert(id: $id) {
      id
      age
      breed
      breedId
      colourId
      coords {
        lat
        lon
        __typename
      }
      currency
      description
      disciplines
      exactCoords {
        lat
        lon
        __typename
      }
      formattedLocation
      genderId
      headline
      height
      isFree
      isPOA
      location
      media {
        id
        fileName
        width
        height
        type
        duration
        thumbnail
        status
        blurHash
        sizes {
          width
          label
          scale
          isScaled
          __typename
        }
        __typename
      }
      youTubeVideos {
        id
        title
        description
        __typename
      }
      hasMedia
      price
      saleTypeId
      propertySaleTypeId
      trailerSaleTypeId
      status
      shouldSync
      syncedAt
      typeId
      categoryId
      createdAt
      updatedAt
      deletedAt
      publishedAt
      expiresAt
      expiredAt
      owner
      userSuspended
      soldAt
      contactBy
      mongoId
      slug
      adminLevel1
      ownerStatus
      horseAndHoundMagazine
      weight
      sleeps
      payload
      condition
      numberOfHorses
      modelName
      manufacturer
      propertyTypeId
      numberOfBedrooms
      numberOfBathrooms
      numberOfReceptionRooms
      numberOfStables
      facilities
      living
      affiliatedCompetitionRecord
      annex
      numberOfPaddocks
      salary
      accommodation
      petsAllowed
      horsesAllowed
      ftpt
      stablesTypeId
      priceTypeId
      partner
      hhAdvertId
      magazineHeadline
      magazineDescription
      dam
      damGrandDam
      damGrandDamGreatGrandDam
      damGrandDamGreatGrandSire
      damGrandSire
      damGrandSireGreatGrandDam
      damGrandSireGreatGrandSire
      sire
      sireGrandDam
      sireGrandDamGreatGrandDam
      sireGrandDamGreatGrandSire
      sireGrandSire
      sireGrandSireGreatGrandDam
      sireGrandSireGreatGrandSire
      stallionsName
      studFeeIncludesVat
      studBookOfOrigin
      graded
      gradedWith
      availability
      concessions
      studPrice {
        fresh {
          amount
          currency
          __typename
        }
        chilled {
          amount
          currency
          __typename
        }
        frozen {
          amount
          currency
          __typename
        }
        natural {
          amount
          currency
          __typename
        }
        __typename
      }
      trailerTypeId
      jobTypeId
      liveryTypeId
      stallionName
      sireDamsire
      packageId
      phone
      email
      whatsApp
      ifSoldReminderSent
      expiringSoonReminderSent
      needHelpReminderSent
      expiredButNotSoldReminderSent
      lastChanceToRenewReminderSent
      expiringNextDayReminderSent
      expiredReminderSent
      skippedExpiredReminder
      skippedExpiresSoonReminder
      websiteLink
      extraImagesLimit
      extraVideosLimit
      extraYouTubeVideosLimit
      purchased {
        extraImages
        extraVideos
        extraYouTubeVideos
        websiteLink
        whatsApp
        __typename
      }
      featuredAt
      includeSocialMediaPost
      includeWebsiteLink
      includeBoostOnce
      includeBoostMulti
      includeFeatured
      seller {
        id
        owner
        bio
        firstName
        lastName
        isBusiness
        coords {
          lat
          lon
          __typename
        }
        email
        phone
        location
        formattedLocation
        isAdminLevel1
        adminLevel1
        businessName
        exactCoords {
          lat
          lon
          __typename
        }
        img
        imgStatus
        acceptedTerms
        horseAndHoundMarketing
        acceptedPrivacyPolicy
        allowNewsletter
        allowBlog
        createdAt
        updatedAt
        syncedAt
        mongoId
        resetPasswordToken
        emailVerificationToken
        newEmail
        stripeCustomerId
        isBilledOffline
        hasLiveAdverts
        wantedAdvertsNotificationsEnabled
        inAppMessagingNotificationsEnabled
        __typename
      }
      colour {
        id
        label
        updatedAt
        createdAt
        mongoId
        showOn
        slug
        __typename
      }
      gender {
        id
        label
        updatedAt
        mongoId
        showOn
        slug
        createdAt
        __typename
      }
      category {
        id
        label
        updatedAt
        createdAt
        __typename
      }
      type {
        id
        label
        updatedAt
        mongoId
        slug
        createdAt
        __typename
      }
      saleType {
        id
        label
        updatedAt
        mongoId
        slug
        createdAt
        __typename
      }
      addons {
        items {
          id
          whatsApp
          includeSocialMediaPost
          includeWebsiteLink
          includeWhatsApp
          websiteLink
          includeBoostOnce
          includeBoostMulti
          includeFeatured
          extraImages
          extraVideos
          extraYouTubeVideos
          method
          purchasedAt
          transactionId
          advertId
          createdAt
          updatedAt
          deletedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      mediaProcessing {
        items {
          id
          owner
          advertId
          type
          error
          status
          progress
          jobId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      boosts {
        items {
          id
          date
          type
          status
          advertId
          createdAt
          updatedAt
          deletedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      featured {
        items {
          id
          startAt
          endAt
          status
          advertId
          createdAt
          updatedAt
          deletedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      socialPromotions {
        items {
          id
          requestedAt
          advertId
          createdAt
          updatedAt
          deletedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const listAdverts = /* GraphQL */ `
  query ListAdverts(
    $filter: ModelAdvertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdverts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        age
        breed
        breedId
        colourId
        coords {
          lat
          lon
          __typename
        }
        currency
        description
        disciplines
        exactCoords {
          lat
          lon
          __typename
        }
        formattedLocation
        genderId
        headline
        height
        isFree
        isPOA
        location
        media {
          id
          fileName
          width
          height
          type
          duration
          thumbnail
          status
          blurHash
          __typename
        }
        youTubeVideos {
          id
          title
          description
          __typename
        }
        hasMedia
        price
        saleTypeId
        propertySaleTypeId
        trailerSaleTypeId
        status
        shouldSync
        syncedAt
        typeId
        categoryId
        createdAt
        updatedAt
        deletedAt
        publishedAt
        expiresAt
        expiredAt
        owner
        userSuspended
        soldAt
        contactBy
        mongoId
        slug
        adminLevel1
        ownerStatus
        horseAndHoundMagazine
        weight
        sleeps
        payload
        condition
        numberOfHorses
        modelName
        manufacturer
        propertyTypeId
        numberOfBedrooms
        numberOfBathrooms
        numberOfReceptionRooms
        numberOfStables
        facilities
        living
        affiliatedCompetitionRecord
        annex
        numberOfPaddocks
        salary
        accommodation
        petsAllowed
        horsesAllowed
        ftpt
        stablesTypeId
        priceTypeId
        partner
        hhAdvertId
        magazineHeadline
        magazineDescription
        dam
        damGrandDam
        damGrandDamGreatGrandDam
        damGrandDamGreatGrandSire
        damGrandSire
        damGrandSireGreatGrandDam
        damGrandSireGreatGrandSire
        sire
        sireGrandDam
        sireGrandDamGreatGrandDam
        sireGrandDamGreatGrandSire
        sireGrandSire
        sireGrandSireGreatGrandDam
        sireGrandSireGreatGrandSire
        stallionsName
        studFeeIncludesVat
        studBookOfOrigin
        graded
        gradedWith
        availability
        concessions
        studPrice {
          __typename
        }
        trailerTypeId
        jobTypeId
        liveryTypeId
        stallionName
        sireDamsire
        packageId
        phone
        email
        whatsApp
        ifSoldReminderSent
        expiringSoonReminderSent
        needHelpReminderSent
        expiredButNotSoldReminderSent
        lastChanceToRenewReminderSent
        expiringNextDayReminderSent
        expiredReminderSent
        skippedExpiredReminder
        skippedExpiresSoonReminder
        websiteLink
        extraImagesLimit
        extraVideosLimit
        extraYouTubeVideosLimit
        purchased {
          extraImages
          extraVideos
          extraYouTubeVideos
          websiteLink
          whatsApp
          __typename
        }
        featuredAt
        includeSocialMediaPost
        includeWebsiteLink
        includeBoostOnce
        includeBoostMulti
        includeFeatured
        seller {
          id
          owner
          bio
          firstName
          lastName
          isBusiness
          email
          phone
          location
          formattedLocation
          isAdminLevel1
          adminLevel1
          businessName
          img
          imgStatus
          acceptedTerms
          horseAndHoundMarketing
          acceptedPrivacyPolicy
          allowNewsletter
          allowBlog
          createdAt
          updatedAt
          syncedAt
          mongoId
          resetPasswordToken
          emailVerificationToken
          newEmail
          stripeCustomerId
          isBilledOffline
          hasLiveAdverts
          wantedAdvertsNotificationsEnabled
          inAppMessagingNotificationsEnabled
          __typename
        }
        colour {
          id
          label
          updatedAt
          createdAt
          mongoId
          showOn
          slug
          __typename
        }
        gender {
          id
          label
          updatedAt
          mongoId
          showOn
          slug
          createdAt
          __typename
        }
        category {
          id
          label
          updatedAt
          createdAt
          __typename
        }
        type {
          id
          label
          updatedAt
          mongoId
          slug
          createdAt
          __typename
        }
        saleType {
          id
          label
          updatedAt
          mongoId
          slug
          createdAt
          __typename
        }
        addons {
          nextToken
          __typename
        }
        mediaProcessing {
          nextToken
          __typename
        }
        boosts {
          nextToken
          __typename
        }
        featured {
          nextToken
          __typename
        }
        socialPromotions {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const advertsForOwner = /* GraphQL */ `
  query AdvertsForOwner(
    $owner: ID
    $syncedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdvertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    advertsForOwner(
      owner: $owner
      syncedAt: $syncedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        age
        breed
        breedId
        colourId
        coords {
          lat
          lon
          __typename
        }
        currency
        description
        disciplines
        exactCoords {
          lat
          lon
          __typename
        }
        formattedLocation
        genderId
        headline
        height
        isFree
        isPOA
        location
        media {
          id
          fileName
          width
          height
          type
          duration
          thumbnail
          status
          blurHash
          __typename
        }
        youTubeVideos {
          id
          title
          description
          __typename
        }
        hasMedia
        price
        saleTypeId
        propertySaleTypeId
        trailerSaleTypeId
        status
        shouldSync
        syncedAt
        typeId
        categoryId
        createdAt
        updatedAt
        deletedAt
        publishedAt
        expiresAt
        expiredAt
        owner
        userSuspended
        soldAt
        contactBy
        mongoId
        slug
        adminLevel1
        ownerStatus
        horseAndHoundMagazine
        weight
        sleeps
        payload
        condition
        numberOfHorses
        modelName
        manufacturer
        propertyTypeId
        numberOfBedrooms
        numberOfBathrooms
        numberOfReceptionRooms
        numberOfStables
        facilities
        living
        affiliatedCompetitionRecord
        annex
        numberOfPaddocks
        salary
        accommodation
        petsAllowed
        horsesAllowed
        ftpt
        stablesTypeId
        priceTypeId
        partner
        hhAdvertId
        magazineHeadline
        magazineDescription
        dam
        damGrandDam
        damGrandDamGreatGrandDam
        damGrandDamGreatGrandSire
        damGrandSire
        damGrandSireGreatGrandDam
        damGrandSireGreatGrandSire
        sire
        sireGrandDam
        sireGrandDamGreatGrandDam
        sireGrandDamGreatGrandSire
        sireGrandSire
        sireGrandSireGreatGrandDam
        sireGrandSireGreatGrandSire
        stallionsName
        studFeeIncludesVat
        studBookOfOrigin
        graded
        gradedWith
        availability
        concessions
        studPrice {
          __typename
        }
        trailerTypeId
        jobTypeId
        liveryTypeId
        stallionName
        sireDamsire
        packageId
        phone
        email
        whatsApp
        ifSoldReminderSent
        expiringSoonReminderSent
        needHelpReminderSent
        expiredButNotSoldReminderSent
        lastChanceToRenewReminderSent
        expiringNextDayReminderSent
        expiredReminderSent
        skippedExpiredReminder
        skippedExpiresSoonReminder
        websiteLink
        extraImagesLimit
        extraVideosLimit
        extraYouTubeVideosLimit
        purchased {
          extraImages
          extraVideos
          extraYouTubeVideos
          websiteLink
          whatsApp
          __typename
        }
        featuredAt
        includeSocialMediaPost
        includeWebsiteLink
        includeBoostOnce
        includeBoostMulti
        includeFeatured
        seller {
          id
          owner
          bio
          firstName
          lastName
          isBusiness
          email
          phone
          location
          formattedLocation
          isAdminLevel1
          adminLevel1
          businessName
          img
          imgStatus
          acceptedTerms
          horseAndHoundMarketing
          acceptedPrivacyPolicy
          allowNewsletter
          allowBlog
          createdAt
          updatedAt
          syncedAt
          mongoId
          resetPasswordToken
          emailVerificationToken
          newEmail
          stripeCustomerId
          isBilledOffline
          hasLiveAdverts
          wantedAdvertsNotificationsEnabled
          inAppMessagingNotificationsEnabled
          __typename
        }
        colour {
          id
          label
          updatedAt
          createdAt
          mongoId
          showOn
          slug
          __typename
        }
        gender {
          id
          label
          updatedAt
          mongoId
          showOn
          slug
          createdAt
          __typename
        }
        category {
          id
          label
          updatedAt
          createdAt
          __typename
        }
        type {
          id
          label
          updatedAt
          mongoId
          slug
          createdAt
          __typename
        }
        saleType {
          id
          label
          updatedAt
          mongoId
          slug
          createdAt
          __typename
        }
        addons {
          nextToken
          __typename
        }
        mediaProcessing {
          nextToken
          __typename
        }
        boosts {
          nextToken
          __typename
        }
        featured {
          nextToken
          __typename
        }
        socialPromotions {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const advertsWithStatus = /* GraphQL */ `
  query AdvertsWithStatus(
    $status: Int
    $syncedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdvertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    advertsWithStatus(
      status: $status
      syncedAt: $syncedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        age
        breed
        breedId
        colourId
        coords {
          lat
          lon
          __typename
        }
        currency
        description
        disciplines
        exactCoords {
          lat
          lon
          __typename
        }
        formattedLocation
        genderId
        headline
        height
        isFree
        isPOA
        location
        media {
          id
          fileName
          width
          height
          type
          duration
          thumbnail
          status
          blurHash
          __typename
        }
        youTubeVideos {
          id
          title
          description
          __typename
        }
        hasMedia
        price
        saleTypeId
        propertySaleTypeId
        trailerSaleTypeId
        status
        shouldSync
        syncedAt
        typeId
        categoryId
        createdAt
        updatedAt
        deletedAt
        publishedAt
        expiresAt
        expiredAt
        owner
        userSuspended
        soldAt
        contactBy
        mongoId
        slug
        adminLevel1
        ownerStatus
        horseAndHoundMagazine
        weight
        sleeps
        payload
        condition
        numberOfHorses
        modelName
        manufacturer
        propertyTypeId
        numberOfBedrooms
        numberOfBathrooms
        numberOfReceptionRooms
        numberOfStables
        facilities
        living
        affiliatedCompetitionRecord
        annex
        numberOfPaddocks
        salary
        accommodation
        petsAllowed
        horsesAllowed
        ftpt
        stablesTypeId
        priceTypeId
        partner
        hhAdvertId
        magazineHeadline
        magazineDescription
        dam
        damGrandDam
        damGrandDamGreatGrandDam
        damGrandDamGreatGrandSire
        damGrandSire
        damGrandSireGreatGrandDam
        damGrandSireGreatGrandSire
        sire
        sireGrandDam
        sireGrandDamGreatGrandDam
        sireGrandDamGreatGrandSire
        sireGrandSire
        sireGrandSireGreatGrandDam
        sireGrandSireGreatGrandSire
        stallionsName
        studFeeIncludesVat
        studBookOfOrigin
        graded
        gradedWith
        availability
        concessions
        studPrice {
          __typename
        }
        trailerTypeId
        jobTypeId
        liveryTypeId
        stallionName
        sireDamsire
        packageId
        phone
        email
        whatsApp
        ifSoldReminderSent
        expiringSoonReminderSent
        needHelpReminderSent
        expiredButNotSoldReminderSent
        lastChanceToRenewReminderSent
        expiringNextDayReminderSent
        expiredReminderSent
        skippedExpiredReminder
        skippedExpiresSoonReminder
        websiteLink
        extraImagesLimit
        extraVideosLimit
        extraYouTubeVideosLimit
        purchased {
          extraImages
          extraVideos
          extraYouTubeVideos
          websiteLink
          whatsApp
          __typename
        }
        featuredAt
        includeSocialMediaPost
        includeWebsiteLink
        includeBoostOnce
        includeBoostMulti
        includeFeatured
        seller {
          id
          owner
          bio
          firstName
          lastName
          isBusiness
          email
          phone
          location
          formattedLocation
          isAdminLevel1
          adminLevel1
          businessName
          img
          imgStatus
          acceptedTerms
          horseAndHoundMarketing
          acceptedPrivacyPolicy
          allowNewsletter
          allowBlog
          createdAt
          updatedAt
          syncedAt
          mongoId
          resetPasswordToken
          emailVerificationToken
          newEmail
          stripeCustomerId
          isBilledOffline
          hasLiveAdverts
          wantedAdvertsNotificationsEnabled
          inAppMessagingNotificationsEnabled
          __typename
        }
        colour {
          id
          label
          updatedAt
          createdAt
          mongoId
          showOn
          slug
          __typename
        }
        gender {
          id
          label
          updatedAt
          mongoId
          showOn
          slug
          createdAt
          __typename
        }
        category {
          id
          label
          updatedAt
          createdAt
          __typename
        }
        type {
          id
          label
          updatedAt
          mongoId
          slug
          createdAt
          __typename
        }
        saleType {
          id
          label
          updatedAt
          mongoId
          slug
          createdAt
          __typename
        }
        addons {
          nextToken
          __typename
        }
        mediaProcessing {
          nextToken
          __typename
        }
        boosts {
          nextToken
          __typename
        }
        featured {
          nextToken
          __typename
        }
        socialPromotions {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const advertsWithMongoId = /* GraphQL */ `
  query AdvertsWithMongoId(
    $mongoId: String
    $sortDirection: ModelSortDirection
    $filter: ModelAdvertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    advertsWithMongoId(
      mongoId: $mongoId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        age
        breed
        breedId
        colourId
        coords {
          lat
          lon
          __typename
        }
        currency
        description
        disciplines
        exactCoords {
          lat
          lon
          __typename
        }
        formattedLocation
        genderId
        headline
        height
        isFree
        isPOA
        location
        media {
          id
          fileName
          width
          height
          type
          duration
          thumbnail
          status
          blurHash
          __typename
        }
        youTubeVideos {
          id
          title
          description
          __typename
        }
        hasMedia
        price
        saleTypeId
        propertySaleTypeId
        trailerSaleTypeId
        status
        shouldSync
        syncedAt
        typeId
        categoryId
        createdAt
        updatedAt
        deletedAt
        publishedAt
        expiresAt
        expiredAt
        owner
        userSuspended
        soldAt
        contactBy
        mongoId
        slug
        adminLevel1
        ownerStatus
        horseAndHoundMagazine
        weight
        sleeps
        payload
        condition
        numberOfHorses
        modelName
        manufacturer
        propertyTypeId
        numberOfBedrooms
        numberOfBathrooms
        numberOfReceptionRooms
        numberOfStables
        facilities
        living
        affiliatedCompetitionRecord
        annex
        numberOfPaddocks
        salary
        accommodation
        petsAllowed
        horsesAllowed
        ftpt
        stablesTypeId
        priceTypeId
        partner
        hhAdvertId
        magazineHeadline
        magazineDescription
        dam
        damGrandDam
        damGrandDamGreatGrandDam
        damGrandDamGreatGrandSire
        damGrandSire
        damGrandSireGreatGrandDam
        damGrandSireGreatGrandSire
        sire
        sireGrandDam
        sireGrandDamGreatGrandDam
        sireGrandDamGreatGrandSire
        sireGrandSire
        sireGrandSireGreatGrandDam
        sireGrandSireGreatGrandSire
        stallionsName
        studFeeIncludesVat
        studBookOfOrigin
        graded
        gradedWith
        availability
        concessions
        studPrice {
          __typename
        }
        trailerTypeId
        jobTypeId
        liveryTypeId
        stallionName
        sireDamsire
        packageId
        phone
        email
        whatsApp
        ifSoldReminderSent
        expiringSoonReminderSent
        needHelpReminderSent
        expiredButNotSoldReminderSent
        lastChanceToRenewReminderSent
        expiringNextDayReminderSent
        expiredReminderSent
        skippedExpiredReminder
        skippedExpiresSoonReminder
        websiteLink
        extraImagesLimit
        extraVideosLimit
        extraYouTubeVideosLimit
        purchased {
          extraImages
          extraVideos
          extraYouTubeVideos
          websiteLink
          whatsApp
          __typename
        }
        featuredAt
        includeSocialMediaPost
        includeWebsiteLink
        includeBoostOnce
        includeBoostMulti
        includeFeatured
        seller {
          id
          owner
          bio
          firstName
          lastName
          isBusiness
          email
          phone
          location
          formattedLocation
          isAdminLevel1
          adminLevel1
          businessName
          img
          imgStatus
          acceptedTerms
          horseAndHoundMarketing
          acceptedPrivacyPolicy
          allowNewsletter
          allowBlog
          createdAt
          updatedAt
          syncedAt
          mongoId
          resetPasswordToken
          emailVerificationToken
          newEmail
          stripeCustomerId
          isBilledOffline
          hasLiveAdverts
          wantedAdvertsNotificationsEnabled
          inAppMessagingNotificationsEnabled
          __typename
        }
        colour {
          id
          label
          updatedAt
          createdAt
          mongoId
          showOn
          slug
          __typename
        }
        gender {
          id
          label
          updatedAt
          mongoId
          showOn
          slug
          createdAt
          __typename
        }
        category {
          id
          label
          updatedAt
          createdAt
          __typename
        }
        type {
          id
          label
          updatedAt
          mongoId
          slug
          createdAt
          __typename
        }
        saleType {
          id
          label
          updatedAt
          mongoId
          slug
          createdAt
          __typename
        }
        addons {
          nextToken
          __typename
        }
        mediaProcessing {
          nextToken
          __typename
        }
        boosts {
          nextToken
          __typename
        }
        featured {
          nextToken
          __typename
        }
        socialPromotions {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const advertWithSlug = /* GraphQL */ `
  query AdvertWithSlug(
    $slug: String
    $sortDirection: ModelSortDirection
    $filter: ModelAdvertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    advertWithSlug(
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        age
        breed
        breedId
        colourId
        coords {
          lat
          lon
          __typename
        }
        currency
        description
        disciplines
        exactCoords {
          lat
          lon
          __typename
        }
        formattedLocation
        genderId
        headline
        height
        isFree
        isPOA
        location
        media {
          id
          fileName
          width
          height
          type
          duration
          thumbnail
          status
          blurHash
          __typename
        }
        youTubeVideos {
          id
          title
          description
          __typename
        }
        hasMedia
        price
        saleTypeId
        propertySaleTypeId
        trailerSaleTypeId
        status
        shouldSync
        syncedAt
        typeId
        categoryId
        createdAt
        updatedAt
        deletedAt
        publishedAt
        expiresAt
        expiredAt
        owner
        userSuspended
        soldAt
        contactBy
        mongoId
        slug
        adminLevel1
        ownerStatus
        horseAndHoundMagazine
        weight
        sleeps
        payload
        condition
        numberOfHorses
        modelName
        manufacturer
        propertyTypeId
        numberOfBedrooms
        numberOfBathrooms
        numberOfReceptionRooms
        numberOfStables
        facilities
        living
        affiliatedCompetitionRecord
        annex
        numberOfPaddocks
        salary
        accommodation
        petsAllowed
        horsesAllowed
        ftpt
        stablesTypeId
        priceTypeId
        partner
        hhAdvertId
        magazineHeadline
        magazineDescription
        dam
        damGrandDam
        damGrandDamGreatGrandDam
        damGrandDamGreatGrandSire
        damGrandSire
        damGrandSireGreatGrandDam
        damGrandSireGreatGrandSire
        sire
        sireGrandDam
        sireGrandDamGreatGrandDam
        sireGrandDamGreatGrandSire
        sireGrandSire
        sireGrandSireGreatGrandDam
        sireGrandSireGreatGrandSire
        stallionsName
        studFeeIncludesVat
        studBookOfOrigin
        graded
        gradedWith
        availability
        concessions
        studPrice {
          __typename
        }
        trailerTypeId
        jobTypeId
        liveryTypeId
        stallionName
        sireDamsire
        packageId
        phone
        email
        whatsApp
        ifSoldReminderSent
        expiringSoonReminderSent
        needHelpReminderSent
        expiredButNotSoldReminderSent
        lastChanceToRenewReminderSent
        expiringNextDayReminderSent
        expiredReminderSent
        skippedExpiredReminder
        skippedExpiresSoonReminder
        websiteLink
        extraImagesLimit
        extraVideosLimit
        extraYouTubeVideosLimit
        purchased {
          extraImages
          extraVideos
          extraYouTubeVideos
          websiteLink
          whatsApp
          __typename
        }
        featuredAt
        includeSocialMediaPost
        includeWebsiteLink
        includeBoostOnce
        includeBoostMulti
        includeFeatured
        seller {
          id
          owner
          bio
          firstName
          lastName
          isBusiness
          email
          phone
          location
          formattedLocation
          isAdminLevel1
          adminLevel1
          businessName
          img
          imgStatus
          acceptedTerms
          horseAndHoundMarketing
          acceptedPrivacyPolicy
          allowNewsletter
          allowBlog
          createdAt
          updatedAt
          syncedAt
          mongoId
          resetPasswordToken
          emailVerificationToken
          newEmail
          stripeCustomerId
          isBilledOffline
          hasLiveAdverts
          wantedAdvertsNotificationsEnabled
          inAppMessagingNotificationsEnabled
          __typename
        }
        colour {
          id
          label
          updatedAt
          createdAt
          mongoId
          showOn
          slug
          __typename
        }
        gender {
          id
          label
          updatedAt
          mongoId
          showOn
          slug
          createdAt
          __typename
        }
        category {
          id
          label
          updatedAt
          createdAt
          __typename
        }
        type {
          id
          label
          updatedAt
          mongoId
          slug
          createdAt
          __typename
        }
        saleType {
          id
          label
          updatedAt
          mongoId
          slug
          createdAt
          __typename
        }
        addons {
          nextToken
          __typename
        }
        mediaProcessing {
          nextToken
          __typename
        }
        boosts {
          nextToken
          __typename
        }
        featured {
          nextToken
          __typename
        }
        socialPromotions {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ownerAdvertsWithStatus = /* GraphQL */ `
  query OwnerAdvertsWithStatus(
    $ownerStatus: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdvertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ownerAdvertsWithStatus(
      ownerStatus: $ownerStatus
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        age
        breed
        breedId
        colourId
        coords {
          lat
          lon
          __typename
        }
        currency
        description
        disciplines
        exactCoords {
          lat
          lon
          __typename
        }
        formattedLocation
        genderId
        headline
        height
        isFree
        isPOA
        location
        media {
          id
          fileName
          width
          height
          type
          duration
          thumbnail
          status
          blurHash
          __typename
        }
        youTubeVideos {
          id
          title
          description
          __typename
        }
        hasMedia
        price
        saleTypeId
        propertySaleTypeId
        trailerSaleTypeId
        status
        shouldSync
        syncedAt
        typeId
        categoryId
        createdAt
        updatedAt
        deletedAt
        publishedAt
        expiresAt
        expiredAt
        owner
        userSuspended
        soldAt
        contactBy
        mongoId
        slug
        adminLevel1
        ownerStatus
        horseAndHoundMagazine
        weight
        sleeps
        payload
        condition
        numberOfHorses
        modelName
        manufacturer
        propertyTypeId
        numberOfBedrooms
        numberOfBathrooms
        numberOfReceptionRooms
        numberOfStables
        facilities
        living
        affiliatedCompetitionRecord
        annex
        numberOfPaddocks
        salary
        accommodation
        petsAllowed
        horsesAllowed
        ftpt
        stablesTypeId
        priceTypeId
        partner
        hhAdvertId
        magazineHeadline
        magazineDescription
        dam
        damGrandDam
        damGrandDamGreatGrandDam
        damGrandDamGreatGrandSire
        damGrandSire
        damGrandSireGreatGrandDam
        damGrandSireGreatGrandSire
        sire
        sireGrandDam
        sireGrandDamGreatGrandDam
        sireGrandDamGreatGrandSire
        sireGrandSire
        sireGrandSireGreatGrandDam
        sireGrandSireGreatGrandSire
        stallionsName
        studFeeIncludesVat
        studBookOfOrigin
        graded
        gradedWith
        availability
        concessions
        studPrice {
          __typename
        }
        trailerTypeId
        jobTypeId
        liveryTypeId
        stallionName
        sireDamsire
        packageId
        phone
        email
        whatsApp
        ifSoldReminderSent
        expiringSoonReminderSent
        needHelpReminderSent
        expiredButNotSoldReminderSent
        lastChanceToRenewReminderSent
        expiringNextDayReminderSent
        expiredReminderSent
        skippedExpiredReminder
        skippedExpiresSoonReminder
        websiteLink
        extraImagesLimit
        extraVideosLimit
        extraYouTubeVideosLimit
        purchased {
          extraImages
          extraVideos
          extraYouTubeVideos
          websiteLink
          whatsApp
          __typename
        }
        featuredAt
        includeSocialMediaPost
        includeWebsiteLink
        includeBoostOnce
        includeBoostMulti
        includeFeatured
        seller {
          id
          owner
          bio
          firstName
          lastName
          isBusiness
          email
          phone
          location
          formattedLocation
          isAdminLevel1
          adminLevel1
          businessName
          img
          imgStatus
          acceptedTerms
          horseAndHoundMarketing
          acceptedPrivacyPolicy
          allowNewsletter
          allowBlog
          createdAt
          updatedAt
          syncedAt
          mongoId
          resetPasswordToken
          emailVerificationToken
          newEmail
          stripeCustomerId
          isBilledOffline
          hasLiveAdverts
          wantedAdvertsNotificationsEnabled
          inAppMessagingNotificationsEnabled
          __typename
        }
        colour {
          id
          label
          updatedAt
          createdAt
          mongoId
          showOn
          slug
          __typename
        }
        gender {
          id
          label
          updatedAt
          mongoId
          showOn
          slug
          createdAt
          __typename
        }
        category {
          id
          label
          updatedAt
          createdAt
          __typename
        }
        type {
          id
          label
          updatedAt
          mongoId
          slug
          createdAt
          __typename
        }
        saleType {
          id
          label
          updatedAt
          mongoId
          slug
          createdAt
          __typename
        }
        addons {
          nextToken
          __typename
        }
        mediaProcessing {
          nextToken
          __typename
        }
        boosts {
          nextToken
          __typename
        }
        featured {
          nextToken
          __typename
        }
        socialPromotions {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchAdverts = /* GraphQL */ `
  query SearchAdverts(
    $filter: SearchableAdvertFilterInput
    $sort: SearchableAdvertSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchAdverts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        age
        breed
        breedId
        colourId
        coords {
          lat
          lon
          __typename
        }
        currency
        description
        disciplines
        exactCoords {
          lat
          lon
          __typename
        }
        formattedLocation
        genderId
        headline
        height
        isFree
        isPOA
        location
        media {
          id
          fileName
          width
          height
          type
          duration
          thumbnail
          status
          blurHash
          __typename
        }
        youTubeVideos {
          id
          title
          description
          __typename
        }
        hasMedia
        price
        saleTypeId
        propertySaleTypeId
        trailerSaleTypeId
        status
        shouldSync
        syncedAt
        typeId
        categoryId
        createdAt
        updatedAt
        deletedAt
        publishedAt
        expiresAt
        expiredAt
        owner
        userSuspended
        soldAt
        contactBy
        mongoId
        slug
        adminLevel1
        ownerStatus
        horseAndHoundMagazine
        weight
        sleeps
        payload
        condition
        numberOfHorses
        modelName
        manufacturer
        propertyTypeId
        numberOfBedrooms
        numberOfBathrooms
        numberOfReceptionRooms
        numberOfStables
        facilities
        living
        affiliatedCompetitionRecord
        annex
        numberOfPaddocks
        salary
        accommodation
        petsAllowed
        horsesAllowed
        ftpt
        stablesTypeId
        priceTypeId
        partner
        hhAdvertId
        magazineHeadline
        magazineDescription
        dam
        damGrandDam
        damGrandDamGreatGrandDam
        damGrandDamGreatGrandSire
        damGrandSire
        damGrandSireGreatGrandDam
        damGrandSireGreatGrandSire
        sire
        sireGrandDam
        sireGrandDamGreatGrandDam
        sireGrandDamGreatGrandSire
        sireGrandSire
        sireGrandSireGreatGrandDam
        sireGrandSireGreatGrandSire
        stallionsName
        studFeeIncludesVat
        studBookOfOrigin
        graded
        gradedWith
        availability
        concessions
        studPrice {
          __typename
        }
        trailerTypeId
        jobTypeId
        liveryTypeId
        stallionName
        sireDamsire
        packageId
        phone
        email
        whatsApp
        ifSoldReminderSent
        expiringSoonReminderSent
        needHelpReminderSent
        expiredButNotSoldReminderSent
        lastChanceToRenewReminderSent
        expiringNextDayReminderSent
        expiredReminderSent
        skippedExpiredReminder
        skippedExpiresSoonReminder
        websiteLink
        extraImagesLimit
        extraVideosLimit
        extraYouTubeVideosLimit
        purchased {
          extraImages
          extraVideos
          extraYouTubeVideos
          websiteLink
          whatsApp
          __typename
        }
        featuredAt
        includeSocialMediaPost
        includeWebsiteLink
        includeBoostOnce
        includeBoostMulti
        includeFeatured
        seller {
          id
          owner
          bio
          firstName
          lastName
          isBusiness
          email
          phone
          location
          formattedLocation
          isAdminLevel1
          adminLevel1
          businessName
          img
          imgStatus
          acceptedTerms
          horseAndHoundMarketing
          acceptedPrivacyPolicy
          allowNewsletter
          allowBlog
          createdAt
          updatedAt
          syncedAt
          mongoId
          resetPasswordToken
          emailVerificationToken
          newEmail
          stripeCustomerId
          isBilledOffline
          hasLiveAdverts
          wantedAdvertsNotificationsEnabled
          inAppMessagingNotificationsEnabled
          __typename
        }
        colour {
          id
          label
          updatedAt
          createdAt
          mongoId
          showOn
          slug
          __typename
        }
        gender {
          id
          label
          updatedAt
          mongoId
          showOn
          slug
          createdAt
          __typename
        }
        category {
          id
          label
          updatedAt
          createdAt
          __typename
        }
        type {
          id
          label
          updatedAt
          mongoId
          slug
          createdAt
          __typename
        }
        saleType {
          id
          label
          updatedAt
          mongoId
          slug
          createdAt
          __typename
        }
        addons {
          nextToken
          __typename
        }
        mediaProcessing {
          nextToken
          __typename
        }
        boosts {
          nextToken
          __typename
        }
        featured {
          nextToken
          __typename
        }
        socialPromotions {
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      total
      __typename
    }
  }
`;
export const getAdvertSnapshot = /* GraphQL */ `
  query GetAdvertSnapshot($id: ID!) {
    getAdvertSnapshot(id: $id) {
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listAdvertSnapshots = /* GraphQL */ `
  query ListAdvertSnapshots(
    $filter: ModelAdvertSnapshotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdvertSnapshots(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAdvertAddon = /* GraphQL */ `
  query GetAdvertAddon($id: ID!) {
    getAdvertAddon(id: $id) {
      id
      whatsApp
      includeSocialMediaPost
      includeWebsiteLink
      includeWhatsApp
      websiteLink
      includeBoostOnce
      includeBoostMulti
      includeFeatured
      extraImages
      extraVideos
      extraYouTubeVideos
      method
      purchasedAt
      transactionId
      advertId
      createdAt
      updatedAt
      deletedAt
      owner
      __typename
    }
  }
`;
export const listAdvertAddons = /* GraphQL */ `
  query ListAdvertAddons(
    $filter: ModelAdvertAddonFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdvertAddons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        whatsApp
        includeSocialMediaPost
        includeWebsiteLink
        includeWhatsApp
        websiteLink
        includeBoostOnce
        includeBoostMulti
        includeFeatured
        extraImages
        extraVideos
        extraYouTubeVideos
        method
        purchasedAt
        transactionId
        advertId
        createdAt
        updatedAt
        deletedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWantedAdvert = /* GraphQL */ `
  query GetWantedAdvert($id: ID!) {
    getWantedAdvert(id: $id) {
      id
      headline
      description
      owner
      distance
      location
      formattedLocation
      isAdminLevel1
      adminLevel1
      miles
      ageMin
      ageMax
      heightMin
      heightMax
      priceMin
      priceMax
      saleTypeId
      propertySaleTypeId
      trailerSaleTypeId
      status
      typeId
      categoryId
      coords {
        lat
        lon
        __typename
      }
      exactCoords {
        lat
        lon
        __typename
      }
      isPOA
      disciplines
      createdAt
      updatedAt
      deletedAt
      syncedAt
      ownerProfile {
        id
        owner
        bio
        firstName
        lastName
        isBusiness
        coords {
          lat
          lon
          __typename
        }
        email
        phone
        location
        formattedLocation
        isAdminLevel1
        adminLevel1
        businessName
        exactCoords {
          lat
          lon
          __typename
        }
        img
        imgStatus
        acceptedTerms
        horseAndHoundMarketing
        acceptedPrivacyPolicy
        allowNewsletter
        allowBlog
        createdAt
        updatedAt
        syncedAt
        mongoId
        resetPasswordToken
        emailVerificationToken
        newEmail
        stripeCustomerId
        isBilledOffline
        hasLiveAdverts
        wantedAdvertsNotificationsEnabled
        inAppMessagingNotificationsEnabled
        __typename
      }
      category {
        id
        label
        updatedAt
        createdAt
        __typename
      }
      type {
        id
        label
        updatedAt
        mongoId
        slug
        createdAt
        __typename
      }
      saleType {
        id
        label
        updatedAt
        mongoId
        slug
        createdAt
        __typename
      }
      __typename
    }
  }
`;
export const listWantedAdverts = /* GraphQL */ `
  query ListWantedAdverts(
    $filter: ModelWantedAdvertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWantedAdverts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        headline
        description
        owner
        distance
        location
        formattedLocation
        isAdminLevel1
        adminLevel1
        miles
        ageMin
        ageMax
        heightMin
        heightMax
        priceMin
        priceMax
        saleTypeId
        propertySaleTypeId
        trailerSaleTypeId
        status
        typeId
        categoryId
        coords {
          lat
          lon
          __typename
        }
        exactCoords {
          lat
          lon
          __typename
        }
        isPOA
        disciplines
        createdAt
        updatedAt
        deletedAt
        syncedAt
        ownerProfile {
          id
          owner
          bio
          firstName
          lastName
          isBusiness
          email
          phone
          location
          formattedLocation
          isAdminLevel1
          adminLevel1
          businessName
          img
          imgStatus
          acceptedTerms
          horseAndHoundMarketing
          acceptedPrivacyPolicy
          allowNewsletter
          allowBlog
          createdAt
          updatedAt
          syncedAt
          mongoId
          resetPasswordToken
          emailVerificationToken
          newEmail
          stripeCustomerId
          isBilledOffline
          hasLiveAdverts
          wantedAdvertsNotificationsEnabled
          inAppMessagingNotificationsEnabled
          __typename
        }
        category {
          id
          label
          updatedAt
          createdAt
          __typename
        }
        type {
          id
          label
          updatedAt
          mongoId
          slug
          createdAt
          __typename
        }
        saleType {
          id
          label
          updatedAt
          mongoId
          slug
          createdAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const wantedAdvertsForOwner = /* GraphQL */ `
  query WantedAdvertsForOwner(
    $owner: ID
    $syncedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWantedAdvertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    wantedAdvertsForOwner(
      owner: $owner
      syncedAt: $syncedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        headline
        description
        owner
        distance
        location
        formattedLocation
        isAdminLevel1
        adminLevel1
        miles
        ageMin
        ageMax
        heightMin
        heightMax
        priceMin
        priceMax
        saleTypeId
        propertySaleTypeId
        trailerSaleTypeId
        status
        typeId
        categoryId
        coords {
          lat
          lon
          __typename
        }
        exactCoords {
          lat
          lon
          __typename
        }
        isPOA
        disciplines
        createdAt
        updatedAt
        deletedAt
        syncedAt
        ownerProfile {
          id
          owner
          bio
          firstName
          lastName
          isBusiness
          email
          phone
          location
          formattedLocation
          isAdminLevel1
          adminLevel1
          businessName
          img
          imgStatus
          acceptedTerms
          horseAndHoundMarketing
          acceptedPrivacyPolicy
          allowNewsletter
          allowBlog
          createdAt
          updatedAt
          syncedAt
          mongoId
          resetPasswordToken
          emailVerificationToken
          newEmail
          stripeCustomerId
          isBilledOffline
          hasLiveAdverts
          wantedAdvertsNotificationsEnabled
          inAppMessagingNotificationsEnabled
          __typename
        }
        category {
          id
          label
          updatedAt
          createdAt
          __typename
        }
        type {
          id
          label
          updatedAt
          mongoId
          slug
          createdAt
          __typename
        }
        saleType {
          id
          label
          updatedAt
          mongoId
          slug
          createdAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const wantedAdvertsWithStatus = /* GraphQL */ `
  query WantedAdvertsWithStatus(
    $status: Int
    $syncedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWantedAdvertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    wantedAdvertsWithStatus(
      status: $status
      syncedAt: $syncedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        headline
        description
        owner
        distance
        location
        formattedLocation
        isAdminLevel1
        adminLevel1
        miles
        ageMin
        ageMax
        heightMin
        heightMax
        priceMin
        priceMax
        saleTypeId
        propertySaleTypeId
        trailerSaleTypeId
        status
        typeId
        categoryId
        coords {
          lat
          lon
          __typename
        }
        exactCoords {
          lat
          lon
          __typename
        }
        isPOA
        disciplines
        createdAt
        updatedAt
        deletedAt
        syncedAt
        ownerProfile {
          id
          owner
          bio
          firstName
          lastName
          isBusiness
          email
          phone
          location
          formattedLocation
          isAdminLevel1
          adminLevel1
          businessName
          img
          imgStatus
          acceptedTerms
          horseAndHoundMarketing
          acceptedPrivacyPolicy
          allowNewsletter
          allowBlog
          createdAt
          updatedAt
          syncedAt
          mongoId
          resetPasswordToken
          emailVerificationToken
          newEmail
          stripeCustomerId
          isBilledOffline
          hasLiveAdverts
          wantedAdvertsNotificationsEnabled
          inAppMessagingNotificationsEnabled
          __typename
        }
        category {
          id
          label
          updatedAt
          createdAt
          __typename
        }
        type {
          id
          label
          updatedAt
          mongoId
          slug
          createdAt
          __typename
        }
        saleType {
          id
          label
          updatedAt
          mongoId
          slug
          createdAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const searchWantedAdverts = /* GraphQL */ `
  query SearchWantedAdverts(
    $filter: SearchableWantedAdvertFilterInput
    $sort: SearchableWantedAdvertSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchWantedAdverts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        headline
        description
        owner
        distance
        location
        formattedLocation
        isAdminLevel1
        adminLevel1
        miles
        ageMin
        ageMax
        heightMin
        heightMax
        priceMin
        priceMax
        saleTypeId
        propertySaleTypeId
        trailerSaleTypeId
        status
        typeId
        categoryId
        coords {
          lat
          lon
          __typename
        }
        exactCoords {
          lat
          lon
          __typename
        }
        isPOA
        disciplines
        createdAt
        updatedAt
        deletedAt
        syncedAt
        ownerProfile {
          id
          owner
          bio
          firstName
          lastName
          isBusiness
          email
          phone
          location
          formattedLocation
          isAdminLevel1
          adminLevel1
          businessName
          img
          imgStatus
          acceptedTerms
          horseAndHoundMarketing
          acceptedPrivacyPolicy
          allowNewsletter
          allowBlog
          createdAt
          updatedAt
          syncedAt
          mongoId
          resetPasswordToken
          emailVerificationToken
          newEmail
          stripeCustomerId
          isBilledOffline
          hasLiveAdverts
          wantedAdvertsNotificationsEnabled
          inAppMessagingNotificationsEnabled
          __typename
        }
        category {
          id
          label
          updatedAt
          createdAt
          __typename
        }
        type {
          id
          label
          updatedAt
          mongoId
          slug
          createdAt
          __typename
        }
        saleType {
          id
          label
          updatedAt
          mongoId
          slug
          createdAt
          __typename
        }
        __typename
      }
      nextToken
      total
      __typename
    }
  }
`;
export const getTransaction = /* GraphQL */ `
  query GetTransaction($id: ID!) {
    getTransaction(id: $id) {
      id
      advertId
      owner
      sessionId
      paymentStatus
      updatedAt
      deletedAt
      productId
      transactionId
      transactionDate
      transactionReceipt
      purchaseToken
      dataAndroid
      signatureAndroid
      autoRenewingAndroid
      purchaseStateAndroid
      originalTransactionDateIOS
      originalTransactionIdentifierIOS
      isAcknowledgedAndroid
      receipt
      price
      createdAt
      syncedAt
      advert {
        id
        age
        breed
        breedId
        colourId
        coords {
          lat
          lon
          __typename
        }
        currency
        description
        disciplines
        exactCoords {
          lat
          lon
          __typename
        }
        formattedLocation
        genderId
        headline
        height
        isFree
        isPOA
        location
        media {
          id
          fileName
          width
          height
          type
          duration
          thumbnail
          status
          blurHash
          __typename
        }
        youTubeVideos {
          id
          title
          description
          __typename
        }
        hasMedia
        price
        saleTypeId
        propertySaleTypeId
        trailerSaleTypeId
        status
        shouldSync
        syncedAt
        typeId
        categoryId
        createdAt
        updatedAt
        deletedAt
        publishedAt
        expiresAt
        expiredAt
        owner
        userSuspended
        soldAt
        contactBy
        mongoId
        slug
        adminLevel1
        ownerStatus
        horseAndHoundMagazine
        weight
        sleeps
        payload
        condition
        numberOfHorses
        modelName
        manufacturer
        propertyTypeId
        numberOfBedrooms
        numberOfBathrooms
        numberOfReceptionRooms
        numberOfStables
        facilities
        living
        affiliatedCompetitionRecord
        annex
        numberOfPaddocks
        salary
        accommodation
        petsAllowed
        horsesAllowed
        ftpt
        stablesTypeId
        priceTypeId
        partner
        hhAdvertId
        magazineHeadline
        magazineDescription
        dam
        damGrandDam
        damGrandDamGreatGrandDam
        damGrandDamGreatGrandSire
        damGrandSire
        damGrandSireGreatGrandDam
        damGrandSireGreatGrandSire
        sire
        sireGrandDam
        sireGrandDamGreatGrandDam
        sireGrandDamGreatGrandSire
        sireGrandSire
        sireGrandSireGreatGrandDam
        sireGrandSireGreatGrandSire
        stallionsName
        studFeeIncludesVat
        studBookOfOrigin
        graded
        gradedWith
        availability
        concessions
        studPrice {
          __typename
        }
        trailerTypeId
        jobTypeId
        liveryTypeId
        stallionName
        sireDamsire
        packageId
        phone
        email
        whatsApp
        ifSoldReminderSent
        expiringSoonReminderSent
        needHelpReminderSent
        expiredButNotSoldReminderSent
        lastChanceToRenewReminderSent
        expiringNextDayReminderSent
        expiredReminderSent
        skippedExpiredReminder
        skippedExpiresSoonReminder
        websiteLink
        extraImagesLimit
        extraVideosLimit
        extraYouTubeVideosLimit
        purchased {
          extraImages
          extraVideos
          extraYouTubeVideos
          websiteLink
          whatsApp
          __typename
        }
        featuredAt
        includeSocialMediaPost
        includeWebsiteLink
        includeBoostOnce
        includeBoostMulti
        includeFeatured
        seller {
          id
          owner
          bio
          firstName
          lastName
          isBusiness
          email
          phone
          location
          formattedLocation
          isAdminLevel1
          adminLevel1
          businessName
          img
          imgStatus
          acceptedTerms
          horseAndHoundMarketing
          acceptedPrivacyPolicy
          allowNewsletter
          allowBlog
          createdAt
          updatedAt
          syncedAt
          mongoId
          resetPasswordToken
          emailVerificationToken
          newEmail
          stripeCustomerId
          isBilledOffline
          hasLiveAdverts
          wantedAdvertsNotificationsEnabled
          inAppMessagingNotificationsEnabled
          __typename
        }
        colour {
          id
          label
          updatedAt
          createdAt
          mongoId
          showOn
          slug
          __typename
        }
        gender {
          id
          label
          updatedAt
          mongoId
          showOn
          slug
          createdAt
          __typename
        }
        category {
          id
          label
          updatedAt
          createdAt
          __typename
        }
        type {
          id
          label
          updatedAt
          mongoId
          slug
          createdAt
          __typename
        }
        saleType {
          id
          label
          updatedAt
          mongoId
          slug
          createdAt
          __typename
        }
        addons {
          nextToken
          __typename
        }
        mediaProcessing {
          nextToken
          __typename
        }
        boosts {
          nextToken
          __typename
        }
        featured {
          nextToken
          __typename
        }
        socialPromotions {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const listTransactions = /* GraphQL */ `
  query ListTransactions(
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        advertId
        owner
        sessionId
        paymentStatus
        updatedAt
        deletedAt
        productId
        transactionId
        transactionDate
        transactionReceipt
        purchaseToken
        dataAndroid
        signatureAndroid
        autoRenewingAndroid
        purchaseStateAndroid
        originalTransactionDateIOS
        originalTransactionIdentifierIOS
        isAcknowledgedAndroid
        receipt
        price
        createdAt
        syncedAt
        advert {
          id
          age
          breed
          breedId
          colourId
          currency
          description
          disciplines
          formattedLocation
          genderId
          headline
          height
          isFree
          isPOA
          location
          hasMedia
          price
          saleTypeId
          propertySaleTypeId
          trailerSaleTypeId
          status
          shouldSync
          syncedAt
          typeId
          categoryId
          createdAt
          updatedAt
          deletedAt
          publishedAt
          expiresAt
          expiredAt
          owner
          userSuspended
          soldAt
          contactBy
          mongoId
          slug
          adminLevel1
          ownerStatus
          horseAndHoundMagazine
          weight
          sleeps
          payload
          condition
          numberOfHorses
          modelName
          manufacturer
          propertyTypeId
          numberOfBedrooms
          numberOfBathrooms
          numberOfReceptionRooms
          numberOfStables
          facilities
          living
          affiliatedCompetitionRecord
          annex
          numberOfPaddocks
          salary
          accommodation
          petsAllowed
          horsesAllowed
          ftpt
          stablesTypeId
          priceTypeId
          partner
          hhAdvertId
          magazineHeadline
          magazineDescription
          dam
          damGrandDam
          damGrandDamGreatGrandDam
          damGrandDamGreatGrandSire
          damGrandSire
          damGrandSireGreatGrandDam
          damGrandSireGreatGrandSire
          sire
          sireGrandDam
          sireGrandDamGreatGrandDam
          sireGrandDamGreatGrandSire
          sireGrandSire
          sireGrandSireGreatGrandDam
          sireGrandSireGreatGrandSire
          stallionsName
          studFeeIncludesVat
          studBookOfOrigin
          graded
          gradedWith
          availability
          concessions
          trailerTypeId
          jobTypeId
          liveryTypeId
          stallionName
          sireDamsire
          packageId
          phone
          email
          whatsApp
          ifSoldReminderSent
          expiringSoonReminderSent
          needHelpReminderSent
          expiredButNotSoldReminderSent
          lastChanceToRenewReminderSent
          expiringNextDayReminderSent
          expiredReminderSent
          skippedExpiredReminder
          skippedExpiresSoonReminder
          websiteLink
          extraImagesLimit
          extraVideosLimit
          extraYouTubeVideosLimit
          featuredAt
          includeSocialMediaPost
          includeWebsiteLink
          includeBoostOnce
          includeBoostMulti
          includeFeatured
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getLandingPage = /* GraphQL */ `
  query GetLandingPage($id: ID!) {
    getLandingPage(id: $id) {
      id
      owner
      headerTitle
      headerParagraph
      headerButtonLabel
      footerTitle
      footerParagraph
      footerButtonLabel
      slug
      location
      formattedLocation
      isAdminLevel1
      miles
      ageMin
      ageMax
      heightMin
      heightMax
      priceMin
      priceMax
      saleTypeId
      propertySaleTypeId
      trailerSaleTypeId
      typeId
      isPOA
      coords {
        lat
        lon
        __typename
      }
      exactCoords {
        lat
        lon
        __typename
      }
      disciplines
      updatedAt
      deletedAt
      createdAt
      __typename
    }
  }
`;
export const listLandingPages = /* GraphQL */ `
  query ListLandingPages(
    $filter: ModelLandingPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLandingPages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        headerTitle
        headerParagraph
        headerButtonLabel
        footerTitle
        footerParagraph
        footerButtonLabel
        slug
        location
        formattedLocation
        isAdminLevel1
        miles
        ageMin
        ageMax
        heightMin
        heightMax
        priceMin
        priceMax
        saleTypeId
        propertySaleTypeId
        trailerSaleTypeId
        typeId
        isPOA
        coords {
          lat
          lon
          __typename
        }
        exactCoords {
          lat
          lon
          __typename
        }
        disciplines
        updatedAt
        deletedAt
        createdAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTransactionPending = /* GraphQL */ `
  query GetTransactionPending($id: ID!) {
    getTransactionPending(id: $id) {
      id
      owner
      advertId
      price
      createdAt
      updatedAt
      deletedAt
      syncedAt
      __typename
    }
  }
`;
export const listTransactionPendings = /* GraphQL */ `
  query ListTransactionPendings(
    $filter: ModelTransactionPendingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTransactionPendings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        advertId
        price
        createdAt
        updatedAt
        deletedAt
        syncedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMigrationToken = /* GraphQL */ `
  query GetMigrationToken($id: ID!) {
    getMigrationToken(id: $id) {
      id
      owner
      email
      newUserId
      migrationMethod
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMigrationTokens = /* GraphQL */ `
  query ListMigrationTokens(
    $filter: ModelMigrationTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMigrationTokens(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        email
        newUserId
        migrationMethod
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMediaProcessing = /* GraphQL */ `
  query GetMediaProcessing($id: ID!) {
    getMediaProcessing(id: $id) {
      id
      owner
      advertId
      type
      error
      status
      progress
      jobId
      sizes {
        width
        label
        scale
        isScaled
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMediaProcessings = /* GraphQL */ `
  query ListMediaProcessings(
    $filter: ModelMediaProcessingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMediaProcessings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        advertId
        type
        error
        status
        progress
        jobId
        sizes {
          width
          label
          scale
          isScaled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const mediaProcessingByJobId = /* GraphQL */ `
  query MediaProcessingByJobId(
    $jobId: String
    $sortDirection: ModelSortDirection
    $filter: ModelMediaProcessingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    mediaProcessingByJobId(
      jobId: $jobId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        advertId
        type
        error
        status
        progress
        jobId
        sizes {
          width
          label
          scale
          isScaled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const mediaProcessingByAdvertId = /* GraphQL */ `
  query MediaProcessingByAdvertId(
    $advertId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMediaProcessingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    mediaProcessingByAdvertId(
      advertId: $advertId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        advertId
        type
        error
        status
        progress
        jobId
        sizes {
          width
          label
          scale
          isScaled
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAdvertBoost = /* GraphQL */ `
  query GetAdvertBoost($id: ID!) {
    getAdvertBoost(id: $id) {
      id
      date
      type
      status
      advertId
      createdAt
      updatedAt
      deletedAt
      owner
      __typename
    }
  }
`;
export const listAdvertBoosts = /* GraphQL */ `
  query ListAdvertBoosts(
    $filter: ModelAdvertBoostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdvertBoosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        date
        type
        status
        advertId
        createdAt
        updatedAt
        deletedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAdvertFeatured = /* GraphQL */ `
  query GetAdvertFeatured($id: ID!) {
    getAdvertFeatured(id: $id) {
      id
      startAt
      endAt
      status
      advertId
      createdAt
      updatedAt
      deletedAt
      owner
      __typename
    }
  }
`;
export const listAdvertFeatureds = /* GraphQL */ `
  query ListAdvertFeatureds(
    $filter: ModelAdvertFeaturedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdvertFeatureds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        startAt
        endAt
        status
        advertId
        createdAt
        updatedAt
        deletedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAdvertSocialPromotion = /* GraphQL */ `
  query GetAdvertSocialPromotion($id: ID!) {
    getAdvertSocialPromotion(id: $id) {
      id
      requestedAt
      advertId
      createdAt
      updatedAt
      deletedAt
      owner
      __typename
    }
  }
`;
export const listAdvertSocialPromotions = /* GraphQL */ `
  query ListAdvertSocialPromotions(
    $filter: ModelAdvertSocialPromotionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdvertSocialPromotions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        requestedAt
        advertId
        createdAt
        updatedAt
        deletedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
