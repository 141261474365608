/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:d4a36f98-ece4-4cb7-8754-746878fd9079",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_LUwlvrsV3",
    "aws_user_pools_web_client_id": "5m0h88k2bqhjobkrbek8larqdm",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://hd55dly43vcebpbx7vbbb5etym.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-4lcwftpz3fewdkcvfhwonprkju",
    "aws_cloud_logic_custom": [
        {
            "name": "WhickrRest",
            "endpoint": "https://lki2ltz94i.execute-api.eu-west-2.amazonaws.com/production",
            "region": "eu-west-2"
        },
        {
            "name": "SSR",
            "endpoint": "https://qvs2wk121f.execute-api.eu-west-2.amazonaws.com/production",
            "region": "eu-west-2"
        },
        {
            "name": "AdminQueries",
            "endpoint": "https://hunm723x3i.execute-api.eu-west-2.amazonaws.com/production",
            "region": "eu-west-2"
        }
    ],
    "aws_user_files_s3_bucket": "whickr-cdn130507-production",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;
