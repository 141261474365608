import './App.less';
import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import Router from './routes';
import * as serviceWorker from './serviceWorker';
import store from './config/store';
import device from './utils/device';

const appVersion = require('../package.json').version;

const newStore = store.init(window.__INITIAL_STATE__);
device.isMobile = window?.initialProps?.isMobile;

Bugsnag.start({
  apiKey: '0b65c309b22948dae6350a573558bbe0',
  plugins: [new BugsnagPluginReact()],
  appVersion,
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

ReactDOM.hydrate(
  <ErrorBoundary>
    <Provider store={newStore}>
      <BrowserRouter>
        <Router store={store.store} />
      </BrowserRouter>
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
