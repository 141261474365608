import React, { ReactElement } from 'react';
import './styles.less';

/**
 * Description
 */

type Props = {
  children: React.ReactNode;
  padding?: number;
  noBorderRadius?: boolean;
  noShadow?: boolean;
  style?: CSSProperties;
};
const defaultProps = { padding: 0, noBorderRadius: false, noShadow: false, style: {} };

const Card: React.FC<Props> = ({
  children,
  padding,
  noBorderRadius,
  noShadow,
  style,
}: Props): ReactElement => {
  let borderRadius;
  let boxShadow;
  if (noBorderRadius) {
    borderRadius = 0;
  }

  if (noShadow) {
    boxShadow = 'unset';
  }

  return (
    <div className="cardContainer" style={{ boxShadow, borderRadius, padding, ...style }}>
      {children}
    </div>
  );
};

Card.defaultProps = defaultProps;
export default Card;
