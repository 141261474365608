import React from 'react';
import {
  liveryFacilities,
  propertyFacilities,
  trailerFacilities,
  jobSubCategories,
  liveryYardSubCategories,
  horseTypeOptions,
  livingOptions,
  conditionOptions,
  propertyTypeOptions,
  ftptOptions,
  typeOfStablesOptions,
  saleTypeOptions,
  trailerWeights,
  disciplinesOptions,
  colourOptions,
  propertySaleTypeOptions,
  trailerSaleTypeOptions,
  trailerTypeOptions,
  genderOptions,
} from '../../utils/options';
import { priceFormatter } from '../../utils/formHelps';

type Option = {
  label: string;
  value: string;
};

type RangeProps = {
  formatter: (value: string) => string;
  maxLength: number;
  inputMode: string;
};

type FilterItem = {
  type: string;
  label: string;
  key: string;
  initialValue?: string;
  options?: Option[];
  selectProps?: any;
  labelExtra?: JSX.Element;
  minProps?: RangeProps;
  maxProps?: RangeProps;
};

type Filters = {
  horse: FilterItem[];
  trailer: FilterItem[];
  property: FilterItem[];
  job: FilterItem[];
  dog: FilterItem[];
  cat: FilterItem[];
  // liveryYard: FilterItem[];
  // stallionsAtStud: FilterItem[];
  // misc: FilterItem[];
};

const jobSubCategoryFilter = {
  type: 'select',
  label: 'Type',
  key: 'jobTypeId',
  selectProps: {
    mode: 'multiple',
    allowClear: true,
  },
  options: jobSubCategories,
};

const liveryYardSubCategoryFilter = {
  type: 'select',
  label: 'Type',
  key: 'liveryTypeId',
  selectProps: {
    mode: 'multiple',
    allowClear: true,
  },
  options: liveryYardSubCategories,
};

const saleTypeFilter = {
  type: 'radio',
  label: 'Sale Type',
  key: 'saleTypeId',
  options: saleTypeOptions,
};

const propertySaleTypeFilter = {
  type: 'radio',
  label: 'Sale Type',
  key: 'propertySaleTypeId',
  options: propertySaleTypeOptions,
};
const trailerSaleTypeFilter = {
  type: 'radio',
  label: 'Sale Type',
  key: 'trailerSaleTypeId',
  options: trailerSaleTypeOptions,
};

const locationFilter = {
  type: 'location',
  label: 'Location',
  key: 'searchLocation',
};

const ageFilter = {
  type: 'range',
  label: 'Age',
  key: 'age',
  labelExtra: <span style={{ color: '#999' }}>(years)</span>,
};

const heightFilter = {
  type: 'range',
  label: 'Height',
  key: 'height',
};

const hasMediaVideoFilter = {
  type: 'checkbox',
  label: 'With Media Only',
  key: 'hasMedia',
};

const disciplinesFilter = {
  type: 'select',
  label: 'Disciplines',
  key: 'disciplines',
  selectProps: {
    mode: 'multiple',
    allowClear: true,
  },
  options: disciplinesOptions,
};

const colourFilter = {
  type: 'select',
  label: 'Colour',
  key: 'colourId',
  selectProps: {
    mode: 'multiple',
    allowClear: true,
  },
  options: colourOptions,
};

const genderFilter = {
  type: 'select',
  label: 'Gender',
  key: 'genderId',
  selectProps: {
    mode: 'multiple',
    allowClear: true,
  },
  options: genderOptions,
};

const horseTypeFilter = {
  type: 'select',
  label: 'Horse Type',
  key: 'typeId',
  selectProps: {
    mode: 'multiple',
    allowClear: true,
  },
  options: horseTypeOptions,
};

const trailerTypeFilter = {
  type: 'select',
  label: 'Trailer Type',
  key: 'trailerTypeId',
  selectProps: {
    mode: 'multiple',
    allowClear: true,
  },
  options: trailerTypeOptions,
};

const budgetFilter = {
  type: 'range',
  label: 'Budget',
  key: 'price',
  labelExtra: <span style={{ color: '#999' }}>(£)</span>,
  minProps: {
    formatter: priceFormatter,
    maxLength: 14,
    inputMode: 'decimal',
  },
  maxProps: {
    formatter: priceFormatter,
    maxLength: 14,
    inputMode: 'decimal',
  },
};

const poaFilter = {
  type: 'checkbox',
  label: 'Exclude POA',
  key: 'excludePOA',
};

const weightFilter = {
  type: 'select',
  label: 'Weight',
  key: 'weight',
  options: trailerWeights,
};
const sleepsFilter = {
  type: 'range',
  label: 'Sleeps',
  key: 'sleeps',
};
const payloadFilter = {
  type: 'range',
  label: 'Payload',
  key: 'payload',
};
const numberOfHorsesFilter = {
  type: 'range',
  label: 'No of Horses',
  key: 'numberOfHorses',
};

const livingFilter = {
  type: 'select',
  label: 'Living',
  key: 'living',
  options: livingOptions,
};

const conditionFilter = {
  type: 'select',
  label: 'Condition',
  key: 'condition',
  options: conditionOptions,
};

const baseFacilitiesProps = {
  type: 'select',
  label: 'Facilities',
  selectProps: {
    mode: 'multiple',
    allowClear: true,
  },
};

const trailerFacilitiesFilter = {
  ...baseFacilitiesProps,
  key: 'trailerFacilities',
  options: trailerFacilities,
};

const propertyFacilitiesFilter = {
  ...baseFacilitiesProps,
  key: 'propertyFacilities',
  options: propertyFacilities,
};

const liveryFacilitiesFilter = {
  ...baseFacilitiesProps,
  key: 'liveryFacilities',
  options: liveryFacilities,
};

const propertyTypeFilter = {
  type: 'select',
  label: 'Property Type',
  key: 'propertyTypeId',
  options: propertyTypeOptions,
};

const numberOfBedroomsFilter = {
  type: 'range',
  label: 'No of Bedrooms',
  key: 'numberOfBedrooms',
};

const numberOfBathroomsFilter = {
  type: 'range',
  label: 'No of Bathrooms',
  key: 'numberOfBathrooms',
};
const numberOfReceptionRoomsFilter = {
  type: 'range',
  label: 'No of Reception Rooms',
  key: 'numberOfReceptionRooms',
};

const annexFilter = {
  type: 'checkbox',
  label: 'Annex',
  key: 'annex',
};
const numberOfStablesFilter = {
  type: 'range',
  label: 'No of Stables',
  key: 'numberOfStables',
};
const numberOfPaddocksFilter = {
  type: 'range',
  label: 'No of Paddocks',
  key: 'numberOfPaddocks',
};

const salaryFilter = {
  type: 'range',
  label: 'Salary',
  key: 'salary',
  labelExtra: <span style={{ color: '#999' }}>(£)</span>,
  minProps: {
    formatter: priceFormatter,
    maxLength: 14,
    inputMode: 'decimal',
  },
  maxProps: {
    formatter: priceFormatter,
    maxLength: 14,
    inputMode: 'decimal',
  },
};
const accommodationFilter = {
  type: 'checkbox',
  label: 'Accommodation',
  key: 'accommodation',
};
const pertsAllowedFilter = {
  type: 'checkbox',
  label: 'Pets Allowed',
  key: 'petsAllowed',
};
const horseAllowedFilter = {
  type: 'checkbox',
  label: 'Horse Allowed',
  key: 'horsesAllowed',
};
const ftptFilter = {
  type: 'select',
  label: 'FT / PT',
  key: 'ftpt',
  options: ftptOptions,
};

const typeOfStablesFilter = {
  type: 'select',
  label: 'Type of Stables',
  key: 'stablesTypeId',
  options: typeOfStablesOptions,
};

const filters: Filters = {
  horse: [
    locationFilter,
    saleTypeFilter,
    ageFilter,
    heightFilter,
    hasMediaVideoFilter,
    horseTypeFilter,
    colourFilter,
    genderFilter,
    budgetFilter,
    poaFilter,
    disciplinesFilter,
  ],
  trailer: [
    locationFilter,
    trailerTypeFilter,
    trailerSaleTypeFilter,
    budgetFilter,
    weightFilter,
    sleepsFilter,
    livingFilter,
    trailerFacilitiesFilter,
    payloadFilter,
    conditionFilter,
    numberOfHorsesFilter,
  ],
  property: [
    locationFilter,
    propertySaleTypeFilter,
    budgetFilter,
    propertyTypeFilter,
    numberOfBedroomsFilter,
    numberOfBathroomsFilter,
    numberOfReceptionRoomsFilter,
    annexFilter,
    numberOfStablesFilter,
    numberOfPaddocksFilter,
    propertyFacilitiesFilter,
  ],
  job: [
    jobSubCategoryFilter,
    locationFilter,
    budgetFilter,
    salaryFilter,
    accommodationFilter,
    pertsAllowedFilter,
    horseAllowedFilter,
    // ftptFilter,
  ],
  dog: [locationFilter, budgetFilter],
  cat: [locationFilter, budgetFilter],
  liveryYard: [
    liveryYardSubCategoryFilter,
    locationFilter,
    budgetFilter,
    numberOfStablesFilter,
    typeOfStablesFilter,
    numberOfPaddocksFilter,
    liveryFacilitiesFilter,
  ],
  stallionsAtStud: [locationFilter, budgetFilter],
  misc: [locationFilter],
};

type Values = any;

type GetFilterItems = (category: Category, values: Values) => FilterItem[];

export type Category = keyof Filters;

const getFilterItems: GetFilterItems = (category, values) => {
  return filters[category];
};

export default getFilterItems;
