import { Select, Menu, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import React, { ReactElement, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Head from '../../components/Head';

import './styles.less';
import Card from '../../components/Card';

const ContactUs: React.FC = (props): ReactElement => {
  const history = useHistory();

  const locationRef = useRef(null);

  const [category, setCategory] = useState('horse.sale');

  let backLink = `${process.env.REACT_APP_CLASSIFIEDS_URL}`;
  if (typeof window !== 'undefined') {
    backLink = encodeURIComponent(window?.location?.href);
  }
  return (
    <div>
      <Head
        title="Contact Us - Horse &amp; Hound"
        description="Find hundreds of horses for sale and ponies for sale suit your requirements in Horse &amp; Hound’s equestrian classifieds marketplace. Also horseboxes for sale, equestrian jobs and equestrian property for sale."
      />
      <section>
        <div className="container content">
          <h2 className="heading">Contact Us</h2>
          <p className="howToParagraph">
            For help placing your advert please call our customer service team on{' '}
            <strong>07399535683</strong>
            <br />
            <em>
              (note this number is not manned 24 hours, please leave a message and our
              customer service team will call you back)
            </em>
            <br />
            or email us on{' '}
            <a style={{ color: '#ee5827' }} href="mailto:tackroom@whickr.com">
              <strong>tackroom@whickr.com</strong>
            </a>
          </p>
        </div>
      </section>
    </div>
  );
};

// ContactUs.fetchData = async (ssrStore, match, req) => {
//   const SSR = withSSRContext({ req });
//   const searchParamsInUrl = qs.parse('?sort=publishedAt.desc&page=1', {
//     ignoreQueryPrefix: true,
//   });

//   const { apiQuery } = getSearchOptions(searchParamsInUrl);
//   const advertItems = await getAdverts({ ...apiQuery, limit: 6, amp: SSR });
//   console.log('advertItems', advertItems);
//   ssrStore.dispatch(updateLanding({ adverts: advertItems }));
// };

export default ContactUs;
