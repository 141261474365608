import React from 'react';
import Helmet from 'react-helmet';
import { useLocation } from 'react-router';

const Head = ({
  title = 'Horse & Hound Classifieds',
  description = 'Horses for Sale and Ponies for Sale, plus Horseboxes, Trailers, Property and Jobs.',
  ogTitle = title,
  ogDescription = description,
  ogImage = 'https://classifieds.horseandhound.co.uk/img/logoWithBackground.png',
  ogImageSecure,
}) => {
  const location = useLocation();

  const pathName = location.pathname;

  return (
    <Helmet>
      <meta charset="utf-8" />
      <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#00623e" />
      <link
        rel="canonical"
        href={`https://classifieds.horseandhound.co.uk${pathName}/`}
      />

      <link rel="manifest" href="https://classifieds.horseandhound.co.uk/manifest.json" />

      <title>{title}</title>

      <meta name="description" content={description} />

      <meta property="og:site_name" content="Horse & Hound Classifieds" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={ogTitle} />
      <meta property="og:description" content={ogDescription} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:image" content={ogImage} />
      {ogImageSecure && <meta property="og:image:secure_url" content={ogImageSecure} />}
      <meta property="og:image:alt" content={ogTitle} />

      <meta
        property="og:url"
        content={`https://classifieds.horseandhound.co.uk${location.pathname}`}
      />
    </Helmet>
  );
};

export default Head;
