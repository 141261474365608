const gpbFormatter = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GBP',
  maximumFractionDigits: 0,
});
const eurFormatter = new Intl.NumberFormat('sfb', {
  style: 'currency',
  currency: 'EUR',
  maximumFractionDigits: 0,
});

export const formatCurrency = (price, currency = 'gbp') => {
  let priceValue = gpbFormatter.format(price); /* $2,500.00 */

  if (currency === 'eur') {
    priceValue = eurFormatter.format(price);
  }
  return priceValue;
};
