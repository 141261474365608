import React, { ReactElement } from 'react';
import './styles.less';
import { Button } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

const { REACT_APP_CLASSIFIEDS_URL, REACT_APP_WHICKR_URL } = process.env;
/**
 * Description
 */

const Footer: React.FC = (): ReactElement => {
  let backLink = `${REACT_APP_CLASSIFIEDS_URL}`;
  if (typeof window !== 'undefined') {
    backLink = encodeURIComponent(window?.location?.href);
  }
  return (
    <>
      <div className="footerBackground">
        <div className="container">
          <div className="footerColHolder">
            <div className="footerCol">
              <h3>
                Information
                <br />
                for buyers
              </h3>
              <ul>
                <li>
                  <a
                    href="https://www.horseandhound.co.uk/features/hhs-ultimate-guide-to-buying-a-horse-314473?_gl=1*1ppu23q*_ga*NDE1MjU4MDg5LjE1NDY4NTI0NDQ.*_gid*MjE2OTE5MDYxLjE1NTA0Nzc4NzQ."
                    target="_blank"
                    rel="noreferrer"
                  >
                    Buying advice
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.horseandhound.co.uk/horse-care/buying-selling-advice/avoiding-online-scams-advice-buyers-646244?_gl=1*8ndg3l*_ga*NDE1MjU4MDg5LjE1NDY4NTI0NDQ.*_gid*MjE2OTE5MDYxLjE1NTA0Nzc4NzQ."
                    target="_blank"
                    rel="noreferrer"
                  >
                    Scam advice
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.futureplc.com/terms-conditions/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms &amp; Conditions
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.futureplc.com/privacy-policy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
            <div className="footerCol">
              <h3>
                Information
                <br />
                for sellers
              </h3>
              <ul>
                <li>
                  <a href="/advert-prices">Advert prices</a>
                </li>
                <li>
                  <a
                    href={`${REACT_APP_WHICKR_URL}/create-advert?p=horseandhound&backLink=${backLink}`}
                  >
                    Place an advert
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.horseandhound.co.uk/features/selling-horse-read-first-434377?_gl=1*um6q2g*_ga*NDE1MjU4MDg5LjE1NDY4NTI0NDQ.*_gid*MjE2OTE5MDYxLjE1NTA0Nzc4NzQ."
                    target="_blank"
                    rel="noreferrer"
                  >
                    Selling tips
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.horseandhound.co.uk/horse-care/buying-selling-advice/avoiding-online-scams-advice-sellers-646247?_gl=1*um6q2g*_ga*NDE1MjU4MDg5LjE1NDY4NTI0NDQ.*_gid*MjE2OTE5MDYxLjE1NTA0Nzc4NzQ."
                    target="_blank"
                    rel="noreferrer"
                  >
                    Scam advice
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.horseandhound.co.uk/horse-care/buying-selling-advice/how-to-write-a-horse-for-sale-advert-38565?_gl=1*um6q2g*_ga*NDE1MjU4MDg5LjE1NDY4NTI0NDQ.*_gid*MjE2OTE5MDYxLjE1NTA0Nzc4NzQ."
                    target="_blank"
                    rel="noreferrer"
                  >
                    Writing your advert
                  </a>
                </li>
              </ul>
            </div>
            <div className="footerCol">
              <h3>
                Marketplace
                <br />
                support
              </h3>
              <ul>
                <li>
                  <a href="/how-to-get-the-most-out-of-the-horse-hound-classified-site">
                    Get the most out of the H&amp;H Classified Site
                  </a>
                </li>
                <li>
                  <a href="/help-faq">Help &amp; FAQ</a>
                </li>
                <li>
                  <a href="/about">About</a>
                </li>
                <li>
                  <a href="/contact-us">Contact us</a>
                </li>
                <li>
                  <a href="/sitemap">Sitemap</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footerBottom">
        <div className="container">
          <div className="footerBottomColHolder">
            <div className="footerBottomCol footerBottomLeft">
              <div className="footerBottomInnerCol">
                <div className="">
                  <img
                    src="/img/old-logo-footer.png"
                    width="200"
                    height="55"
                    className="footerLogo"
                  />
                </div>

                <span className="copyright">
                  © Copyright Future PLC, all rights reserved.
                </span>
              </div>
            </div>
            <div className="footerBottomCol footerBottomRight">
              <div className="footerBottomInnerCol beforeYouGoDesktop">
                <span className="buttonTagline">Before you go...</span>
                <Button
                  // size="large"
                  className="poweredByButton"
                  href={`${REACT_APP_WHICKR_URL}/create-advert?p=horseandhound&backLink=${backLink}`}
                >
                  Place an advert
                </Button>
              </div>
              <div className="footerBottomInnerCol beforeYouGoMobile">
                <a
                  // size="large"
                  className="poweredByButtonMobile"
                  href={`${REACT_APP_WHICKR_URL}/create-advert?p=horseandhound&backLink=${backLink}`}
                >
                  <span> Place an advert</span>
                  <ArrowRightOutlined
                    style={{
                      marginRight: 6,
                    }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
