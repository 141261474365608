import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/performance';

// const firebaseConfig = {
//   apiKey: 'AIzaSyDKbusZF2IExVmnIFZIXnzJOAkjMiDRaeY',
//   authDomain: 'horse-and-hound-classifieds-dv.firebaseapp.com',
//   projectId: 'horse-and-hound-classifieds-dv',
//   storageBucket: 'horse-and-hound-classifieds-dv.appspot.com',
//   messagingSenderId: '667417469122',
//   appId: '1:667417469122:web:1cb907bcc4c2f5cb5e1b5b',
//   measurementId: 'G-H0XRENPEEN',
// };

// if (process.env.REACT_APP_ENV === 'production') {
const firebaseConfig = {
  apiKey: 'AIzaSyAS6WPOnKrVjh2zsDu1PADQZgeGKJObLtw',
  authDomain: 'horse-and-hound-classifieds.firebaseapp.com',
  projectId: 'horse-and-hound-classifieds',
  storageBucket: 'horse-and-hound-classifieds.appspot.com',
  messagingSenderId: '209332343284',
  appId: '1:209332343284:web:b037c0dbbd34da60e569a1',
  measurementId: 'G-2D2SYR0LH1',
};
// }

let analytics = {
  onSetUserId: (userId: string): void => {},

  onSignIn: (userId: string): void => {},

  onTrackScreen: (page_title: string, page_path: string): void => {},

  onSignOut: (): void => null,

  onEvent: (
    name: string,
    data?: { [key: string]: string | number | boolean }
  ): void => {},
};

if (typeof document !== 'undefined') {
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
  firebase.performance();

  analytics = {
    onSetUserId: (userId: string): void => {
      return firebase.analytics().setUserId(userId);
    },

    onSignIn: (userId: string): void => {
      analytics.onSetUserId(userId);
      analytics.onEvent('login', { method: 'email' }); // need to check with rudge
    },

    onTrackScreen: (page_title: string, page_path: string): void => {
      firebase.analytics().setCurrentScreen(page_path);
      firebase.analytics().logEvent('page_view', { page_title, page_path });
    },

    onSignOut: (): void => firebase.analytics().setUserId(null),

    onEvent: (
      name: string,
      data?: { [key: string]: string | number | boolean }
    ): void => {
      const transformedEvent: { [key: string]: string | number | boolean } = {};
      if (data) {
        Object.keys(data).forEach((key) => {
          if (typeof data[key] === 'string') {
            const stringValue = data[key] as string;
            transformedEvent[key] = stringValue.toLowerCase();
          } else {
            transformedEvent[key] = data[key];
          }
        });
      }
      return firebase.analytics().logEvent(name, transformedEvent);
    },
  };
}

export default analytics;
