import _ from 'lodash';
import * as types from '../actions/actionTypes';
import { Advert, Colour, Discipline, Gender, SaleType, Type } from '../models';

type InitialState = {
  list: Advert[];
  currentAdvert: Advert | null;
  loading: boolean;
  sortKey: string;
  sortDirection: string;
  sortSetBySystem: boolean;
  filters: any[];
  ranges: any[];
  disciplines: Discipline[];
  saleTypes: SaleType[];
  colours: Colour[];
  types: Type[];
  genders: Gender[];
  nextToken: string | null;
  favourites: any[];
  myadverts: any[];
};

const initialState: InitialState = {
  list: [],
  currentAdvert: null,
  loading: true,
  sortKey: 'publishedAt',
  sortDirection: 'desc',
  sortSetBySystem: true,
  filters: [],
  ranges: [],
  disciplines: [],
  saleTypes: [],
  colours: [],
  types: [],
  genders: [],
  nextToken: null,
  favourites: [],
  myadverts: [],
};

export default (state = initialState, action): InitialState => {
  switch (action.type) {
    case types.UPDATE_ADVERTS:
      return (() => {
        return {
          ...state,
          list: action.payload.items,
          from: action.payload.from,
          total: action.payload.total,
          loading: false,
        };
      })();

    case types.UPDATE_LANDING:
      return (() => {
        return {
          ...state,
          landing: action.payload.landing,
        };
      })();

    case types.UPDATE_CURRENT_ADVERT:
      return (() => {
        return {
          ...state,
          currentAdvert: action.payload.advert,
          loading: false,
        };
      })();

    case types.ADD_ADVERTS:
      return (() => {
        return {
          ...state,
          list: _.uniqBy([...state.list, ...action.payload.items], 'id'),
          from: action.payload.from,
          total: action.payload.total,
          loading: false,
        };
      })();

    case types.UPDATE_SOLD_AT:
      return (() => {
        const newList = [...state.list];

        const index = _.findIndex(newList, { id: action.payload.advertId });

        if (index > -1) {
          newList[index].soldAt = action.payload.soldAt;
        }

        return {
          ...state,
          list: newList,
        };
      })();

    case types.ADD_FAVOURITE:
      return (() => {
        const newList = [...state.list];

        const index = _.findIndex(newList, { id: action.payload.advertId });

        if (index > -1) {
          newList[index].favouriteId = action.payload.favouriteId;
        }

        return {
          ...state,
          list: newList,
        };
      })();

    case types.REMOVE_FAVOURITE:
      return (() => {
        const newList = [...state.list];

        const index = _.findIndex(newList, { favouriteId: action.payload.id });

        if (index > -1) {
          delete newList[index].favouriteId;
        }

        return {
          ...state,
          list: newList,
        };
      })();

    case types.UPDATE_FAVOURITES:
      return (() => {
        return {
          ...state,
          favourites: action.payload.favourites,
        };
      })();

    case types.UPDATE_MY_ADVERTS:
      return (() => {
        return {
          ...state,
          myadverts: action.payload.adverts,
        };
      })();

    default:
      return state;
  }
};
