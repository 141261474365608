import { categoryIdByPath } from './categoryPaths';

export const termTypes = [
  'categoryId',
  'saleTypeId',
  'propertySaleTypeId',
  'trailerSaleTypeId',
  'trailerTypeId',
  'typeId',
  'miles',
  'searchLocation',
  'weight',
  'living',
  'condition',
  'propertyTypeId',
  'annex',
  'accommodation',
  'petsAllowed',
  'horsesAllowed',
  'ftpt',
  'stablesTypeId',
  'priceTypeId',
];

export const multiTermTypes = [
  'typeId',
  'disciplines',
  'colourId',
  'genderId',
  'trailerFacilities',
  'propertyFacilities',
  'liveryFacilities',
  'liveryTypeId',
  'jobTypeId',
];
export const rangeTypes = [
  'age',
  'height',
  'price',
  'numberOfBedrooms',
  'sleeps',
  'payload',
  'numberOfHorses',
  'numberOfBedrooms',
  'numberOfBathrooms',
  'numberOfReceptionRooms',
  'numberOfStables',
  'numberOfPaddocks',
  'salary',
];
export const booleanTypes = ['hasMedia', 'excludePOA'];

type GetSearchOptionsFilterBoxResponse = {
  filters: any;
  sort: string;
};

/**
 * Generates variables / values for using with graphql api and filterbox component
 * @param SearchOptions - includes sort and filters object
 * @returns return variables to use for graphql api and filterbox components
 */
type GetSearchOptionsResponse = {
  /**
   * To use with graphql api
   * @returns Graphql query variables
   */
  apiQuery: any;
  /**
   * To use with the FilterBox component
   * @returns type FilterBoxQuery
   */
  urlParameters: any;
  filterBoxProps: GetSearchOptionsFilterBoxResponse;
};

interface GetSearchOptionsParams {
  sort?: string;
  filters?: any;
}

/**
 * Generates variables / values for using with graphql api and filterbox component
 * @param SearchOptions - includes sort and filters object
 * @returns return variables to use for graphql api and filterbox components
 */
export function getSearchOptions(
  params: GetSearchOptionsParams,
  locationPath?: string
): GetSearchOptionsResponse {
  // get variables for api

  // get url string?

  // get data for form inputs

  const searchParamsData = {
    sort: 'publishedAt.desc',
    filters: {
      categoryId: params.categoryId,
    },
  };

  let filters = {};

  let sort = 'publishedAt.desc';
  if (Object.keys(params).length > 0) {
    if (params.sort) {
      searchParamsData.sort = params.sort;
      sort = params.sort;
    }

    // if (!params.categoryId || params.categoryId === 'horse') {
    //   // if (!params.includePOA) {
    //   //   searchParamsData.filters.includePOA = false;
    //   // }
    //   if (params.includePOA === 'checked' || params.includePOA) {
    //     searchParamsData.filters.includePOA = true;
    //   }
    // }
    if (params['coords.lat'] || params['coords.lon']) {
      params.coords = {
        lat: params['coords.lat'],
        lon: params['coords.lon'],
      };
    }
    if (params.coords?.lat && params.coords?.lon) {
      searchParamsData.filters.coords = {
        lat: params.coords?.lat,
        lon: params.coords?.lon,
      };
    }

    if (params.isAdminLevel1 || params.isAdminLevel1 === 'true') {
      searchParamsData.filters.isAdminLevel1 = true;
      searchParamsData.filters.adminLevel1 = params.searchLocation;
    }

    booleanTypes.forEach((key) => {
      if (params[key] === 'checked' || params[key]) {
        searchParamsData.filters[key] = true;
      }
    });

    termTypes.forEach((key) => {
      if (params[key]) {
        searchParamsData.filters[key] = params[key];
      }
    });
    multiTermTypes.forEach((key) => {
      if (params[key]) {
        if (params[key].length > 0) {
          searchParamsData.filters[key] = params[key];
        }
      }
    });

    filters = { ...searchParamsData.filters };

    rangeTypes.forEach((key) => {
      if (params[`${key}.min`]) {
        searchParamsData.filters[key] = {
          ...searchParamsData.filters[key],
          gte: params[`${key}.min`],
        };
        filters[`${key}.min`] = params[`${key}.min`];
      }
      if (params[`${key}.max`]) {
        searchParamsData.filters[key] = {
          ...searchParamsData.filters[key],
          lte: params[`${key}.max`],
        };
        filters[`${key}.max`] = params[`${key}.max`];
      }
    });
  }

  let location = { pathname: '/horses' };

  if (typeof window !== 'undefined') {
    location = window.location;
  }

  if (locationPath) {
    location = { pathname: locationPath };
  }
  searchParamsData.filters.categoryId = categoryIdByPath[location.pathname] || 'horse';

  if (!filters.miles) {
    filters.miles = '50';
  }

  if (params.page) {
    filters.page = params.page;
    searchParamsData.from = parseInt(params.page) * 10 - 10;
  }

  return {
    apiQuery: searchParamsData,
    urlParameters: { sort, ...filters },
    filterBoxProps: { sort, filters },
  };
}
