import { Collapse } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import React, { ReactElement, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Head from '../../components/Head';

import './styles.less';

const { Panel } = Collapse;

const HelpFaqScene: React.FC = (props): ReactElement => {
  const history = useHistory();

  const locationRef = useRef(null);

  const [category, setCategory] = useState('horse.sale');

  let backLink = `${process.env.REACT_APP_CLASSIFIEDS_URL}`;
  if (typeof window !== 'undefined') {
    backLink = encodeURIComponent(window?.location?.href);
  }
  return (
    <div className="helpFaqScene">
      <Head title="Help &amp; FAQ - Horse &amp; Hound" />
      <section>
        <div className="container content" style={{ paddingBottom: 80 }}>
          <h1 className="heading">Help & FAQ</h1>
          <h3 className="categoryTitle">Online Ads</h3>
          <Collapse ghost expandIcon={() => null}>
            <Panel
              header={
                <span className="panelHeader">
                  How do I place an ad on Horse &amp; Hound?
                </span>
              }
              key="1"
              className="panel"
            >
              <div className="panelContent">
                <p>
                  Click &quot;Place an advert&quot; on the Horse &amp; Hound Horses for
                  Sale website to start posting an ad online, this will take you to our
                  platform managed by Whickr. If you already have a Whickr account, please
                  log in using your existing Whickr details, otherwise please register for
                  an account with Whickr at www.whickr.com to post your advert on the
                  Horse &amp; Hound Horses for Sale website. Once on Whickr, please
                  include as much information as possible along with your contact
                  information. Your advert once placed will be immediately live on our
                  website.
                </p>
              </div>
            </Panel>
            <Panel
              header={
                <span className="panelHeader">
                  Do I have to register to place an online ad?
                </span>
              }
              key="2"
              className="panel"
            >
              <div className="panelContent">
                <p>
                  Yes, we need to be able to communicate with you if you are placing an
                  advert on Horse &amp; Hound via our partner, Whickr. Registration is
                  quick, easy and free. Registering also gives you access to special
                  features, including:
                  <ol>
                    <li>
                      Sellers can place online adverts for horses and equestrian products
                      that are seen by thousands of potential buyers on Horse &amp; Hound
                      and the Whickr platform.
                    </li>
                    <li>
                      Sellers may cancel ads online at any time. If you provided your
                      email address when placing an advert, you will receive an email
                      reminder when your ad is about to expire.
                    </li>
                    <li>
                      Buyers have access to a personal online profile where they can save
                      ads and searches.
                    </li>
                    <li>
                      Buyers can also set up automated search alerts via email. Once you
                      set up the criteria, we&apos;ll send you notices whenever there are
                      new listings that match what you&apos;re looking for.
                    </li>
                    <li>
                      Registered members may also participate in online promotions, and
                      are entitled to receive email updates on the latest developments.
                    </li>
                  </ol>
                </p>
              </div>
            </Panel>
            <Panel
              header={
                <span className="panelHeader">
                  How do I make changes to my online advert?
                </span>
              }
              key="3"
              className="panel"
            >
              <div className="panelContent">
                <p>
                  To make changes to your advert, log into Horse &amp; Hound&apos;s
                  partner&apos;s account Whickr and then click on &apos;My Adverts&apos;
                  which you&apos;ll find under the &apos;My Profile&apos; tab. Select your
                  ad and click &apos;Edit&apos;. Make any desired changes, including
                  adding videos and photos and don&apos;t forget to save and publish!
                </p>
              </div>
            </Panel>
            <Panel
              header={
                <span className="panelHeader">
                  How long does it take for a new advert to show up?
                </span>
              }
              key="4"
              className="panel"
            >
              <div className="panelContent">
                <p>
                  All ads submitted online will go live on our site and Whickr
                  immediately.
                </p>
              </div>
            </Panel>
            <Panel
              header={
                <span className="panelHeader">
                  I have sold my item, but I can&apos;t remove it from the website. What
                  should I do?
                </span>
              }
              key="5"
              className="panel"
            >
              <div className="panelContent">
                <p>
                  You can easily mark your advert as sold. Via Horse &amp; Hound partner
                  Whickr, login, go to My Adverts, and select &apos;Mark Advert as
                  Sold&apos; on the sold advert. Alternatively, please email{' '}
                  <a href="mailto:tackroom@whickr.com">tackroom@whickr.com</a> to remove
                  your advert.
                </p>
              </div>
            </Panel>
            <Panel
              header={
                <span className="panelHeader">
                  How do I filter and refine my search results to find exactly what I am
                  looking for?
                </span>
              }
              key="6"
              className="panel"
            >
              <div className="panelContent">
                <p>
                  To begin your search enter your location and search radius in the
                  ‘distance&apos; boxes on the homepage. From here you&apos;ll be taken to
                  a page of adverts available in your search radius.
                </p>
                <p>
                  From here you can narrow your search by price, age and lots of other
                  options which should help narrow down your search to find exactly what
                  you&apos;re looking for.
                </p>
              </div>
            </Panel>
            <Panel
              header={<span className="panelHeader">What is your refund policy?</span>}
              key="7"
              className="panel"
            >
              <div className="panelContent">
                <p>
                  Please be sure to pay extra attention when creating your advert. Horse &
                  Hound is not responsible for user errors. If you make a mistake when
                  creating your ad, you can still edit it by logging into your account and
                  going to the &apos;My Ads&apos; tab. We/ our partner Whickr will not
                  issue refunds for cancelled ads. It is your responsibility to review the
                  ad for all errors and mistakes before placing it on our website. If you
                  have any trouble during the ad placement process or just general
                  questions, please email{' '}
                  <a href="mailto:tackroom@whickr.com">tackroom@whickr.com</a>.
                </p>
              </div>
            </Panel>
            <Panel
              header={<span className="panelHeader">Is my credit card safe?</span>}
              key="8"
              className="panel"
            >
              <div className="panelContent">
                <p>
                  Security is one of our top priorities. When you submit sensitive
                  information like your credit card number via our partner&apos;s website,
                  your information is protected. It is encrypted and protected with the
                  best encryption software currently available in the industry - SSL. The
                  highest level of encryption of SSL currently available is used to ensure
                  your information remains safe.
                </p>
              </div>
            </Panel>
          </Collapse>
          {/* <h3 className="categoryTitle">Site Features</h3>
          <Collapse ghost expandIcon={() => null}>
            <Panel
              header={<span className="panelHeader">What is an email alert?</span>}
              key="9"
              className="panel"
            >
              <div className="panelContent">
                <p>
                  You can subscribe to email alerts to receive new listings in your email
                  based on your search criteria. For example, if you are interested in a
                  specific discipline of horse, you can sign up for an email alert which
                  will send you any new adverts for horses of that discipline. This is a
                  great way to hear about the latest ads on Horse &amp; Hound.
                </p>
              </div>
            </Panel>
          </Collapse> */}
          <h3 className="categoryTitle">Print Ads</h3>
          <Collapse ghost expandIcon={() => null}>
            <Panel
              header={
                <span className="panelHeader">
                  Who should I contact to place an advert in a magazine?
                </span>
              }
              key="10"
              className="panel"
            >
              <div className="panelContent">
                <p>
                  You can book adverts for both online and the magazine through the online
                  booking system.
                </p>
              </div>
            </Panel>
            <Panel
              header={
                <span className="panelHeader">
                  When is your deadline to book an advert in a magazine?
                </span>
              }
              key="11"
              className="panel"
            >
              <div className="panelContent">
                <p>
                  All horses for sale adverts include a free print advert in one issue of
                  Horse &amp; Hound magazine, at the discretion of the editorial team
                  during busy months. If you need additional help or would like to speak
                  to our partner&apos;s customer service team about placing an advert in
                  the magazine please call 07399 535683 or email our partner at{' '}
                  <a href="mailto:tackroom@whickr.com">tackroom@whickr.com</a>. Our print
                  deadline depends on the magazine you would like your ad to appear in.
                </p>
              </div>
            </Panel>
          </Collapse>
          <h3 className="categoryTitle">Trust &amp; Safety</h3>
          <Collapse ghost expandIcon={() => null}>
            <Panel
              header={
                <span className="panelHeader">
                  Am I buying horses or products from Horse &amp; Hound?
                </span>
              }
              key="12"
              className="panel"
            >
              <div className="panelContent">
                <p>
                  No, Horse &amp; Hound is an online and print advertising media that
                  brings buyers and sellers together. Horse &amp; Hound and our partner
                  Whickr do not own or sell any of the horses or products that are
                  advertised.
                </p>
              </div>
            </Panel>
            <Panel
              header={
                <span className="panelHeader">
                  What do I do if I see a suspicious or offensive advert on Horse &amp;
                  Hound?
                </span>
              }
              key="13"
              className="panel"
            >
              <div className="panelContent">
                <p>
                  Please report the advert so that it can be reviewed and removed by our
                  team. When you click &quot;Report&quot; you will be asked to enter your
                  email address and a brief description of the abuse involved. Types of
                  abuse include: fraudulent ads intended to scam users,
                  illegal/obscene/discriminatory or any inappropriate content, copyright
                  infringement, spam, or any other listings that violate our Terms &
                  Conditions.
                </p>
              </div>
            </Panel>
          </Collapse>
        </div>
      </section>
    </div>
  );
};

// HelpFaqScene.fetchData = async (ssrStore, match, req) => {
//   const SSR = withSSRContext({ req });
//   const searchParamsInUrl = qs.parse('?sort=publishedAt.desc&page=1', {
//     ignoreQueryPrefix: true,
//   });

//   const { apiQuery } = getSearchOptions(searchParamsInUrl);
//   const advertItems = await getAdverts({ ...apiQuery, limit: 6, amp: SSR });
//   console.log('advertItems', advertItems);
//   ssrStore.dispatch(updateLanding({ adverts: advertItems }));
// };

export default HelpFaqScene;
