import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';
import * as types from '../actions/actionTypes';

export default {
  init(initialState, options = { logger: true }) {
    const middleware = [thunk];

    if (process.env.NODE_ENV !== 'production' && options.logger) {
      const { createLogger } = require('redux-logger');
      const logger = createLogger({ collapsed: true });
      middleware.push(logger);
    }

    this.store = createStore(rootReducer, initialState, applyMiddleware(...middleware));
    return this.store;
  },
};
