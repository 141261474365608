import { combineReducers } from 'redux';

import adverts from './adverts';
import user from './user';
import misc from './misc';
import wantedAdverts from './wantedAdverts';

const appReducer = combineReducers({
  adverts,
  user,
  misc,
  wantedAdverts
});

const rootReducer = (state, action) => {
  if (action.type === 'DESTROY_SESSION') {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
