export const isServer = typeof window === 'undefined';
export const isClient = !isServer;

export const storage = isServer
  ? {
      getItem: () => null,
      putItem: () => null,
      removeItem: () => null,
      setItem: () => null,
    }
  : localStorage;
