import { Select, Menu, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import React, { ReactElement, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { withSSRContext } from 'aws-amplify';
import Head from '../../components/Head';

import './styles.less';
import Card from '../../components/Card';
import { updateDisciplines } from '../../actions/miscActions';
import { connect } from 'react-redux';
import getDisciplines from '../../methods/getDisciplines';
import { jobSubCategories } from '../../utils/options';

const SitemapScene: React.FC = (props): ReactElement => {
  const history = useHistory();

  const locationRef = useRef(null);

  const [category, setCategory] = useState('horse.sale');

  let backLink = `${process.env.REACT_APP_CLASSIFIEDS_URL}`;
  if (typeof window !== 'undefined') {
    backLink = encodeURIComponent(window?.location?.href);
  }
  return (
    <div>
      <Head title="Sitemap - Horse &amp; Hound" />
      <section>
        <div className="container content">
          <h2 className="heading">Categories</h2>
          <div className="columns">
            <ul>
              <li>
                <a href="/cats/">Cats</a>
              </li>
            </ul>
            <ul>
              <li>
                <a href="/dogs/">Dogs</a>
              </li>
            </ul>
            <ul>
              <li>
                <a href="/jobs/">Equestrian jobs</a>
                <ul>
                  {jobSubCategories.map((item) => (
                    <li key={item.id}>
                      <a href={`/jobs?jobTypeId=${item.value}`}>{item.label}</a>
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
            <ul>
              <li>
                <a href="/properties/">Equestrian properties</a>
                <ul>
                  <li>
                    <a href="/properties?propertySaleTypeId=sale">
                      Equestrian property for sale
                    </a>
                  </li>
                  <li>
                    <a href="/properties?propertySaleTypeId=let">
                      Equestrian property to let
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            <ul>
              <li>
                <a href="/horseboxes-trailers/">Horseboxes &amp; trailers</a>
                <ul>
                  <li>
                    <a href="/horseboxes-trailers?trailerTypeId=trailer&trailerSaleTypeId=hire">
                      Horse trailers for hire
                    </a>
                  </li>
                  <li>
                    <a href="/horseboxes-trailers?trailerTypeId=trailer&trailerSaleTypeId=sale">
                      Horse trailers for sale
                    </a>
                  </li>
                  <li>
                    <a href="/horseboxes-trailers?trailerTypeId=horsebox&trailerSaleTypeId=hire">
                      Horseboxes for hire
                    </a>
                  </li>
                  <li>
                    <a href="/horseboxes-trailers?trailerTypeId=horsebox&trailerSaleTypeId=sale">
                      Horseboxes for sale
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            <ul>
              <li>
                <a href="/horses-for-loan-share/">Horses for loan/lease/share</a>
                <ul>
                  {props.disciplines
                    ?.filter((o) => o.type === '5ac0973a-2239-4048-a953-f812bc74d927')
                    .map(({ label, id }) => (
                      <li>
                        <a
                          href={`/horses?saleTypeId=89ac3e31-fcfb-4794-8af9-6342f2d2b56e&typeId=5ac0973a-2239-4048-a953-f812bc74d927&disciplines=${id}`}
                        >
                          {label}
                        </a>
                      </li>
                    ))}
                </ul>
              </li>
            </ul>
            <ul>
              <li>
                <a href="/horses-for-sale/">Horses for sale</a>
                <ul>
                  {props.disciplines
                    ?.filter((o) => o.type === '5ac0973a-2239-4048-a953-f812bc74d927')
                    .map(({ label, id }) => (
                      <li>
                        <a
                          href={`/horses?saleTypeId=80b50ee5-0f84-48e7-92f1-65793a48d597&typeId=5ac0973a-2239-4048-a953-f812bc74d927&disciplines=${id}`}
                        >
                          {label}
                        </a>
                      </li>
                    ))}
                </ul>
              </li>
            </ul>
            <ul>
              <li>
                <a href="/ponies-for-sale/">Ponies for loan / share</a>
                <ul>
                  {props.disciplines
                    ?.filter((o) => o.type === '5ae802e7-5b1b-4818-a67e-ecdf8e70fe88')
                    .map(({ label, id }) => (
                      <li>
                        <a
                          href={`/horses?saleTypeId=89ac3e31-fcfb-4794-8af9-6342f2d2b56e&typeId=5ae802e7-5b1b-4818-a67e-ecdf8e70fe88&disciplines=${id}`}
                        >
                          {label}
                        </a>
                      </li>
                    ))}
                </ul>
              </li>
            </ul>
            <ul>
              <li>
                <a href="/ponies-for-sale/">Ponies for sale</a>
                <ul>
                  {props.disciplines
                    ?.filter((o) => o.type === '5ae802e7-5b1b-4818-a67e-ecdf8e70fe88')
                    .map(({ label, id }) => (
                      <li>
                        <a
                          href={`/horses?saleTypeId=80b50ee5-0f84-48e7-92f1-65793a48d597&typeId=5ae802e7-5b1b-4818-a67e-ecdf8e70fe88&disciplines=${id}`}
                        >
                          {label}
                        </a>
                      </li>
                    ))}
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};

SitemapScene.fetchData = async (ssrStore, match, req) => {
  const SSR = withSSRContext({ req });
  const disciplines = await getDisciplines({ amp: SSR });
  ssrStore.dispatch(
    updateDisciplines(disciplines?.filter((o) => o.showOn?.includes('web')))
  );
};

export default connect((state): { disciplines: any[] } => ({
  disciplines: state.misc.disciplines,
}))(SitemapScene);
