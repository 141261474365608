import _ from 'lodash';
import * as types from '../actions/actionTypes';
import { Advert, Colour, Discipline, Gender, SaleType, Type } from '../models';

type InitialState = {
  shouldShowWelcomeMessage: boolean;
  colors: Colour[];
  disciplines: Discipline[];
};

const initialState: InitialState = {
  shouldShowWelcomeMessage: true,
  colors: [],
  disciplines: [],
};

export default (state = initialState, action): InitialState => {
  switch (action.type) {
    case types.UPDATE_SHOW_WELCOME_MESSAGE:
      return (() => {
        return {
          ...state,
          shouldShowWelcomeMessage: action.payload.shouldShowWelcomeMessage,
        };
      })();

    case types.UPDATE_COLORS:
      return (() => {
        return {
          ...state,
          colors: action.payload.colors,
        };
      })();

    case types.UPDATE_DISCIPLINES:
      return (() => {
        return {
          ...state,
          disciplines: action.payload.disciplines,
        };
      })();
    default:
      return state;
  }
};
