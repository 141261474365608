/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'eu-west-2',
  aws_cognito_identity_pool_id: 'eu-west-2:978d276d-1a6b-47c7-82e4-daf85b467e52',
  aws_cognito_region: 'eu-west-2',
  aws_user_pools_id: 'eu-west-2_r69sPhpzG',
  aws_user_pools_web_client_id: '2fiaknoddr1r499p2kpoal4q2j',
  oauth: {},
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
  aws_appsync_graphqlEndpoint:
    'https://xqt55imnzjdllcqj6ke4hjzym4.appsync-api.eu-west-2.amazonaws.com/graphql',
  aws_appsync_region: 'eu-west-2',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_apiKey: 'da2-cdo7h7oquraqxk6ppw7nml3t6m',
  aws_cloud_logic_custom: [
    {
      name: 'WhickrRest',
      endpoint: 'https://ws1jkdc6d6.execute-api.eu-west-2.amazonaws.com/testing',
      region: 'eu-west-2',
    },
    {
      name: 'SSR',
      endpoint: 'https://6quk3sp46h.execute-api.eu-west-2.amazonaws.com/testing',
      region: 'eu-west-2',
    },
    {
      name: 'AdminQueries',
      endpoint: 'https://l0jcwxlpw0.execute-api.eu-west-2.amazonaws.com/testing',
      region: 'eu-west-2',
    },
  ],
  aws_user_files_s3_bucket: 'whickr-cdn142217-testing',
  aws_user_files_s3_bucket_region: 'eu-west-2',
};

export default awsmobile;
