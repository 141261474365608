import React, { ReactElement, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Head from '../../components/Head';

import './styles.less';

const AboutScene: React.FC = (props): ReactElement => {
  const history = useHistory();

  const locationRef = useRef(null);

  const [category, setCategory] = useState('horse.sale');

  let backLink = `${process.env.REACT_APP_CLASSIFIEDS_URL}`;
  if (typeof window !== 'undefined') {
    backLink = encodeURIComponent(window?.location?.href);
  }
  return (
    <div>
      <Head title="About - Horse &amp; Hound" />
      <section>
        <div className="container content">
          <h1 className="heading">About</h1>
          <p className="aboutParagraph">
            Horse &amp; Hound is the UK&#39;s equestrian bible, an essential reference
            point for equestrian enthusiasts and home to one of the UK&#39;s leading
            equestrian communities. It offers the latest equestrian news, a popular online
            forum and hundreds of horses for sale, plus other equestrian equipment. Visit
            the website at:{' '}
            <a href="https://www.horseandhound.co.uk">
              {' '}
              <strong>www.horseandhound.co.uk</strong>
            </a>
          </p>
          <p className="aboutParagraph">
            Classified advertisements displayed on Horse &amp; Hound &#39;Horses For
            Sale&#39; are provided by the sellers. Future Publishing Limited (Future), the
            publisher of <em>Horse &amp; Hound</em>, is not responsible for the content of
            the advertisements and is unable to verify the accuracy of these
            advertisements. Prospective buyers should check the authenticity of the seller
            and see the horse prior to making any payment. Beware of scams requiring
            upfront payment or sellers unable to prove their identity. Future accepts no
            liability to a buyer in connection with these advertisements. Please refer to
            detailed guidance on{' '}
            <a href="https://www.horseandhound.co.uk/features/hhs-ultimate-guide-to-buying-a-horse-314473">
              buying horses
            </a>{' '}
            and our{' '}
            <a href="https://www.horseandhound.co.uk/horse-care/buying-selling-advice/avoiding-online-scams-advice-buyers-646244">
              advice on scams
            </a>
            .
          </p>
        </div>
      </section>
    </div>
  );
};

// AboutScene.fetchData = async (ssrStore, match, req) => {
//   const SSR = withSSRContext({ req });
//   const searchParamsInUrl = qs.parse('?sort=publishedAt.desc&page=1', {
//     ignoreQueryPrefix: true,
//   });

//   const { apiQuery } = getSearchOptions(searchParamsInUrl);
//   const advertItems = await getAdverts({ ...apiQuery, limit: 6, amp: SSR });
//   console.log('advertItems', advertItems);
//   ssrStore.dispatch(updateLanding({ adverts: advertItems }));
// };

export default AboutScene;
