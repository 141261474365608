/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/prop-types */
import { Component } from 'react';
import { bindActionCreators } from 'redux';

import { connect } from 'react-redux';

import _ from 'lodash';
import moment from 'moment';

import * as advertActions from '../actions/advertActions';
import { getAdverts } from '../methods/adverts';
import { Advert } from '../models';

type Props = {
  adverts: { list: Advert[] };
};
class ChildComponent extends Component<Props> {
  render() {
    const { children, adverts, staticAdverts, searchParams, ...props } = this.props;
    const childStuff: {
      adverts: Advert[];
      loading: boolean;
      from: string;
    } = {
      adverts: adverts.list,
      loading: adverts.loading,
      from: adverts.from,
      total: adverts.total,
      ...props,
    };
    return children(childStuff);
  }
}

export default connect(
  (state): { adverts: Advert[]; user: any } => ({
    adverts: state.adverts,
    user: state.user,
  }),
  (dispatch) => ({
    actions: bindActionCreators({ ...advertActions }, dispatch),
  })
)(ChildComponent);
