import _ from 'lodash';
import * as types from '../actions/actionTypes';
import { WantedAdvert } from '../models';

type InitialState = {
  list: WantedAdvert[];
};

const initialState: InitialState = {
  list: [],
};

export default (state = initialState, action): InitialState => {
  switch (action.type) {

    case types.UPDATE_WANTED_ADVERTS:
      return (() => {
        return {
          ...state,
          list: action.payload.wantedAdverts,
        };
      })();

    default:
      return state;
  }
};
