import React, { ReactElement, useState, useEffect } from 'react';
import './styles.less';

import { Row, Col, Menu, Dropdown, Space, message, Button, Modal } from 'antd';
import { Auth } from 'aws-amplify';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { UserOutlined, CaretDownOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { Header } from 'antd/lib/layout/layout';

import { storage } from '../../utils';
import LoginModal from '../LoginModal';
import AuthPopUp from '../AuthPopUp';
import RegisterModal from '../RegisterModal';
import analytics from '../../utils/analytics';
import device from '../../utils/device';
import { disciplinesOptions } from '../../utils/options';

const { REACT_APP_CLASSIFIEDS_URL, REACT_APP_WHICKR_URL } = process.env;

const { confirm } = Modal;

/**
 * Description
 */

const ClassifiedsHeader: React.FC = ({}): ReactElement => {
  const location = useLocation();

  const selectedPage = location.pathname;

  let backLink = `${REACT_APP_CLASSIFIEDS_URL}`;
  if (typeof window !== 'undefined') {
    backLink = encodeURIComponent(window?.location?.href);
  }
  return (
    <div className="header">
      <div className="large">
        <div className="container" style={{ zIndex: 2, position: 'relative' }}>
          <Row className="headerRow" gutter={[32, 0]}>
            <Col span="6">
              <a href="/">
                <div className="logo">
                  <img src="/img/horseandhoundLogo.png" className="logoImage" />
                </div>
              </a>
            </Col>
            <Col span="18">
              <div className="headerRowRight">
                <div className="headerRowRightTop">
                  <a href="https://horseandhound.co.uk" style={{ color: '#c8b861' }}>
                    <ArrowLeftOutlined
                      style={{
                        marginRight: 6,
                      }}
                    />
                    <span
                      style={{
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      Go back to horseandhound.co.uk
                    </span>
                  </a>
                  <div className="poweredByHolder">
                    <span className="poweredByText">Powered by Whickr</span>
                    <Button
                      // size="large"
                      className="poweredByButton"
                      href={`${REACT_APP_WHICKR_URL}/create-advert?p=horseandhound&backLink=${backLink}`}
                    >
                      Place an advert
                    </Button>
                    <span className="authLinks">
                      <a
                        href={`${REACT_APP_WHICKR_URL}/auth/login?p=horseandhound&backLink=${backLink}`}
                      >
                        <UserOutlined style={{ marginRight: 8 }} />
                        My Account
                      </a>
                    </span>
                  </div>
                </div>
                <div className="navHolder">
                  <Menu
                    mode="horizontal"
                    theme="light"
                    selectedKeys={selectedPage}
                    className="nav"
                    style={{ minWidth: 0, flex: 'auto' }}
                  >
                    <Menu.SubMenu
                      title={
                        <>
                          <span style={{ marginRight: 4 }}>Horses By Type</span>
                          <CaretDownOutlined />
                        </>
                      }
                    >
                      <Menu.Item key="/horses">
                        <Link to="/horses">Browse all horses</Link>
                      </Menu.Item>
                      {disciplinesOptions.map(({ label, value }) => (
                        <Menu.Item key={`/horses-${value}`}>
                          <Link
                            to={`/horses?typeId=5ac0973a-2239-4048-a953-f812bc74d927&disciplines=${value}`}
                          >
                            {label}
                          </Link>
                        </Menu.Item>
                      ))}
                    </Menu.SubMenu>

                    <Menu.Item key="/horseboxes-trailers">
                      <Link to="/horseboxes-trailers">Horseboxes &amp; trailers</Link>
                    </Menu.Item>
                    <Menu.Item key="/properties">
                      <Link to="/properties">Equestrian properties</Link>
                    </Menu.Item>
                    <Menu.SubMenu
                      title={
                        <>
                          <span style={{ marginRight: 4 }}>More Categories</span>
                          <CaretDownOutlined />
                        </>
                      }
                    >
                      <Menu.Item key="/jobs">
                        <Link to="/jobs">Equestrian jobs</Link>
                      </Menu.Item>
                      <Menu.Item key="/dogs">
                        <Link to="/dogs">Dogs</Link>
                      </Menu.Item>
                      {/* <Menu.Item key="/livery-yards">
                        <Link to="/livery-yards">Livery yards</Link>
                      </Menu.Item>
                      <Menu.Item key="/misc">
                        <Link to="/misc">Miscellaneous</Link>
                      </Menu.Item> */}
                      <Menu.Item key="/cats">
                        <Link to="/cats">Cats</Link>
                      </Menu.Item>
                      {/* <Menu.Item key="/stallions-at-stud">
                        <Link to="/stallions-at-stud">Stallions at Stud</Link>
                      </Menu.Item> */}
                    </Menu.SubMenu>
                  </Menu>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="small">
        <div className="backLinkHolder">
          <a href="https://horseandhound.co.uk" style={{ color: '#c8b861' }}>
            <ArrowLeftOutlined
              style={{
                marginRight: 6,
              }}
            />
            <span
              style={{
                padding: 0,
                margin: 0,
              }}
              className="backLinkText"
            >
              Go back to horseandhound.co.uk
            </span>
          </a>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 20,
            paddingTop: 0,
          }}
        >
          <div style={{ flex: 2 }}>
            <a href="/">
              <div className="logo">
                <img src="/img/horseandhoundLogo.png" className="logoImage" />
              </div>
            </a>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <div className="poweredByHolder">
              <a
                href="#"
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                }}
              >
                <span
                  style={{
                    display: 'inline-block',
                    padding: 0,
                    margin: 0,
                    color: 'white',
                    // marginRight: 10,
                  }}
                >
                  Powered by
                </span>
                <img
                  src="/img/logo-white-transparent-background.png"
                  width="70"
                  style={{ paddingBottom: 3, marginLeft: 5 }}
                />
              </a>
              <Button
                // size="large"
                className="poweredByButton"
                href={`${REACT_APP_WHICKR_URL}/create-advert?p=horseandhound&backLink=${backLink}`}
              >
                Place an advert
              </Button>
            </div>
          </div>
        </div>
        <Row gutter={[0, 0]}>
          <Col xs={{ span: 24 }}>
            <Row gutter={[0, 0]}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
              >
                <div className="navHolder">
                  <Menu
                    mode="inline"
                    theme="light"
                    selectedKeys={selectedPage}
                    className="nav"
                    style={{ minWidth: 0, flex: 'auto' }}
                  >
                    <Menu.SubMenu
                      title={
                        <>
                          <span style={{ marginRight: 4 }}>Menu</span>
                        </>
                      }
                    >
                      {/* <Menu.Item key="/create-advert">
                        <a
                          className="createAdvertLink"
                          href={`${REACT_APP_WHICKR_URL}/create-advert?p=horseandhound&backLink=${backLink}`}
                        >
                          Place an Advert
                        </a>
                      </Menu.Item> */}
                      <Menu.SubMenu
                        title={
                          <>
                            <span style={{ marginRight: 4 }}>Horses By Type</span>
                          </>
                        }
                      >
                        <Menu.Item key="/horses">
                          <a href="/horses">Browse all horses</a>
                        </Menu.Item>
                        {disciplinesOptions.map(({ label, value }) => (
                          <Menu.Item key={`/horses-${value}`}>
                            <a href={`/horses?disciplines=${value}`}>{label}</a>
                          </Menu.Item>
                        ))}
                      </Menu.SubMenu>

                      <Menu.Item key="/horseboxes-trailers">
                        <a href="/horseboxes-trailers">Horseboxes &amp; trailers</a>
                      </Menu.Item>
                      <Menu.Item key="/properties">
                        <a href="/properties">Equestrian properties</a>
                      </Menu.Item>

                      <Menu.Item key="/jobs">
                        <a href="/jobs">Equestrian jobs</a>
                      </Menu.Item>
                      <Menu.Item key="/dogs">
                        <a href="/dogs">Dogs</a>
                      </Menu.Item>
                      {/* <Menu.Item key="/livery-yards">
                      <a href="/livery-yards">Livery yards</a>
                    </Menu.Item>
                    <Menu.Item key="/misc">
                      <a href="/misc">Miscellaneous</a>
                    </Menu.Item> */}
                      <Menu.Item key="/cats">
                        <a href="/cats">Cats</a>
                      </Menu.Item>
                      {/* <Menu.Item key="/stallions-at-stud">
                      <a href="/stallions-at-stud">Stallions at Stud</a>
                    </Menu.Item> */}
                      <Menu.Divider />
                      <Menu.Item key="/auth/login">
                        <a
                          href={`${REACT_APP_WHICKR_URL}/auth/login?p=horseandhound&backLink=${backLink}`}
                        >
                          <UserOutlined style={{ marginRight: 8 }} />
                          My Account
                        </a>
                      </Menu.Item>
                    </Menu.SubMenu>
                  </Menu>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ClassifiedsHeader;
