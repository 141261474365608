/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://pe5ffp0z4a.execute-api.eu-west-2.amazonaws.com/develop",
            "region": "eu-west-2"
        },
        {
            "name": "SSR",
            "endpoint": "https://7bk8jj70a0.execute-api.eu-west-2.amazonaws.com/develop",
            "region": "eu-west-2"
        },
        {
            "name": "WhickrRest",
            "endpoint": "https://c0qlf6sfpj.execute-api.eu-west-2.amazonaws.com/develop",
            "region": "eu-west-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://fymvg6kbgnbshl7kgmtar3o7um.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-sdp7eluxyrhmdduxcjtpftbcti",
    "aws_cognito_identity_pool_id": "eu-west-2:a7840570-ebdd-4de1-9cab-69a336d54418",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_TPlR2AnM3",
    "aws_user_pools_web_client_id": "7m2has88gubf6c2jhel15lqjqo",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "whickr-cdn131849-develop",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;
