import { Select, Row, Col, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import React, { ReactElement, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Box from '../../components/Box';
import Button from '../../components/Button';

import Head from '../../components/Head';
import { CategoryType } from '../../global';

import './styles.less';
import Card from '../../components/Card';

const prices = {
  horse: 2999,
  trailer: 4500,
  job: 15000,
  dog: 4500,
  cat: 4500,
  liveryYard: 9500,
  misc: 2499,
  property: 15000,
};

const formatPrice = (value) => {
  return value / 100;
};
const PricingScene: React.FC = (props): ReactElement => {
  const [advert, setAdvert] = useState({
    categoryId: 'horse',
    currency: 'gbp',
  });
  const history = useHistory();
  const category = {
    is: (key: CategoryType) => {
      return key === advert.categoryId;
    },
  };

  console.log('Pricing PROPS', props);
  const locationRef = useRef(null);

  const [categoryChoice, setCategoryChoice] = useState('horse.sale');

  // console.log('CAT', category);
  let backLink = `${process.env.REACT_APP_CLASSIFIEDS_URL}/advert-prices`;
  if (typeof window !== 'undefined') {
    backLink = encodeURIComponent(window?.location?.href);
  }
  return (
    <div>
      <Head title="Advert prices - Horse &amp; Hound" />
      <section>
        <div className="container content">
          <div className="partnershipInfoHolder">
            <div className="logo">
              <img
                src="/img/horse-and-hound-whickr-partnership.png"
                className="horseAndHoundWhickrLogo"
                alt="Horse & Hound: Horses For Sale - Powered By Whickr Logo"
              />
            </div>
            <h2>The Horse & Hound marketplace is powered by Whickr</h2>
            <p>
              Horse & Hound has partnered with Whickr to give you the best marketplace
              experience for buying and selling all things equestrian.
            </p>
            <div className="buttonHolder">
              <a href="https://www.horseandhound.co.uk/whickr?_ga=2.71558675.188526034.1702498639-481382675.1701709227">
                <Button label="Read more about our partnership" />
              </a>
              <a
                href={`${process.env.REACT_APP_WHICKR_URL}/create-advert?p=horseandhound&category=${advert.categoryId}&backLink=${backLink}`}
              >
                <Button
                  type="primary"
                  style={{ backgroundColor: '#dd6238', borderColor: '#dd6238' }}
                  label="Place an advert"
                />
              </a>
            </div>
          </div>
          <div className="advertPricesinfoHolder">
            <h1 className="heading">Advert prices</h1>
            <p className="introduction">
              To find out the price of advertising with Horse &amp; Hound, simply select
              the category you wish to advertise in from the drop down below. <br />
              <br />
              <strong>Place your advert before 10:30 am on a Monday</strong> to appear in
              the next available issue of Horse &amp; Hound, space permitting.
            </p>
            {/* <a
              href={`${process.env.REACT_APP_WHICKR_URL}/create-advert?p=horseandhound&category=${advert.categoryId}&backLink=${backLink}`}
              className="placeAdvertLink"
            >
              Place your advert now
            </a> */}
          </div>

          <div className="dropwdownHolder">
            <div className="separator-text">
              <span>Category</span>
            </div>
            <Select
              size="large"
              defaultValue="horse"
              // style={{ width: 120 }}
              // onChange={handleChange}
              onSelect={(value) => {
                setAdvert({ categoryId: value });
              }}
              className="selectBox"
              options={[
                {
                  value: 'horse',
                  label: 'Horses for Sale',
                },
                {
                  value: 'trailer',
                  label: 'Horseboxes & Trailers',
                },
                {
                  value: 'property',
                  label: 'Equestrian Properties',
                },

                {
                  value: 'job',
                  label: 'Equestrian Jobs',
                },
                {
                  value: 'dog',
                  label: 'Dogs',
                },
                {
                  value: 'cat',
                  label: 'Cats',
                },
              ]}
            />
          </div>
          <Row gutter={[16, 16]} align="center" style={{ minHeight: 400 }}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 16 }}>
              <div className="priceCardHolder">
                <div className="inputBox">
                  <h2>Advert Package</h2>
                  <span
                    style={{
                      display: 'block',
                      color: 'rgb(153, 153, 153)',
                      marginBottom: 16,
                    }}
                  >
                    {/* Optional */}
                  </span>

                  <Row gutter={[0, 16]}>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 24 }}
                    >
                      <Box
                        padding={20}
                        style={{
                          //   borderColor: '#695cbc',
                          //   borderWidth: 2,
                          backgroundColor: '#ebf2f0',
                          borderRadius: 0,
                        }}
                      >
                        {/* <h4 className="packageTitle">Standard</h4> */}
                        <span className="packagePrice">
                          <span>
                            £{formatPrice(prices[advert.categoryId] || 2999)} per advert
                          </span>
                          <a
                            href={`${process.env.REACT_APP_WHICKR_URL}/create-advert?p=horseandhound&category=${advert.categoryId}&backLink=${backLink}`}
                            className="placeAdvertLink"
                          >
                            Place your advert now
                          </a>
                        </span>
                      </Box>
                    </Col>
                  </Row>
                  <Row gutter={[0, 0]}>
                    {category.is(CategoryType.Horse) && (
                      <>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                        >
                          <ul className="packageDetailsList">
                            <li>Advert online for 6 weeks</li>
                            <li>3 Photos of your horse included</li>
                            <li>3 YouTube videos included</li>
                            <li>3 Videos (3 x 1 minute videos) included</li>
                          </ul>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                        >
                          <ul className="packageDetailsList">
                            <li>
                              Option to include in Horse &amp; Hound Magazine (advertised
                              for sale only)
                            </li>
                            <li>Immediately notify matching buyers</li>

                            <li>Knowledgeable customer support</li>
                          </ul>
                        </Col>
                      </>
                    )}

                    {category.is(CategoryType.Trailer) && (
                      <>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                        >
                          <ul className="packageDetailsList">
                            <li>Advert online for 6 weeks</li>
                            <li>5 Photos of your horsebox / trailer included</li>
                            <li>5 YouTube videos included</li>
                            <li>3 Videos (3 x 1 minute videos) included</li>
                          </ul>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                        >
                          <ul className="packageDetailsList">
                            <li>Knowledgeable customer support</li>
                          </ul>
                        </Col>
                      </>
                    )}

                    {category.is(CategoryType.Property) && (
                      <>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                        >
                          <ul className="packageDetailsList">
                            <li>Advert online for 6 weeks</li>
                            <li>5 Photos of your property included</li>
                            <li>5 YouTube videos included</li>
                            <li>3 Videos (3 x 1 minute videos) included</li>
                          </ul>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                        >
                          <ul className="packageDetailsList">
                            <li>Knowledgeable customer support</li>
                          </ul>
                        </Col>
                      </>
                    )}

                    {category.is(CategoryType.Job) && (
                      <>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                        >
                          <ul className="packageDetailsList">
                            <li>Advert online for 6 weeks</li>
                            <li>5 Photos included</li>
                            <li>5 YouTube videos included</li>
                            <li>3 Videos (3 x 1 minute videos)</li>
                          </ul>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                        >
                          <ul className="packageDetailsList">
                            <li>Knowledgeable customer support</li>
                          </ul>
                        </Col>
                      </>
                    )}

                    {category.is(CategoryType.Dog) && (
                      <>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                        >
                          <ul className="packageDetailsList">
                            <li>Advert online for 6 weeks</li>
                            <li>5 Photos of your dog included</li>
                            <li>5 YouTube videos included</li>
                            <li>3 Videos (3 x 1 minute videos)</li>
                          </ul>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                        >
                          <ul className="packageDetailsList">
                            <li>Knowledgeable customer support</li>
                          </ul>
                        </Col>
                      </>
                    )}

                    {category.is(CategoryType.Cat) && (
                      <>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                        >
                          <ul className="packageDetailsList">
                            <li>Advert online for 6 weeks</li>
                            <li>5 Photos of your cat included</li>
                            <li>5 YouTube videos included</li>
                            <li>3 Videos (3 x 1 minute videos) included</li>
                          </ul>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                        >
                          <ul className="packageDetailsList">
                            <li>Knowledgeable customer support</li>
                          </ul>
                        </Col>
                      </>
                    )}

                    {category.is(CategoryType.Misc) && (
                      <>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                        >
                          <ul className="packageDetailsList">
                            <li>Advert online for 6 weeks</li>
                            <li>5 Photos included</li>
                            <li>5 YouTube videos included</li>
                            <li>3 Videos (3 x 1 minute videos) included</li>
                          </ul>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                        >
                          <ul className="packageDetailsList">
                            <li>Knowledgeable customer support</li>
                          </ul>
                        </Col>
                      </>
                    )}

                    {category.is(CategoryType.LiveryYard) && (
                      <>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                        >
                          <ul className="packageDetailsList">
                            <li>Advert online for 6 weeks</li>
                            <li>5 Photos of your livery yard included</li>
                            <li>5 YouTube videos included</li>
                            <li>3 Videos (3 x 1 minute videos) included</li>
                          </ul>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                        >
                          <ul className="packageDetailsList">
                            <li>Knowledgeable customer support</li>
                          </ul>
                        </Col>
                      </>
                    )}
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </div>
  );
};

// PricingScene.fetchData = async (ssrStore, match, req) => {
//   const SSR = withSSRContext({ req });
//   const searchParamsInUrl = qs.parse('?sort=publishedAt.desc&page=1', {
//     ignoreQueryPrefix: true,
//   });

//   const { apiQuery } = getSearchOptions(searchParamsInUrl);
//   const advertItems = await getAdverts({ ...apiQuery, limit: 6, amp: SSR });
//   console.log('advertItems', advertItems);
//   ssrStore.dispatch(updateLanding({ adverts: advertItems }));
// };

export default PricingScene;
