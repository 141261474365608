import analytics from '../utils/analytics';
import * as types from './actionTypes';

export function updateAdverts({ items, from, total }) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_ADVERTS,
      payload: {
        items,
        from,
        total,
      },
    });
  };
}

export function updateLanding(landing) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_LANDING,
      payload: {
        landing,
      },
    });
  };
}

export function updateCurrentAdvert(advert) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_CURRENT_ADVERT,
      payload: {
        advert,
      },
    });
  };
}

export function addAdverts({ items, from, total }) {
  return (dispatch) => {
    dispatch({
      type: types.ADD_ADVERTS,
      payload: {
        items,
        from,
        total,
      },
    });
  };
}

export function updateSoldAt({ advertId, soldAt }) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_SOLD_AT,
      payload: { advertId, soldAt },
    });
  };
}

export function addFavourite(advertId, favouriteId, sellerId, type) {
  return (dispatch) => {
    dispatch({
      type: types.ADD_FAVOURITE,
      payload: { advertId, favouriteId },
    });

    analytics.onEvent('advert_favourited', {
      advert_id: advertId,
      seller_id: sellerId,
      type,
    });
  };
}

export function removeFavourite(id, advertId, sellerId, type) {
  return (dispatch) => {
    dispatch({
      type: types.REMOVE_FAVOURITE,
      payload: { id },
    });
    analytics.onEvent('advert_unfavourited', {
      advert_id: advertId,
      seller_id: sellerId,
      type,
    });
  };
}

export function updateFavourites(favourites) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_FAVOURITES,
      payload: { favourites },
    });
  };
}

export function updateMyAdverts(adverts) {
  return (dispatch) => {
    dispatch({
      type: types.UPDATE_MY_ADVERTS,
      payload: { adverts },
    });
  };
}
