import {
  Button,
  Card,
  Layout,
  Typography,
  Form,
  Input,
  Select,
  Space,
  Row,
  Collapse,
  Col,
  Checkbox,
} from 'antd';
import { Content, Footer, Header } from 'antd/lib/layout/layout';
import { withSSRContext } from 'aws-amplify';
import qs from 'qs';

import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { updateAdverts, updateLanding } from '../../actions/advertActions';
import AdvertCard from '../../components/AdvertCard';
import LocationAutoCompleteInput from '../../components/LocationAutoCompleteInput';
import { getAdverts } from '../../methods/adverts';
import getAmazonUrl from '../../methods/getAmazonUrl';
import { categoryPathById } from '../../utils/categoryPaths';
import { getSearchOptions } from '../../utils/filterTypes';
import Head from '../../components/Head';

import {
  colourOptions,
  conditionOptions,
  disciplinesOptions,
  ftptOptions,
  genderOptions,
  jobSubCategories,
  liveryFacilities,
  liveryYardSubCategories,
  livingOptions,
  propertyFacilities,
  propertySaleTypeOptions,
  propertyTypeOptions,
  trailerFacilities,
  trailerSaleTypeOptions,
  trailerWeights,
  typeOfStablesOptions,
} from '../../utils/options';
import './styles.less';
import getAdvertCdnUrl from '../../methods/getAdvertCdnUrl';

const { REACT_APP_CLASSIFIEDS_URL, REACT_APP_WHICKR_URL } = process.env;

const { Option } = Select;
const { Panel } = Collapse;

const HORSE_SALE = '80b50ee5-0f84-48e7-92f1-65793a48d597';
const HORSE_LOAN = '89ac3e31-fcfb-4794-8af9-6342f2d2b56e';

const HomeScene: React.FC = (props): ReactElement => {
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(0);
  const history = useHistory();

  const locationRef = useRef(null);
  const [isAdminLevel1, setIsAdminLevel1] = useState(false);
  const formRef = useRef<FormInstance<Values>>(null);

  const [category, setCategory] = useState('horse.sale');

  let backLink = `${REACT_APP_CLASSIFIEDS_URL}`;
  if (typeof window !== 'undefined') {
    backLink = encodeURIComponent(window?.location?.href);
  }
  return (
    <div>
      <Head
        title="Equestrian Classifieds Marketplace - Horse &amp; Hound"
        description="Find hundreds of horses for sale and ponies for sale suit your requirements in Horse &amp; Hound's equestrian classifieds marketplace. Also horseboxes for sale, equestrian jobs and equestrian property for sale."
      />
      <section className="heroSection">
        <div className="container">
          <div className="heroBox">
            <Typography.Title level={1} className="heroTitle">
              Find horses for sale throughout the UK and beyond with Horse &amp; Hound
            </Typography.Title>
            <Typography.Paragraph style={{ textAlign: 'center', padding: '20px 0px' }}>
              Search for your ideal partner from hundreds of{' '}
              <a
                className="homeLinks"
                href="/listings?disciplines=720e53c2-686e-44a4-a589-00670278768b"
              >
                eventers
              </a>
              ,{' '}
              <a
                className="homeLinks"
                href="/listings?disciplines=11586567-fffd-4e92-9e93-b5f02ec4dfb0"
              >
                showjumpers
              </a>
              ,{' '}
              <a
                className="homeLinks"
                href="/listings?disciplines=690bfc49-14b5-4655-8e25-52a38fafe986"
              >
                dressage horses
              </a>{' '}
              and{' '}
              <a
                className="homeLinks"
                href="/listings?disciplines=48d04c8a-9cd7-42d7-89b4-58394647dd54"
              >
                all-rounders
              </a>
              , as well as fun{' '}
              <a
                className="homeLinks"
                href="/listings?saleTypeId=80b50ee5-0f84-48e7-92f1-65793a48d597"
              >
                horses for sale
              </a>
              . <br />
              Also find{' '}
              <a
                className="homeLinks"
                href="/listings?typeId=5ae802e7-5b1b-4818-a67e-ecdf8e70fe88"
              >
                ponies for sale
              </a>{' '}
              and{' '}
              <a
                className="homeLinks"
                href="/listings?saleTypeId=89ac3e31-fcfb-4794-8af9-6342f2d2b56e"
              >
                horses for loan
              </a>
              .
            </Typography.Paragraph>
            <Form
              name="basic"
              ref={formRef}
              // labelCol={{ span: 8 }}
              // wrapperCol={{ span: 16 }}
              initialValues={{
                remember: true,
                category: 'horse.sale',
                saleTypeId: HORSE_SALE,
                distance: '50',
              }}
              onFinish={(values) => {
                const isHorseCat = ['horse.sale', 'horse.loan'].includes(category);
                // dont need category / postcode in query params
                const urlValues = {
                  ...values,
                  category: undefined,
                  postcode: undefined,
                  saleTypeId: isHorseCat ? values.saleTypeId : undefined,
                };

                const stringifiedSearchParams = qs.stringify(urlValues, {
                  indices: false,
                  allowDots: true,
                  addQueryPrefix: true,
                });

                const categoryPathKey = isHorseCat ? 'horse' : category;
                const targetUrl = `/${categoryPathById[categoryPathKey]}/${stringifiedSearchParams}`;

                history.push(targetUrl);
              }}
              // onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item name="formattedLocation" style={{ display: 'none' }} />
              <Form.Item name="exactCoords" style={{ display: 'none' }} />
              <Form.Item name="coords" style={{ display: 'none' }} />
              <Row gutter={[16, 0]}>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 12 }}
                >
                  <Form.Item name="searchLocation">
                    <LocationAutoCompleteInput
                      size="large"
                      locationRef={locationRef}
                      onChange={({
                        location,
                        coords,
                        isAdminLevel1: newIsAdminLevel1,
                      }) => {
                        setIsAdminLevel1(newIsAdminLevel1);
                        formRef.current.setFieldsValue({
                          searchLocation: location,
                          coords,
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 12 }}
                >
                  <Form.Item name="miles">
                    <Select
                      placeholder="Distance"
                      allowClear
                      disabled={isAdminLevel1}
                      size="large"
                    >
                      <Option value="50">+ 50 Miles</Option>
                      <Option value="100">+ 100 Miles</Option>
                      <Option value="200">+ 200 Miles</Option>
                      <Option value="300">+ 300 Miles</Option>
                      <Option value="500">+ 500 Miles</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 0]}>
                <Col span={24}>
                  <Form.Item name="category" rules={[{ required: true }]}>
                    <Select
                      placeholder="Category"
                      onChange={(newCategory) => {
                        setShowAdvancedFilters(0);
                        if (newCategory.includes('horse.')) {
                          const saleTypeId = newCategory.split('.')[1];

                          formRef.current.setFieldsValue({
                            saleTypeId: saleTypeId === 'loan' ? HORSE_LOAN : HORSE_SALE,
                          });
                        }
                        setCategory(newCategory);
                      }}
                      allowClear
                      size="large"
                    >
                      <Option value="horse.sale">Horses for sale</Option>
                      <Option value="trailer">Horseboxes and Trailers</Option>
                      <Option value="property">Equestrian Properties</Option>
                      <Option value="job">Equestrian Jobs</Option>
                      <Option value="horse.loan">Horses for loan</Option>
                      <Option value="dog">Dogs</Option>
                      <Option value="cat">Cats</Option>
                      {/* <Option value="liveryYard">Livery Yard</Option> */}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 0]}>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 12 }}
                >
                  <Form.Item name={category === 'job' ? 'salary.min' : 'price.min'}>
                    <Input
                      placeholder={category === 'job' ? 'Min Salary' : 'Min Budget'}
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 12 }}
                >
                  <Form.Item name={category === 'job' ? 'salary.max' : 'price.max'}>
                    <Input
                      placeholder={category === 'job' ? 'Max Salary' : 'Max Budget'}
                      size="large"
                    />
                  </Form.Item>
                </Col>
              </Row>

              {category !== 'cat' && category !== 'dog' && (
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Button
                      size="large"
                      type="ghost"
                      style={{
                        borderColor: 'rgba(0, 98, 62, 1.00)',
                        color: 'rgba(0, 98, 62, 1.00)',
                      }}
                      block
                      onClick={() =>
                        setShowAdvancedFilters(showAdvancedFilters === 1 ? 0 : 1)
                      }
                    >
                      Advanced Search
                    </Button>
                  </Col>
                </Row>
              )}
              <Form.Item name="saleTypeId" style={{ display: 'none' }}>
                <Input type="hidden" />
              </Form.Item>
              <Collapse
                ghost
                activeKey={showAdvancedFilters}
                style={{ margin: 0, padding: 0 }}
              >
                <Panel key="1" showArrow={false} style={{ margin: 0, padding: 0 }}>
                  {(category === 'horse.sale' || category === 'horse.loan') && (
                    <>
                      <Row>
                        <Col span={24}>
                          <Row gutter={[16, 0]}>
                            <Col>Disciplines</Col>
                          </Row>
                          <Form.Item name="disciplines" shouldUpdate>
                            <Select
                              style={{
                                width: '100%',
                              }}
                              placeholder="Select"
                              size="large"
                              mode="tags"
                              options={disciplinesOptions}
                              // onChange={(e) => {
                              //   formRef.current.setFieldsValue({
                              //     [filterComp.key]: e,
                              //   });
                              // }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={[16, 0]}>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                        >
                          <Row gutter={[16, 0]}>
                            <Col>Height</Col>
                          </Row>
                          <Row gutter={[16, 0]}>
                            <Col
                              xs={{ span: 24 }}
                              sm={{ span: 24 }}
                              md={{ span: 24 }}
                              lg={{ span: 12 }}
                            >
                              <Form.Item name="height.min">
                                <Input placeholder="Min height" size="large" />
                              </Form.Item>
                            </Col>
                            <Col
                              xs={{ span: 24 }}
                              sm={{ span: 24 }}
                              md={{ span: 24 }}
                              lg={{ span: 12 }}
                            >
                              <Form.Item name="height.max">
                                <Input placeholder="Max height" size="large" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                        >
                          <Row gutter={[16, 0]}>
                            <Col>Age</Col>
                          </Row>
                          <Row gutter={[16, 0]}>
                            <Col
                              xs={{ span: 24 }}
                              sm={{ span: 24 }}
                              md={{ span: 24 }}
                              lg={{ span: 12 }}
                            >
                              <Form.Item name="age.min">
                                <Input placeholder="Min age" size="large" />
                              </Form.Item>
                            </Col>
                            <Col
                              xs={{ span: 24 }}
                              sm={{ span: 24 }}
                              md={{ span: 24 }}
                              lg={{ span: 12 }}
                            >
                              <Form.Item name="age.max">
                                <Input placeholder="Max age" size="large" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      {/* <Row gutter={[16, 0]}>
                        <Col xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 12 }}>
                          <Row gutter={[16, 0]}>
                            <Col>Gender</Col>
                          </Row>
                          <Form.Item name="genderId" shouldUpdate>
                            <Select
                              style={{
                                width: '100%',
                              }}
                              placeholder="Select"
                              size="large"
                              mode="tags"
                              options={genderOptions}
                              // onChange={(e) => {
                              //   formRef.current.setFieldsValue({
                              //     [filterComp.key]: e,
                              //   });
                              // }}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 12 }}>
                          <Row gutter={[16, 0]}>
                            <Col>Colour</Col>
                          </Row>
                          <Form.Item name="colourId" shouldUpdate>
                            <Select
                              style={{
                                width: '100%',
                              }}
                              placeholder="Select"
                              size="large"
                              mode="tags"
                              options={colourOptions}
                              // onChange={(e) => {
                              //   formRef.current.setFieldsValue({
                              //     [filterComp.key]: e,
                              //   });
                              // }}
                            />
                          </Form.Item>
                        </Col>
                      </Row> */}
                    </>
                  )}

                  {category === 'trailer' && (
                    <>
                      <Row gutter={[16, 16]}>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                        >
                          Weight
                          <Form.Item name="weight" shouldUpdate>
                            <Select
                              style={{
                                width: '100%',
                              }}
                              placeholder="Select"
                              size="large"
                              options={trailerWeights}
                              // onChange={(e) => {
                              //   formRef.current.setFieldsValue({
                              //     [filterComp.key]: e,
                              //   });
                              // }}
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                        >
                          <Row gutter={[16, 0]}>
                            <Col>Sleeps</Col>
                          </Row>
                          <Row gutter={[16, 0]}>
                            <Col
                              xs={{ span: 24 }}
                              sm={{ span: 24 }}
                              md={{ span: 24 }}
                              lg={{ span: 12 }}
                            >
                              <Form.Item name="sleeps.min">
                                <Input placeholder="Min sleeps" size="large" />
                              </Form.Item>
                            </Col>
                            <Col
                              xs={{ span: 24 }}
                              sm={{ span: 24 }}
                              md={{ span: 24 }}
                              lg={{ span: 12 }}
                            >
                              <Form.Item name="sleeps.max">
                                <Input placeholder="Max sleeps" size="large" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row gutter={[16, 16]}>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                        >
                          <Row gutter={[16, 0]}>
                            <Col>Payload</Col>
                          </Row>
                          <Row gutter={[16, 0]}>
                            <Col
                              xs={{ span: 24 }}
                              sm={{ span: 24 }}
                              md={{ span: 24 }}
                              lg={{ span: 12 }}
                            >
                              <Form.Item name="payload.min">
                                <Input placeholder="Min payload" size="large" />
                              </Form.Item>
                            </Col>
                            <Col
                              xs={{ span: 24 }}
                              sm={{ span: 24 }}
                              md={{ span: 24 }}
                              lg={{ span: 12 }}
                            >
                              <Form.Item name="payload.max">
                                <Input placeholder="Max payload" size="large" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                        >
                          <Row gutter={[16, 0]}>
                            <Col>No of horses</Col>
                          </Row>
                          <Row gutter={[16, 0]}>
                            <Col
                              xs={{ span: 24 }}
                              sm={{ span: 24 }}
                              md={{ span: 24 }}
                              lg={{ span: 12 }}
                            >
                              <Form.Item name="numberOfHorses.min">
                                <Input placeholder="Min horses" size="large" />
                              </Form.Item>
                            </Col>
                            <Col
                              xs={{ span: 24 }}
                              sm={{ span: 24 }}
                              md={{ span: 24 }}
                              lg={{ span: 12 }}
                            >
                              <Form.Item name="numberOfHorses.max">
                                <Input placeholder="Max horses" size="large" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row gutter={[16, 16]}>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                        >
                          Sale type
                          <Form.Item name="trailerSaleTypeId" shouldUpdate>
                            <Select
                              style={{
                                width: '100%',
                              }}
                              placeholder="Select"
                              size="large"
                              mode="tags"
                              options={trailerSaleTypeOptions}
                              // onChange={(e) => {
                              //   formRef.current.setFieldsValue({
                              //     [filterComp.key]: e,
                              //   });
                              // }}
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                        >
                          Living
                          <Form.Item name="living" shouldUpdate>
                            <Select
                              style={{
                                width: '100%',
                              }}
                              placeholder="Select"
                              size="large"
                              options={livingOptions}
                              // onChange={(e) => {
                              //   formRef.current.setFieldsValue({
                              //     [filterComp.key]: e,
                              //   });
                              // }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={[16, 16]}>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                        >
                          Facilities
                          <Form.Item name="trailerFacilities" shouldUpdate>
                            <Select
                              style={{
                                width: '100%',
                              }}
                              placeholder="Select"
                              size="large"
                              mode="tags"
                              options={trailerFacilities}
                              // onChange={(e) => {
                              //   formRef.current.setFieldsValue({
                              //     [filterComp.key]: e,
                              //   });
                              // }}
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                        >
                          Condition
                          <Form.Item name="condition" shouldUpdate>
                            <Select
                              style={{
                                width: '100%',
                              }}
                              placeholder="Select"
                              size="large"
                              options={conditionOptions}
                              // onChange={(e) => {
                              //   formRef.current.setFieldsValue({
                              //     [filterComp.key]: e,
                              //   });
                              // }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  )}

                  {category === 'property' && (
                    <>
                      <Row gutter={[16, 16]}>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                        >
                          <Row gutter={[16, 0]}>
                            <Col>Number of bedrooms</Col>
                          </Row>
                          <Row gutter={[16, 0]}>
                            <Col
                              xs={{ span: 24 }}
                              sm={{ span: 24 }}
                              md={{ span: 24 }}
                              lg={{ span: 12 }}
                            >
                              <Form.Item name="numberOfBedrooms.min">
                                <Input placeholder="Min bedrooms" size="large" />
                              </Form.Item>
                            </Col>
                            <Col
                              xs={{ span: 24 }}
                              sm={{ span: 24 }}
                              md={{ span: 24 }}
                              lg={{ span: 12 }}
                            >
                              <Form.Item name="numberOfBedrooms.max">
                                <Input placeholder="Max bedrooms" size="large" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                        >
                          <Row gutter={[16, 0]}>
                            <Col>Number of bathrooms</Col>
                          </Row>
                          <Row gutter={[16, 0]}>
                            <Col
                              xs={{ span: 24 }}
                              sm={{ span: 24 }}
                              md={{ span: 24 }}
                              lg={{ span: 12 }}
                            >
                              <Form.Item name="numberOfBathrooms.min">
                                <Input placeholder="Min bathrooms" size="large" />
                              </Form.Item>
                            </Col>
                            <Col
                              xs={{ span: 24 }}
                              sm={{ span: 24 }}
                              md={{ span: 24 }}
                              lg={{ span: 12 }}
                            >
                              <Form.Item name="numberOfBathrooms.max">
                                <Input placeholder="Max bathrooms" size="large" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row gutter={[16, 16]}>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                        >
                          <Row gutter={[16, 0]}>
                            <Col>No of reception rooms</Col>
                          </Row>
                          <Row gutter={[16, 0]}>
                            <Col
                              xs={{ span: 24 }}
                              sm={{ span: 24 }}
                              md={{ span: 24 }}
                              lg={{ span: 12 }}
                            >
                              <Form.Item name="numberOfReceptionRooms.min">
                                <Input placeholder="Min reception rooms" size="large" />
                              </Form.Item>
                            </Col>
                            <Col
                              xs={{ span: 24 }}
                              sm={{ span: 24 }}
                              md={{ span: 24 }}
                              lg={{ span: 12 }}
                            >
                              <Form.Item name="numberOfReceptionRooms.max">
                                <Input placeholder="Max reception rooms" size="large" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                        >
                          <Row gutter={[16, 0]}>
                            <Col>No of stables</Col>
                          </Row>
                          <Row gutter={[16, 0]}>
                            <Col
                              xs={{ span: 24 }}
                              sm={{ span: 24 }}
                              md={{ span: 24 }}
                              lg={{ span: 12 }}
                            >
                              <Form.Item name="numberOfStables.min">
                                <Input placeholder="Min stables" size="large" />
                              </Form.Item>
                            </Col>
                            <Col
                              xs={{ span: 24 }}
                              sm={{ span: 24 }}
                              md={{ span: 24 }}
                              lg={{ span: 12 }}
                            >
                              <Form.Item name="numberOfStables.max">
                                <Input placeholder="Max stables" size="large" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row gutter={[16, 16]}>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 24 }}
                        >
                          <Row gutter={[16, 0]}>
                            <Col>No of paddocks</Col>
                          </Row>
                          <Row gutter={[16, 0]}>
                            <Col
                              xs={{ span: 24 }}
                              sm={{ span: 24 }}
                              md={{ span: 24 }}
                              lg={{ span: 12 }}
                            >
                              <Form.Item name="numberOfPaddocks.min">
                                <Input placeholder="Min paddocks" size="large" />
                              </Form.Item>
                            </Col>
                            <Col
                              xs={{ span: 24 }}
                              sm={{ span: 24 }}
                              md={{ span: 24 }}
                              lg={{ span: 12 }}
                            >
                              <Form.Item name="numberOfPaddocks.max">
                                <Input placeholder="Max paddocks" size="large" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row gutter={[16, 16]}>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                        >
                          Sale type
                          <Form.Item name="propertySaleTypeId" shouldUpdate>
                            <Select
                              style={{
                                width: '100%',
                              }}
                              placeholder="Select"
                              size="large"
                              options={propertySaleTypeOptions}
                              // onChange={(e) => {
                              //   formRef.current.setFieldsValue({
                              //     [filterComp.key]: e,
                              //   });
                              // }}
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                        >
                          Property type
                          <Form.Item name="propertyTypeId" shouldUpdate>
                            <Select
                              style={{
                                width: '100%',
                              }}
                              placeholder="Select"
                              size="large"
                              options={propertyTypeOptions}
                              // onChange={(e) => {
                              //   formRef.current.setFieldsValue({
                              //     [filterComp.key]: e,
                              //   });
                              // }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={[16, 16]}>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                        >
                          <Form.Item name="annex" valuePropName="checked">
                            <Checkbox>Annex</Checkbox>
                          </Form.Item>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                        >
                          Facilities
                          <Form.Item name="propertyFacilities" shouldUpdate>
                            <Select
                              style={{
                                width: '100%',
                              }}
                              placeholder="Select"
                              size="large"
                              mode="tags"
                              options={propertyFacilities}
                              // onChange={(e) => {
                              //   formRef.current.setFieldsValue({
                              //     [filterComp.key]: e,
                              //   });
                              // }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  )}

                  {category === 'job' && (
                    <>
                      <Row gutter={[16, 16]}>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                        >
                          Sub Category
                          <Form.Item name="jobTypeId" shouldUpdate>
                            <Select
                              style={{
                                width: '100%',
                              }}
                              placeholder="Select"
                              size="large"
                              mode="tags"
                              options={jobSubCategories}
                              // onChange={(e) => {
                              //   formRef.current.setFieldsValue({
                              //     [filterComp.key]: e,
                              //   });
                              // }}
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                        >
                          FT / PT
                          <Form.Item name="ftpt" shouldUpdate>
                            <Select
                              style={{
                                width: '100%',
                              }}
                              placeholder="Select"
                              size="large"
                              options={ftptOptions}
                              // onChange={(e) => {
                              //   formRef.current.setFieldsValue({
                              //     [filterComp.key]: e,
                              //   });
                              // }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={[16, 16]}>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 8 }}
                        >
                          <Form.Item name="accommodation" valuePropName="checked">
                            <Checkbox>Accommodation</Checkbox>
                          </Form.Item>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 8 }}
                        >
                          <Form.Item name="petsAllowed" valuePropName="checked">
                            <Checkbox>Pets Allowed</Checkbox>
                          </Form.Item>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 8 }}
                        >
                          <Form.Item name="horsesAllowed" valuePropName="checked">
                            <Checkbox>Horse Allowed</Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  )}

                  {category === 'liveryYard' && (
                    <>
                      <Row gutter={[16, 16]}>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                        >
                          Livery Yard Type
                          <Form.Item name="liveryTypeId" shouldUpdate>
                            <Select
                              style={{
                                width: '100%',
                              }}
                              placeholder="Select"
                              size="large"
                              options={liveryYardSubCategories}
                              // onChange={(e) => {
                              //   formRef.current.setFieldsValue({
                              //     [filterComp.key]: e,
                              //   });
                              // }}
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                        >
                          Stables type
                          <Form.Item name="stablesTypeId" shouldUpdate>
                            <Select
                              style={{
                                width: '100%',
                              }}
                              placeholder="Select"
                              size="large"
                              options={typeOfStablesOptions}
                              // onChange={(e) => {
                              //   formRef.current.setFieldsValue({
                              //     [filterComp.key]: e,
                              //   });
                              // }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={[16, 0]}>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                        >
                          <Row gutter={[16, 0]}>
                            <Col>Number of stables</Col>
                          </Row>
                          <Row gutter={[16, 0]}>
                            <Col
                              xs={{ span: 24 }}
                              sm={{ span: 24 }}
                              md={{ span: 24 }}
                              lg={{ span: 12 }}
                            >
                              <Form.Item name="numberOfStables.min">
                                <Input placeholder="Min stables" size="large" />
                              </Form.Item>
                            </Col>
                            <Col
                              xs={{ span: 24 }}
                              sm={{ span: 24 }}
                              md={{ span: 24 }}
                              lg={{ span: 12 }}
                            >
                              <Form.Item name="numberOfStables.max">
                                <Input placeholder="Max stables" size="large" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 12 }}
                        >
                          <Row gutter={[16, 0]}>
                            <Col>Number of paddocks</Col>
                          </Row>
                          <Row gutter={[16, 0]}>
                            <Col
                              xs={{ span: 24 }}
                              sm={{ span: 24 }}
                              md={{ span: 24 }}
                              lg={{ span: 12 }}
                            >
                              <Form.Item name="numberOfPaddocks.min">
                                <Input placeholder="Min paddocks" size="large" />
                              </Form.Item>
                            </Col>
                            <Col
                              xs={{ span: 24 }}
                              sm={{ span: 24 }}
                              md={{ span: 24 }}
                              lg={{ span: 12 }}
                            >
                              <Form.Item name="numberOfPaddocks.max">
                                <Input placeholder="Max paddocks" size="large" />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      <Row gutter={[16, 16]}>
                        <Col
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 24 }}
                        >
                          Facilities
                          <Form.Item name="liveryFacilities" shouldUpdate>
                            <Select
                              style={{
                                width: '100%',
                              }}
                              placeholder="Select"
                              size="large"
                              mode="tags"
                              options={liveryFacilities}
                              // onChange={(e) => {
                              //   formRef.current.setFieldsValue({
                              //     [filterComp.key]: e,
                              //   });
                              // }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  )}
                </Panel>
              </Collapse>

              <Row gutter={[16, { xs: 16, sm: 16, md: 16, lg: 0 }]}>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 12 }}
                >
                  <Button size="large" href="/listings" block>
                    Browse All{' '}
                    {(category === 'horse.sale' || category === 'horse.loan') && 'Horses'}
                  </Button>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 12 }}
                >
                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    // style={{ backgroundColor: '#ee5827', borderColor: '#ee5827' }}
                    block
                  >
                    Search
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </section>
      <div style={{ backgroundColor: '#fafafa', paddingTop: 60, paddingBottom: 60 }}>
        <div className="container">
          <Typography.Title level={3} className="subTitle">
            Latest horses for sale
          </Typography.Title>

          {props.landing?.adverts?.items?.length > 0 && (
            <Row gutter={[32, 32]}>
              {props.landing.adverts.items.map((advert, index) => {
                return (
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}
                    key={advert.id}
                  >
                    <AdvertCard
                      isFeatured={!!advert.featuredAt}
                      categoryId={advert.categoryId}
                      slug={advert.slug}
                      mediaHeight={282}
                      size="small"
                      advertId={advert.id}
                      sellerName={
                        advert.seller?.isBusiness
                          ? advert.seller?.businessName
                          : `${advert.seller?.firstName}${advert.seller?.lastName}`
                      }
                      sellerLocation={advert.formattedLocation}
                      title={advert.headline}
                      description={advert.description}
                      previewMedia={
                        advert.media && advert.media.length > 0
                          ? {
                              ...advert.media[0],
                              url: `${getAdvertCdnUrl(advert.media[0].type)}${
                                advert.media[0].fileName
                              }`,
                            }
                          : youTubeVideos && youTubeVideos.length > 0 && youTubeVideos[0]
                          ? { ...youTubeVideos[0], type: 'youtube' }
                          : null
                      }
                      youTubeVideo={
                        advert.youTubeVideos &&
                        advert.youTubeVideos.length > 0 &&
                        advert.youTubeVideos[0]
                          ? advert.youTubeVideos[0].id
                          : null
                      }
                      gender={advert.gender?.label}
                      age={advert.age}
                      height={advert.height}
                      saleType={advert.saleType?.label}
                      favouriteId={advert.favouriteId}
                      isSold={advert.soldAt}
                      price={advert.price}
                      isPOA={advert.isPOA}
                      isFree={advert.isFree}
                      isFixedHeight
                      index={index}
                    />
                  </Col>
                );
              })}
            </Row>
          )}
          <Row gutter={[32, 32]}>
            <Col span={24} style={{ textAlign: 'center' }}>
              <Button type="primary" size="large" href="/listings">
                Browse All Horses
              </Button>
            </Col>
          </Row>
        </div>
      </div>
      <div
        className="toolsSection"
        style={{ backgroundColor: '#fff', paddingTop: 60, paddingBottom: 60 }}
      >
        <div className="container">
          <Typography.Title level={3} className="subTitle">
            Tools to help you search
          </Typography.Title>
          <div className="toolsGrid">
            <a href="#">
              <div>
                <img src="/img/box-icon-1.png" />
                <Typography.Paragraph>
                  Start your search as wide or defined as you like with our postcode
                  search
                </Typography.Paragraph>
              </div>
            </a>
            <a href="/listings?sort=publishedAt.desc&hasMedia=true">
              <div>
                <img src="/img/box-icon-2.png" />
                <Typography.Paragraph>
                  Want to see a horse in action before you visit? Filter your search to
                  view adverts that include media
                </Typography.Paragraph>
              </div>
            </a>
            <a href="/how-to-get-the-most-out-of-the-horse-hound-classified-site">
              <div>
                <img src="/img/box-icon-3.png" />
                <Typography.Paragraph>
                  See a horse you like? Click our like button and view your shortlist side
                  by side
                </Typography.Paragraph>
              </div>
            </a>
            <a href="https://www.horseandhound.co.uk/features/hhs-ultimate-guide-to-buying-a-horse-314473?_ga=2.190563176.216960239.1681405186-277351622.1681347398">
              <div>
                <img src="/img/box-icon-4.png" />
                <Typography.Paragraph>
                  Read Horse &amp; Hound’s Ultimate Guide to buying a horse here
                </Typography.Paragraph>
              </div>
            </a>
          </div>
        </div>
      </div>
      <section className="preFooterSectionBackground">
        <div className="preFooterSection">
          <div className="container">
            <Typography.Title className="preFooterTitle">
              Is it time to sell <br />
              your horse?
            </Typography.Title>
            <Button
              type="primary"
              size="large"
              href="/advert-prices"
              style={{
                fontSize: '1.5rem',
                backgroundColor: '#f36c2e',
                borderColor: '#f36c2e',
              }}
            >
              Advert Prices
            </Button>
            <br />
            <Button
              type="primary"
              size="large"
              href={`${REACT_APP_WHICKR_URL}/create-advert?p=horseandhound&backLink=${backLink}`}
              style={{
                marginTop: '1.2rem',
                fontSize: '1.5rem',
                backgroundColor: '#f36c2e',
                borderColor: '#f36c2e',
              }}
            >
              Place Ad
            </Button>
          </div>
        </div>
      </section>
    </div>
  );
};

HomeScene.fetchData = async (ssrStore, match, req) => {
  try {
    const SSR = withSSRContext({ req });
    const searchParamsInUrl = qs.parse('?sort=publishedAt.desc&page=1', {
      ignoreQueryPrefix: true,
    });

    const { apiQuery } = getSearchOptions(searchParamsInUrl);
    const advertItems = await getAdverts({ ...apiQuery, limit: 6, amp: SSR });

    ssrStore.dispatch(updateLanding({ adverts: advertItems }));
  } catch (e) {
    console.log('HomeScene.fetchData error: ', JSON.stringify(e, null, 2));
  }
};

export default connect(
  (state) => ({
    landing: state.adverts.landing,
  }),
  (dispatch) => ({
    actions: bindActionCreators({ updateLanding }, dispatch),
  })
)(HomeScene);
