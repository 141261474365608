export const categoryPathById = {
  horse: 'horses',
  trailer: 'horseboxes-trailers',
  job: 'jobs',
  cat: 'cats',
  dog: 'dogs',
  misc: 'miscellaneous',
  property: 'properties',
  stallionsAtStud: 'stallions-at-stud',
  liveryYard: 'livery-yards',
};

export const categoryIdByPath = {
  '/': 'horse',
  '/horses': 'horse',
  '/horses-for-sale': 'horse',
  '/horses-for-sale/': 'horse',
  '/horses-for-loan-share': 'horse',
  '/horses-for-loan-share/': 'horse',
  '/horses-and-ponies-for-sale': 'horse',
  '/horses-and-ponies-for-sale/': 'horse',
  '/horseboxes-trailers': 'trailer',
  '/jobs': 'job',
  '/properties': 'property',
  '/cats': 'cat',
  '/dogs': 'dog',
  '/stallions-at-stud': 'stallionsAtStud',
  '/livery-yards': 'liveryYard',
  '/miscellaneous': 'misc',
  '/horses/': 'horse',
  '/horseboxes-trailers/': 'trailer',
  '/jobs/': 'job',
  '/properties/': 'property',
  '/cats/': 'cat',
  '/dogs/': 'dog',
  '/stallions-at-stud/': 'stallionsAtStud',
  '/livery-yards/': 'liveryYard',
  '/miscellaneous/': 'misc',
  '/horseboxes-and-trailers': 'trailer',
  '/equestrian-property': 'property',
  '/equestrian-jobs': 'job',
  '/horseboxes-and-trailers/': 'trailer',
  '/equestrian-property/': 'property',
  '/equestrian-jobs/': 'job',
};
