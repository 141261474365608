import React, { ReactElement } from 'react';
import './styles.less';

/**
 * Description
 */

type Props = { children: React.ReactNode; padding?: number; style?: CSSProperties };
const defaultProps = { padding: 0, style: {} };

const Box: React.FC<Props> = ({ children, padding, style }: Props): ReactElement => (
  <div className="BoxContainer" style={{ padding, ...style }}>
    {children}
  </div>
);

Box.defaultProps = defaultProps;
export default Box;
